import { ApiService } from "./api.service";
import { ApiRequest } from "../../models/apirequest";
import { Constants } from "../../config/constants";
import { ReplaySubject, Observable } from "rxjs";
import { Place } from "../../models/place";
import { PlaceType } from "../../models/place-type";
import { Injectable, Inject } from "@angular/core";
import { UtilsService } from "../utils.service";
import { Timeslot } from "../../models/timeslot";
import { GlobalService } from "../global.service";


@Injectable({
  providedIn: 'root'
})
export class PlaceService {

  constructor(
    private apiService: ApiService, 
    private utilsService: UtilsService,
    public global: GlobalService 
  )  {
    apiService.delegate = this
  }

  public getAllTypes(): Observable<PlaceType[]> {
      let observer = new ReplaySubject<PlaceType[]>()
      let request = new ApiRequest(Constants.URL_ALL_PLACE_TYPES, null)
      this.apiService.get(request).subscribe(
          data => {
              const pts: PlaceType[] = []
              for (const type of data) pts.push(new PlaceType(type))
              observer.next(pts)
          }, error => observer.error(error))
          return observer
  }

  public getTypes(): Observable<PlaceType[]> {
      let request = new ApiRequest(Constants.URL_ALL_PLACE_TYPES, null)
      let observer = new ReplaySubject<PlaceType[]>()

      this.apiService.get(request).subscribe(
          data => {
              const placesType: PlaceType[] = []
              for (const placeType of data) placesType.push(new PlaceType(placeType))
              observer.next(placesType)
          },
          error => observer.error(error),
          () => observer.complete()
      )

      return observer
  }

  public getPlaces(type: number): Observable<Place[]> {
      let request = new ApiRequest(this.utilsService.format(Constants.URL_PLACES_WITH_TYPE, { value: String(type), symbol: '@1' }), null)
      if (this.global.idPanier) {
        request.headers = request.headers.set(Constants.HEADER_QUOTE, this.global.idPanier.toString());
      }
      let observer = new ReplaySubject<Place[]>()
      this.apiService.get(request).subscribe(
          data => {
              const places: Place[] = []
              for (const place of data) places.push(new Place(place))
              observer.next(places)
          },
          error => observer.error(error),
          () => observer.complete()
      )
      return observer
  }

  public getTimeSlots(type: number): Observable<Timeslot[]> {
      let request = new ApiRequest(this.utilsService.format(Constants.URL_TIMESLOTS_WITH_TYPE, { value: String(type), symbol: '@1' }), null)
      let observer = new ReplaySubject<Timeslot[]>()
      this.apiService.get(request).subscribe(
          data => {
              const timeslots: Timeslot[] = []
              for (const place of data) timeslots.push(new Timeslot(place))
              observer.next(timeslots)
          },
          error => observer.error(error),
          () => observer.complete()
      )

      return observer
  }
}
