export class Stock {
    public qty: number;
    public outOfStock: number;
    public comingSoon: boolean;
    public store: {id: number};
    public stockable : boolean
    
    constructor(data) {
        this.setData(data)
    }

    public setData(data) {
        this.convert(data)
        this.outOfStock = data.outOfStock ? data.outOfStock : false
        this.comingSoon = data.comingSoon ? data.comingSoon : false
        this.qty = data.qty ? data.qty : 0
        this.store = data.store ? data.store : null
        this.stockable = data.stockable
    }

    public get storeId(): number { return this.store.id }

    private convert(data) {
        if (data.out_of_stock) data.outOfStock = data.out_of_stock
        if (data.coming_soon) data.comingSoon = data.coming_soon
    }
}