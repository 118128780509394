
import * as moment from 'moment';
import 'moment-timezone';


export {}
  

declare global {
    interface DateConstructor {
        instance(value?): Date
    }
}
Date.instance = (value = new Date): Date => {
    if (typeof value == 'string') {
        if (value.match(/^\d{4}-\d{2}-\d{2}[^T]/)) {
            // pour safari: YYYY-MM-DD? -> YYYY-MM-DDT
            value = value.substring(0,10) + 'T' + value.substring(11)
        }
        if (value.match(/^\d{4}-\d{2}-\d{2}.\d{2}:\d{2}:\d{2}\+\d{4}$/)) {
            // pour safari: YYYY-MM-DDTkk:mm:ss+0000 -> YYYY-MM-DDTkk:mm:ss+00:00
            //retrait timeslot et force sur UTC
            value = value.substr(0, value.length - 2) + ':' + value.substr(-2)
            value = value.substr(0, value.length - 5)+'00:00'
        }
    }
    return moment.tz(value,'YYYY-MM-DDTkk:mm:ssZ','UTC').toDate()
}