import { Component, OnInit, Input, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NavController } from "@ionic/angular";

// Model
import { Card } from "../../../../../models/card";
import { MethodOfPayment } from "../../../../../models/method-of-payment";

// Pages
import { PayzenEmbededPage } from "../../../payzen-embeded/payzen-embeded.page";
import { PayzenRegisterPage } from "../../../payzen-register/payzen-register.page";

// Services
import { MopComponent } from "../mop-component";
import { CommandPipeService } from "src/app/command-pipe/command-pipe-service";
import { AlertService } from "src/app/services/alert.service";
import { UserService } from "src/app/services/api/user.service";
import { QuoteService } from "src/app/services/api/quote.service";
import { UtilsService } from "src/app/services/utils.service";
import { ValidateOrderAction } from "../../actions/validate-order-action";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

import { PayService } from "src/app/services/pay.service";

@Component({
	selector: 'embeded-mop',
	templateUrl: './embeded-mop.component.html',
	styleUrls: ['./embeded-mop.component.scss'],
})
export class EmbededMopComponent implements OnInit, MopComponent {
	@Input('valuePrefix') public valuePrefix?: string
	public title: string
	public mop: MethodOfPayment
	public get firstValue(): string { 
		let valuePrefix; return (valuePrefix = this.valuePrefix) ? valuePrefix + 'card' : 'card' 
	}

	public listCards: Card[] = []
	private timout: number;

	constructor(
		private navCtrl: NavController,
		private commandPipeService: CommandPipeService,
		private userService: UserService,
		private quoteService: QuoteService,
		private utilsService: UtilsService,
		private translateService: TranslateService,
		private alertService: AlertService,
		public router: Router,
		private route: ActivatedRoute,
		private payService: PayService
	) {}

	// Modes de paiement
	private payWithPayzenForm() {
		if (this.userService.isLoggedIn()) {
			this.payService.payAction('tabs/payzen-embeded');
		} else{
			// this.navCtrl.push(PayzenRegisterPage, { 'pay-action': payAction })
			const navigationExtras: NavigationExtras = {
				state: {
					'backAction': 'tabs/payzen-embeded',
				}
		   };
		   this.router.navigate(['tabs/payzen-register'], navigationExtras);
		} 
	}

	private payWithPayzenToken(index: number) {
		let that = this;
		this.userService.payOrder(that.listCards[index].id).subscribe((data) => {
			new ValidateOrderAction(this.quoteService, this.commandPipeService, this.utilsService, this.translateService, this.alertService).do()
		}, (error) => {
			that.payService.presentErrorAlert(this.translateService.instant("card-mop.invalid-token-alert.message"), error);
		});
	}

	/* MopComponent */
	public init() {
		if (this.timout) { clearTimeout(this.timout); this.timout = null }

		var that = this;
		this.userService.getMyCards(false).subscribe((data) => {
			for (let i in data) that.listCards.push(new Card(data[i]));
		}, (error) => {
			// Erreur silencieuse. Si il y a une erreur, les cartes préenregistrées ne sont pas affichées
		});
	}
	
	public processValue(value: string): boolean {
		let valuePrefix = this.valuePrefix
		let i = value
		if (valuePrefix) {
			if (!value.startsWith(valuePrefix)) return false
			i = value.substr(valuePrefix.length)
		}

		const infoTime = this.quoteService.getFullDate();

		this.quoteService.setMethodOfPayment(this.mop, infoTime).subscribe(
			data => i == 'card' ? this.payWithPayzenForm() : this.payWithPayzenToken(Number(i)),
			error => this.payService.presentErrorAlert(this.translateService.instant("card-mop.timeout-alert.message"))
		)
		return true
	}

	ngOnInit() {}

}
