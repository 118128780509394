import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from "rxjs";
import { environment } from 'src/environments/environment';

import { PrinterConf } from "../config/printer-conf";
import { NeptingConf } from "../config/nepting-conf";
import { PlatformConf } from "../config/platform-conf";

import { Constants } from "../config/constants";
import { Conf } from "../config/conf";

import { FileService } from './file.service';
import { ApiHeaderService, DeviceCodeHeaderContainer } from "./api/api.header.service";
import { Store } from '../models/store';


@Injectable({
	providedIn: 'root'
})
export class ConfigurationService {
	private deviceCodeHeaderContainer: DeviceCodeHeaderContainer;
	public CONF: any = '';
	public locale: string = 'en';
	public selectedIndex:number = 0;

	public device: device = 'mobile'
	public hiddenSettingsAccess : boolean = false
    public idleTimeout: number
    public idleExtratime: number
    public store: Store
    public log: {
        enabled: boolean
        keys: string[]
    }
    public translationAsKey: boolean
	public mode: {
        allowAnonymous: boolean,
        articlesPageLayout: articlesPageLayout,
        articleSelectionPageSequence: articleSelectionPageSequence,
        takeBackMode: takeBackMode,
        nicknamePageOrder: nicknamePageOrder,
        quoteFlushMode: quoteFlushMode,
        showTva: boolean,
        showToasterAddQuote: boolean,
        showCart: boolean,
        menuMode: menuMode,
        positionCart: positionCart,
        showStructure: boolean,
        couponFid:boolean,
        priceForShowOnly:boolean,
        saveStoreInStorage:boolean,
        AllowOosProductInfo:boolean,
        positionMenu: positionMenu,
        nbGrid: nbGrid,
        checkCGV: boolean,
        numInternational:boolean
    } = {
        allowAnonymous: true,
        articlesPageLayout: "grid",
        articleSelectionPageSequence: "cart",
        takeBackMode: "scan+type",
        nicknamePageOrder: "",
        quoteFlushMode: "soft",
        showTva: true,
        showToasterAddQuote: false,
        showCart: false,
        menuMode: "menu",
        positionCart: "bottom",
        showStructure: false,
        couponFid:false,
        priceForShowOnly:false,
        saveStoreInStorage:true,
        AllowOosProductInfo:true,
        positionMenu : "top",
        nbGrid : "4",
        checkCGV: false,
        numInternational:true
    }

	private _printerConf: PrinterConf
	public get printerConf() { return this._printerConf }
	public set printerConf(printerConf : PrinterConf) { this._printerConf = printerConf }

	private _neptingConf: NeptingConf
	public get neptingConf() { return this._neptingConf }
	public set neptingConf(neptingConf : NeptingConf) { this._neptingConf = neptingConf }

	private _platformConf: PlatformConf
	public get platformConf() { return this._platformConf }
	public set platformConf(platformConf : PlatformConf) { this._platformConf = platformConf }

	public isDisplaySideMenu:boolean = true;

	constructor(
		private http: HttpClient,
	  	private fileService: FileService,
	  	apiHeaderService: ApiHeaderService 
	) {
		this.getConfJson().subscribe(data => {
			if(data){
				this.CONF = data[0];
				// console.log(this.CONF);
			}
		});
	  this.deviceCodeHeaderContainer = apiHeaderService;
	}

	private createConf(conf: Conf) {
		switch(conf.device) {
			case 'nepting':
				if (this._neptingConf) this.neptingConf.overrideConf(conf as NeptingConf)
				else this._neptingConf = new NeptingConf(conf as NeptingConf)
				break
			case 'mobile':
			case 'terminal':
			case 'terminal_2':
				if (this._platformConf) this.platformConf.overrideConf(conf as PlatformConf)
				else this._platformConf = new PlatformConf(conf as PlatformConf)
				break
			case 'printer':
				if (this._printerConf) this.printerConf.overrideConf(conf as PrinterConf)
				else this._printerConf = new PrinterConf(conf as PrinterConf)
				break
		}
	}

	read(): Observable<any> {
		console.log('Configuration', 'read conf...');
		const ASSETS_READ_FLAG = 1
		const FS_READ_FLAG = 2
		let readStates = 0
		let observer = new ReplaySubject<any>();
		let confsByFlags: {[id:number]:Conf[]} = {}
		const finnallyRead = (flag: number, data?: any) => {
			// console.log('Configuration', `read conf from ${flag == FS_READ_FLAG ? 'file' : 'assets'} - ${data ? 'success' : 'fail'}`)
			/*console.log('Configuration', data)*/
			let confs: Conf[]
			if(data && data != '') {
			 	try {
				 	confs = JSON.parse(data)
				 	// console.log('Configuration', 'parse conf - success')
			 	} catch (error) {}
			}

			if (confs) {
				confsByFlags[flag] = confs
			}else{
				console.log('Configuration', 'parse conf - fail')
			} 

			readStates |= flag
			if (readStates != (FS_READ_FLAG | ASSETS_READ_FLAG)) return

			const confss = [
				confsByFlags[ASSETS_READ_FLAG],
				confsByFlags[FS_READ_FLAG]
			]

			for(const confs of confss)
			if (confs)
			   for(let conf of confs)
				  this.createConf(conf)


			if (!this._platformConf) this._platformConf = new PlatformConf()
			// Comment by Sajid
			this.deviceCodeHeaderContainer.deviceCode = this._platformConf.device

			// console.log('Configuration',
			//  	'final conf',
			//  	JSON.stringify([
			// 	 	this._platformConf,
			// 	 	this._neptingConf,
			// 	 	this._printerConf
			//  	], null, 1)
			// )
			observer.next(data);
	  	}

	  	// Comment by Sajid
  		this.fileService.readFromFile(Constants.CONF).subscribe(
			data => finnallyRead(FS_READ_FLAG, data),
			error => {
			   if (error) setTimeout(() => window.location.reload(), 10000)
			   else finnallyRead(FS_READ_FLAG)
			}
		);

	  	this.http.get(Constants.ASSETS_CONF,{
		 	headers: new HttpHeaders()
				.set('Content-Type', 'text/json')
				.append('Access-Control-Allow-Methods', 'GET')
				.append('Access-Control-Allow-Origin', '*')
				.append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
				responseType:'text'
	  	}).subscribe(
		 	data => {
				console.log('read data asset');
				finnallyRead(ASSETS_READ_FLAG, data)
		},
		error => {
			console.log('url : ' + 'localasset : '+Constants.ASSETS_CONF + ' error newhttp ', JSON.stringify(error));
			finnallyRead(ASSETS_READ_FLAG)
		});

		return observer;
   	}

 
	getConfJson():Observable<any>{
		return this.http.get('./assets/conf.json');
	}

	getApiUrl(url){
		return environment.API_URL + this.locale + '/' + 'API/v' + environment.API_VERSION + '/' + url;
	}

	getTabPages(){
		let tabPages = [
			{
				title: 'tab.home',
				url: '/tabs/home',
				tab:'home',
				icon: 'home-sharp',
			},
			{
				title: 'tab.stores',
				url: '/tabs/stores-list',
				tab:'stores-list',
				icon: 'locate-sharp'
			},
			{
				title: 'tab.shop',
				url: '/tabs/directory',
				tab:'directory',
				icon: 'restaurant-sharp'
			},
			{
				title: 'tab.cart',
				url: '/tabs/quote',
				tab:'quote',
				icon: 'bag-handle-sharp'
			},
			{
				title: 'tab.profile',
				url: '/tabs/profile',
				tab:'profile',
				icon: 'person-circle-sharp'
			}

		];

		return tabPages;
	}

	overrideConf(platformConf: PlatformConf) {
        const device = platformConf.device
        if (!device || device == 'mobile' || device == 'terminal' || device == 'terminal_2') {
            this.device = platformConf.device || 'mobile'
            if(platformConf.hiddenSettingsAccess)   this.hiddenSettingsAccess = platformConf.hiddenSettingsAccess
            if(platformConf.idleTimeout)            this.idleTimeout =          Number(platformConf.idleTimeout)
            if(platformConf.idleExtratime)          this.idleExtratime =        Number(platformConf.idleExtratime)
            if(platformConf.store)                  this.store =                platformConf.store
            if(platformConf.log)                    this.log =                  platformConf.log
            if(platformConf.translationAsKey)       this.translationAsKey =     platformConf.translationAsKey
            const mode = this.mode
            const platformConfMode = platformConf.mode
            if (platformConfMode) {
                if (typeof platformConfMode.allowAnonymous == 'boolean')        Constants.ALLOW_ANONYMOUS =                     mode.allowAnonymous =               platformConfMode.allowAnonymous
                if (platformConfMode.articlesPageLayout)                        Constants.ARTICLES_PAGE_LAYOUT =                mode.articlesPageLayout =           platformConfMode.articlesPageLayout
                if (platformConfMode.articleSelectionPageSequence)              Constants.ARTICLE_SELECTION_PAGE_SEQUENCE =     mode.articleSelectionPageSequence = platformConfMode.articleSelectionPageSequence
                if (platformConfMode.takeBackMode)                              Constants.TAKE_BACK_MODE =                      mode.takeBackMode =                 platformConfMode.takeBackMode
                if (platformConfMode.nicknamePageOrder)                         Constants.NICKNAME_PAGE_ORDER =                 mode.nicknamePageOrder =            platformConfMode.nicknamePageOrder
                if (platformConfMode.quoteFlushMode)                            Constants.QUOTE_FLUSH_MODE =                    mode.quoteFlushMode =               platformConfMode.quoteFlushMode
                if (typeof platformConfMode.showTva == 'boolean')               Constants.SHOW_TVA =                            mode.showTva =                      platformConfMode.showTva
                if (typeof platformConfMode.showToasterAddQuote == 'boolean')   Constants.SHOW_TOASTER_ADD_QUOTE =              mode.showToasterAddQuote =          platformConfMode.showToasterAddQuote
                if (typeof platformConfMode.showCart == 'boolean')              Constants.SHOW_CART =                           mode.showCart =                     platformConfMode.showCart
                if (platformConfMode.menuMode)                                  Constants.MENU_MODE =                           mode.menuMode =                     platformConfMode.menuMode
                if (platformConfMode.positionCart)                              Constants.POSITION_CART =                       mode.positionCart =                 platformConfMode.positionCart
                if (typeof platformConfMode.showStructure == 'boolean')         Constants.SHOW_STRUCTURE =                      mode.showStructure =                platformConfMode.showStructure
                if (typeof platformConfMode.couponFid == 'boolean')             Constants.COUPON_FID =                          mode.couponFid =                    platformConfMode.couponFid
                if (typeof platformConfMode.priceForShowOnly == 'boolean')      Constants.PRICE_FOR_SHOW_ONLY =                 mode.priceForShowOnly =             platformConfMode.priceForShowOnly
                if (typeof platformConfMode.showTva == 'boolean')               Constants.SHOW_TVA =                            mode.showTva =                      platformConfMode.showTva
                if (typeof platformConfMode.saveStoreInStorage == 'boolean')    Constants.SAVE_STORE_IN_STORAGE =               mode.saveStoreInStorage =           platformConfMode.saveStoreInStorage
                if (typeof platformConfMode.AllowOosProductInfo == 'boolean')   Constants.ALLOW_OOS_PRODUCT_INFO =              mode.AllowOosProductInfo =          platformConfMode.AllowOosProductInfo
                if (platformConfMode.positionMenu)                              Constants.POSITION_MENU =                       mode.positionMenu =                 platformConfMode.positionMenu
                if (platformConfMode.nbGrid)                                    Constants.NB_GRID =                             mode.nbGrid =                       platformConfMode.nbGrid
                if (typeof platformConfMode.checkCGV == 'boolean')              Constants.CHECK_CGV =                           mode.checkCGV =                     platformConfMode.checkCGV
                if (typeof platformConfMode.numInternational == 'boolean')      Constants.NUM_INTERNATIONAL =                   mode.numInternational =             platformConfMode.numInternational
            }
        }
    }

}
