// src/models/option.ts
import { OptionItem } from "./optionItem"

export class Option {
    static count = 0
    public uid = 0
    private i18nLabel        : i18nString
    private _label           : string

    public id                : number
    public deposit           : boolean
    public min_selectable_qty: number
    public max_selectable_qty: number
    public unlimited_qty     : boolean
    public hidden            : boolean
    public active            : boolean
    public multiple          : boolean
    public items             : OptionItem[] = undefined

    public get label() { return localize(this.i18nLabel, this._label) }

    get selectedCount(): number { 
        let qty = 0;
        if(this.items) {
            let isMultiple = this.items.find(item => item.isMultiple)
            if(isMultiple){
                this.items.filter(item => item.qty > 0).forEach(item=> qty += item.qty)
            } else {
                qty = this.items.filter(item => item.isChecked).length
            }
            
        }
        return qty 
    }

    get canSelectAll(): boolean {
        const itemsCount = this.items
            ? this.items.length
            : 0

        const result = this.unlimited_qty || this.max_selectable_qty >= itemsCount

        return result
    }

    constructor(data) {
        this.uid = ++Option.count
        this.id                 = typeof data.id                 !== "undefined" ? data.id                  : undefined
        this._label             = typeof data.label              !== "undefined" ? data.label               : undefined
        this.i18nLabel          = typeof data.i18n_label         !== "undefined" ? data.i18n_label          : data.i18nLabel
        this.min_selectable_qty = typeof data.min_selectable_qty !== "undefined" ? data.min_selectable_qty  : 0
        this.max_selectable_qty = typeof data.max_selectable_qty !== "undefined" ? data.max_selectable_qty  : 1
        this.unlimited_qty      = typeof data.unlimited_qty      !== "undefined" ? data.unlimited_qty       : false
        this.active             = typeof data.active             !== "undefined" ? data.active              : undefined
        this.multiple           = typeof data.multiple           !== "undefined" ? data.multiple            : false
        this.hidden             = data.hidden
        this.deposit            = data.deposit

        if(typeof data.items !== "undefined"
        && data.items.length > 0) {
            this.items = []

            for (let i in data.items) {
                data.items[i].isMultiple = data.multiple
                this.items.push(new OptionItem(data.items[i]))
            }
        }
    }

    public deselectAll() {
        for (let item of this.items) {
            item.isChecked = false
        }
    }
}
