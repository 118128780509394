import { Injectable, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "./api.service";
import { LanguageLoader, TranslateConfigService } from "../translate-config.service";
import { ReplaySubject } from "rxjs";
import { Constants } from "../../config/constants";
import { ApiRequest } from "../../models/apirequest";

@Injectable({
	providedIn: 'root'
})
export class TranslationsService {

	constructor(
		private apiService: ApiService,
		private translateService: TranslateService,
        private translateConfigService:TranslateConfigService,
        // comment by sajid
		// private languageLoader: LanguageLoader
	) {}

    public getTranslations() {
        let observer = new ReplaySubject<void>()

        let request = new ApiRequest(Constants.URL_TRANSLATIONS, null)
        this.apiService.get(request).subscribe(
            data => {
                if(Object.keys(data).length !== 0){

                    // this.languageLoader.languageByLocales = data;
                    let languageArray = [];
                    this.translateConfigService.defaultLanguageByLocales.forEach(data => {
                        languageArray.push(data.language);
                    });

                    let remoteTranslation = data._;
                    let remoteTranslationKey = Object.keys(remoteTranslation);
                    remoteTranslationKey.forEach(key => {
                        var translationData = remoteTranslation[key];
                        var obj = {};
                        obj[key] = translationData;
                        languageArray.forEach(locale => {
                            this.translateService.setTranslation(locale, obj, false);
                        });
                    });

                    this.reloadTranslations(languageArray)
                }
                observer.next(null)
            },
            error => { observer.error(error) }
        )
        return observer
    }

    public reloadTranslations(locales: string[]) {
        for (let locale of locales) {
            // console.log(`LanguageService::translationService.resetLang(${locale})`)
            // note: hack pour forcer la réinitialisation des traductions
            if (locale == this.translateService.currentLang) { 
                this.translateService.currentLang = null; 
                setTimeout(() => this.translateService.use(locale), 0) 
                
            }
            this.translateService.resetLang(locale);
           
        }
    }

    // private addDynamicTranslation(section:string,culture:string,k:string,v:string){
    //     //if key already exists: overwrite it, if not create it
    //     if(this.valuemapper.translateService.getLangs().includes(culture)){
    //        this.valuemapper.translateService.getTranslation(culture).subscribe((t:Object)=>{
    //             if(t.hasOwnProperty(section)){
    //                if(t[section].hasOwnProperty(k)) t[section][k]=v;
    //                else Object.defineProperty(t[section], k, {value:v, writable:true, configurable:true, enumerable:true});
    //             }
    //             else{
    //                 var obj={};
    //                 Object.defineProperty(obj, k, {value:v, writable:true, configurable:true, enumerable:true});
    //                 Object.defineProperty(t, section, obj);
    //             };
    //             this.valuemapper.translateService.setTranslation(culture,t);
    //         })
    //     }
    // }


}
