import { Injectable } from '@angular/core';
import { CustomNavResponse } from "../models/navigation";
import { NavigationService } from './api/navigation.service';
import { SerialUsbService, Reader } from "./serial-usb.service";


@Injectable({
  providedIn: 'root'
})
export class DirectoryService {

  public reader: Reader
  public readerErrorCallback: (error: any) => void
  public serialOptions: SerialOptions

  private _navigation: CustomNavResponse
  public set navigation(navigation: CustomNavResponse) {
    this._navigation = navigation
    this.navigationService.listenItemSelectionChange(() => {
      this.didChangeSelectedItem()
    })
  }

  constructor(private serialUsbService: SerialUsbService,
              private navigationService: NavigationService) {}

  private didChangeSelectedItem() {
    let selectedItem = this.navigationService.selectedItem
    if (selectedItem) {
      switch (selectedItem.type) {
        case 'rfid':
          this.serialUsbService.start(
            this.reader,
            this.readerErrorCallback,
            this.serialOptions
          );
          break;

        default:
          this.serialUsbService.stop();
          break;
      }
    }
  }
}
