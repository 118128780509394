import { Injectable } from '@angular/core';
import { NavigationService } from './api/navigation.service';
import { CustomNavResponse } from "../models/navigation";

@Injectable({
  providedIn: 'root'
})
export class LoadNavigationService {

  constructor(public navigationService: NavigationService) { }

  public getNavigation(index: any, storeService?: any): Promise<CustomNavResponse> {
    return new Promise((resolve, reject) => {
      this._getNavigationService(resolve, reject, index, storeService)
    });
  }

  private _getNavigationService(resolve, reject, index: any, storeService?: any, attempt = false) {
    const subscription = this.navigationService.callApiForNavigation().subscribe(
      (resp) => {
        // Force la sélection de lindex.
        if(isNaN(index)) {
          index = this.navigationService.navigation.children.findIndex((nav)=> nav.label == index)
        }

        this.navigationService.selectedItem = this.navigationService.navigation.children[index];
        resolve(resp);
      },
      (err) => {
        if (storeService.getCurrentStore() && attempt === false) {
          storeService.deleteStoreFromStorage()
          this._getNavigationService(index, storeService, true);
        } else {
          reject(err);
        }
      }
    )

    return subscription;
  }

}
