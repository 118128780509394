
export class StorageData {
    constructor(public value: string, public expireDate?: Date) {
        this.value = value;
        this.expireDate = expireDate;
    }

    public getValue(parsed: boolean = false): string|any { return parsed ? JSON.parse(this.value) : this.value }
    public isExpired(): boolean { return this.expireDate && this.expireDate < new Date() }
    public toString(): string { return JSON.stringify(this) }
}