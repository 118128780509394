import { Injectable, Inject } from '@angular/core';
import { Observable, ReplaySubject }  from 'rxjs';

import { Constants } from '../../config/constants';
import { ApiService } from './api.service';
import { ApiRequest } from '../../models/apirequest';

// models
import { Article } from '../../models/article';

// services
import { UtilsService } from '../utils.service';

@Injectable({
	providedIn: 'root'
})
export class ArticleService {

	constructor( 
		private apiService: ApiService,
		private utilsService: UtilsService
	) {
		this.apiService.delegate = this
	}

	private getArticlesWithRoute(
		route:    string,
		ids:      any[], 
		observer: ReplaySubject<Article[]>,
		params:   any[] = []
	) {
		let apiRequest = new ApiRequest(
			this.utilsService.format(route, { value: ids.join('/'), symbol: '@1' }),
			null
		)

		this.apiService.get(apiRequest).subscribe((data) => {
			let articles: Article[] = []
			
			for(const articleKey in data) {
				articles.push(new Article(data[articleKey]))
			}

			observer.next(articles)
		},
		(error) => { observer.error(error) },
			() => { observer.complete() 
		});
	}

	private getArticlesWithCodes(
		codes:    string[],
		observer: ReplaySubject<Article[]>,
		params: string[] = []
	) {
		this.getArticlesWithRoute(Constants.URL_ARTICLES_CODE_X, codes, observer, params)
	}

	/**
	 * Appel l'API pour récupérer les articles des ventes additionelles
	*/
	public getCrossSellings(article: Article): Observable<Article[]> {
		let observer = new ReplaySubject<Article[]>();

		let cross_sellings = article.cross_sellings;
		if (cross_sellings && cross_sellings.length > 0) {
			this.getArticlesWithIds(cross_sellings, observer)
		}

		return observer;
	}

	public getArticles(articles: Article[], params: string[] = []): Observable<Article[]> {
		let observer = new ReplaySubject<Article[]>();

		if (articles && articles.length > 0) {
			let ids = articles.map((article: Article) => article.id, observer)
			this.getArticlesWithIds(ids, observer, params)
		} else {
			observer.next([])
		}

		return observer;
	}

	private getArticlesWithIds(
		ids:      number[],
		observer: ReplaySubject<Article[]>,
		params:   string[] = []
	) {
		if(params.length === 0) {
			this.getArticlesWithRoute(Constants.URL_ARTICLES_X, ids, observer)
		} else {
			this.getArticlesWithRoute(Constants.URL_ARTICLES_X_EXTRA, ids, observer)
		}
	}

	public getArticleFromCodes(
		codes: string[], params: string[] = []
	) {
		let observer = new ReplaySubject<Article[]>();
		this.getArticlesWithCodes(codes, observer, params)
		return observer;
	}
}
