import { PrintStrategy } from "./print-strategy";
import { Observable, ReplaySubject } from "rxjs";
import { Injectable } from "@angular/core";
import { FileService } from "../services/file.service";
import { PrinterConf } from "../config/printer-conf";

@Injectable()
export class FilePrint implements PrintStrategy<string> {
    public get id() { return 'FilePrint' }
    public printerConf: PrinterConf

    constructor(private fileService: FileService) {}

    public print(data: string): Observable<string> {
        const filename = this.printerConf && this.printerConf.logFilename
        if (filename) return this.fileService.writeIntoFile(filename, data, true)
        const observer = new ReplaySubject<string>()
        observer.error('No filename provided')
        return observer
    }

}