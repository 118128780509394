import { Component, Input, OnInit, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { MopComponent } from "../mop-component";

import { MethodOfPayment } from "../../../../../models/method-of-payment";

import { ValidateOrderAction } from "../../actions/validate-order-action";
import { CommandPipeService } from "../../../../../command-pipe/command-pipe-service";
import { QuoteService } from "../../../../../services/api/quote.service";
import { UtilsService } from "../../../../../services/utils.service";
import { AlertService } from "../../../../../services/alert.service";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';


@Component({
	selector: 'cash-mop',
	templateUrl: './cash-mop.component.html',
	styleUrls: ['./cash-mop.component.scss'],
})
export class CashMopComponent implements OnInit, MopComponent {
	@Input('valuePrefix') public valuePrefix?: string
	public title: string
	public mop: MethodOfPayment
	public get firstValue(): string { let valuePrefix; return (valuePrefix = this.valuePrefix) ? valuePrefix + 'cash' : 'cash' }

	constructor(
		private commandPipeService: CommandPipeService, 
		private quoteService: QuoteService, 
		private utilsService: UtilsService, 
		private translateService: TranslateService,
		private alertService: AlertService,
		public router: Router,
		private route: ActivatedRoute,
	
	) { }

	/* MopComponent */
	public processValue(value: string): boolean {
		let valuePrefix = this.valuePrefix
		if (valuePrefix && !value.startsWith(valuePrefix)) return false

		const infoTime = this.quoteService.getFullDate();

		this.quoteService.setMethodOfPayment(this.mop, infoTime).subscribe(
			data => new ValidateOrderAction(this.quoteService, this.commandPipeService, this.utilsService, this.translateService, this.alertService).do(),
			error => {
				this.alertService.show({
					cssClass:'alert-margintop11',
					header: this.translateService.instant('alert.title.error'),
					message: this.translateService.instant("cash-mop.payment-error-alert.message"),
					buttons: [{
						text: this.translateService.instant('ok'),
						cssClass: 'btnvalider'
					}]
				});

			}
		);
		return true;
	}

	public init() {}

	ngOnInit() {}

}
