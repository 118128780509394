export class Peculiarity {
    public id: number;
    private _label: string;
    public get defaultLabel() { return this._label }
    public set defaultLabel(label: string) { this._label = label }
    public get label() { return localize(this.i18nLabel, this._label) }
    public set label(label: string) { this._label = label }
    public i18nLabel: i18nString;
    public icon: string;
    public enabled: boolean;
    public update: boolean;

    public edit: boolean; // uniquement utile dans la page d'édition

    constructor(peculiarity?: Peculiarity) {
        if (peculiarity) {
            if (peculiarity.id !== undefined) this.id = Number(peculiarity.id)
            if (peculiarity.label) this.label = peculiarity.label
            this.i18nLabel = {}
            if (peculiarity.i18nLabel) this.i18nLabel = peculiarity.i18nLabel
            else if ((peculiarity as any).i18n_label) this.i18nLabel = (peculiarity as any).i18n_label
            if (peculiarity.icon) this.icon = peculiarity.icon
            if (peculiarity.enabled) this.enabled = Boolean(peculiarity.enabled)
            if (peculiarity.update) this.update = Boolean(peculiarity.update)
        }
    }
}
