import { Component, Input, Injectable, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NavController } from "@ionic/angular";

// Model
import { Card } from "../../../../../models/card";
import { MethodOfPayment } from "../../../../../models/method-of-payment";

// Pages
import { PayzenPage } from "../../../payzen/payzen.page";
import { PayzenRegisterPage } from "../../../payzen-register/payzen-register.page";
import { ValidationOrderPage } from "../../validation-order.page";

// Services
import { MopComponent } from "../mop-component";
import { CommandPipeService } from "../../../../../command-pipe/command-pipe-service";
import { AlertService } from "../../../../../services/alert.service";
import { UserService } from "../../../../../services/api/user.service";
import { QuoteService } from "../../../../../services/api/quote.service";
import { UtilsService } from "../../../../../services/utils.service";
import { Constants } from "../../../../../config/constants";
import { GlobalService } from "../../../../../services/global.service";
import { PayService } from "../../../../../services/pay.service";

import { ValidateOrderAction } from "../../actions/validate-order-action";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';


@Component({
	selector: 'card-mop',
	templateUrl: './card-mop.component.html',
	styleUrls: ['./card-mop.component.scss'],
})
export class CardMopComponent implements OnInit, MopComponent {
	@Input('valuePrefix') public valuePrefix?: string
	public title: string
	public mop: MethodOfPayment
	public get firstValue(): string { 
		let valuePrefix; return (valuePrefix = this.valuePrefix) ? valuePrefix + 'card' : 'card' 
	}

	public listCards: Card[] = []
	private timout: number;

	constructor(
		private navCtrl: NavController,
		private commandPipeService: CommandPipeService,
		private userService: UserService,
		private quoteService: QuoteService,
		private utilsService: UtilsService,
		private global: GlobalService,
		private translateService: TranslateService,
		private alertService: AlertService,
		public router: Router,
		private route: ActivatedRoute,
		private payService: PayService
	) {}

	// Modes de paiement
	private payWithPayzenForm() {
		if (this.userService.isLoggedIn()) {
			this.payService.payAction('tabs/payzen');
		}else {
			const navigationExtras: NavigationExtras = {
		      	state: {
		        	'backAction': 'tabs/payzen',
		      	}
		   };
		   this.router.navigate(['tabs/payzen-register'], navigationExtras);
		}
	}

	private payWithPayzenToken(index: number) {
		let that = this;
		this.userService.payOrder(that.listCards[index].id).subscribe((data) => {
			new ValidateOrderAction(this.quoteService, this.commandPipeService, this.utilsService, this.translateService, this.alertService).do()
		}, (error) => {
			that.payService.presentErrorAlert(this.translateService.instant("card-mop.invalid-token-alert.message"), error);
		});
	}

	// Ping de l'api heyou pour connaitre la réponse de payzen sur le paiement
	private cancelPayment() {
		this.payService.presentErrorAlert(this.translateService.instant("card-mop.timeout-alert.message"));
	}

	private checkPayment(requestCount: number = 0) {
		var that = this;
		this.timout = setTimeout(() => {
			try {
				this.userService.checkPaiement(this.global.idPanier).subscribe((data) => {
					if (data != null) {
						// Retour sur la page de choix de paiement
						this.router.navigate(['tabs/validation-order']);
						data === 1 ?  new ValidateOrderAction(this.quoteService, this.commandPipeService, this.utilsService, this.translateService, this.alertService).do() : that.payService.presentErrorAlert(this.translateService.instant("validation-order.card-mop.payment-fail"));
					} else {
						// Si data est null on continue de ping l'api heyou
						// TODO: limiter le nombre de ping ?
						// if (requestCount < Constants.CHECK_PAYMENT_LIMIT && this.navCtrl.getActive().component == PayzenPage)
						if (requestCount < Constants.CHECK_PAYMENT_LIMIT && this.router.url == '/tabs/payzen')
							that.checkPayment(requestCount + 1);
						else
							that.cancelPayment();
					}
				}, (error) => {
					this.router.navigate(['tabs/validation-order']);
					new ValidateOrderAction(this.quoteService, this.commandPipeService, this.utilsService, this.translateService, this.alertService).do()
				});
			}
			catch (ex) {
				// console.log(ex);
			}
		}, Constants.CHECK_PAYMENT_DELAY);
	}

	/* MopComponent */
	public init() {
		if (this.timout) { clearTimeout(this.timout); this.timout = null }

		var that = this;
		this.userService.getMyCards(false).subscribe((data) => {
			for (let i in data) that.listCards.push(new Card(data[i]));
		}, (error) => {
			// Erreur silencieuse. Si il y a une erreur, les cartes préenregistrées ne sont pas affichées
		});
	}
	public processValue(value: string): boolean {
		let valuePrefix = this.valuePrefix
		let i = value
		if (valuePrefix) {
			if (!value.startsWith(valuePrefix)) return false
			i = value.substr(valuePrefix.length)
		}

		const infoTime = this.quoteService.getFullDate();

		this.quoteService.setMethodOfPayment(this.mop, infoTime).subscribe(
			data => i == 'card' ? this.payWithPayzenForm() : this.payWithPayzenToken(Number(i)),
			error => this.payService.presentErrorAlert(this.translateService.instant("card-mop.timeout-alert.message"))
		)
		return true
	}

	ngOnInit() {}

}
