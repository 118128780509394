import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { IdleDetectorService } from "./idle-detector.service";

@Injectable({
	providedIn: 'root'
})
export class LoaderService {

    private isLoaderVisible = false;
    public enabled: boolean = true;
    private retainCount = 0;

	constructor(
		private loadingCtrl: LoadingController,
		private idleDetectorService: IdleDetectorService
	) {
		// let interval = setInterval(() => {
		//     this.dismiss();
		// },5000)
	}

	/**
	 * Method to show loader
	*/
	async present(isDuration:boolean = true) {
		// console.log('show loader: ', this.retainCount);

		if (this.retainCount === 0 && this.enabled) {
			this.retainCount++;
			this.isLoaderVisible = true;

			let loaderObj;

			if(isDuration){
				loaderObj = {
					// message: '<ion-spinner name="crescent"></ion-spinner>',
					duration: 5000,
					cssClass: 'custom-loading-class',
				};
			}else{
				loaderObj = {
					// message: '<ion-spinner name="crescent"></ion-spinner>',
					cssClass: 'custom-loading-class',
				};
			}

			const loader = await this.loadingCtrl.create(loaderObj);
			loader.onDidDismiss().then(()=>{
				this.retainCount--;
				this.isLoaderVisible = false;
				this.idleDetectorService.resume();
				// console.log('Dissmissed')
			})

			await loader.present().then(()=>{
				console.log('presented');
				this.idleDetectorService.pause()
			});
		}
	}

	/**
	 * Method to hide loader
	*/
	dismiss() {
		// console.log("Hide Loader");
		// this.loadingCtrl.getTop().then(loader => {
		// 	console.log(loader);
		//   	if (loader) {
		//     	this.loadingCtrl.dismiss();
		//     	this.isLoaderVisible = false;
		//     	// this.idleDetectorService.resume()
		//   	}
		// });

		if (this.retainCount > 0) {
			this.isLoaderVisible = false;

			this.loadingCtrl.getTop().then(loader => {
			  	if (loader) {
			    	this.loadingCtrl.dismiss();
			    	// this.isLoaderVisible = false;
			    	// this.idleDetectorService.resume()
			  	}
			});
			// this.loadingCtrl.dismiss();
		}
	}

	clearLoader(){
		// console.log('hide loader by timer');
		this.loadingCtrl.getTop().then(loader => {
		  	if (loader) {
		    	this.loadingCtrl.dismiss();
		    	this.isLoaderVisible = false;
		    	// this.idleDetectorService.resume()
		  	}
		});
	}

}




