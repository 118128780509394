import { Conf } from "./conf";

export class NeptingConf implements Conf {
    public device: string = 'nepting'
    public ip: string
    public port: number
    public merchantId : string
    public host: string
    public wsdl: string

    constructor(neptingConf ?: NeptingConf) {
        if (neptingConf) this.overrideConf(neptingConf)
    }

    public overrideConf(neptingConf: NeptingConf) {
        if (neptingConf.device == this.device) {
            if(neptingConf.ip)          this.ip =           neptingConf.ip
            if(neptingConf.merchantId)  this.merchantId =   neptingConf.merchantId
            if(neptingConf.port)        this.port =         Number(neptingConf.port)
            if(neptingConf.host)        this.host =         neptingConf.host
            if(neptingConf.wsdl)        this.wsdl =         neptingConf.wsdl
        }
    }
}