import { Reader } from "../../services/serial-usb.service";
import { ArticleService } from "../../services/api/article.service";
import { QuoteService } from "../../services/api/quote.service";
import { CategoryService } from "../../services/api/category.service";
import { Article } from "../../models/article";
import { Category } from "../../models/category";
import { UtilsService } from "../../services/utils.service";

export class BeesketArticleReader implements Reader {
    private data: string = ''

    constructor(private articleService: ArticleService, private categoryService: CategoryService, private quoteService: QuoteService, private errorCallback?: (error) => void) {}

    public incomingData(buffer: ArrayBuffer) {
        let data = this.data
        var bytes = new Uint8Array(buffer)
        console.log('SerialUSB - BeesketArticleReader', bytes)
        // Longueur du message :
        // 1 caractère pour le nombre de mots (n)
        // 8 caractère par mot (n * 8)
        // eg. 3|00000001|00000002|00000003 = 3 * 8 + 1
        const messageLength = 1 + Number(data.length > 0 ? data[0] : String.fromCharCode(bytes[0])) * 8
        bytes.forEach(b => data += String.fromCharCode(b))
        if (data.length >= messageLength) {
            const message = data.slice(0, messageLength)
            const words = message.match(/([BT][0-9]{7})/g)

            const articleCodes = []
            let bundleCode = null

            for (const word of words) {
                switch(word[0]) {
                    case 'B':
                        bundleCode = word
                        articleCodes.push(word)
                        break
                    case 'T':
                        articleCodes.push(word)
                        break
                }
            }
            let categories: {[id: number]: Category} = this.categoryService.categories
            let articles: Article[]
            const processQuoteAdd = () => {
                if (!categories || !articles) return
                const categoryArticles = []
                // Association des articles a leur catégorie pour l'ajout au panier
                for (const i in categories) {
                    const category = categories[i]
                    let foundArticle: Article
                    const categoryArticle = category.articles.find(categoryArticle => !!(foundArticle = articles.find(article => categoryArticle.id == article.id)));
                    if (categoryArticle) {
                        categoryArticles.push(categoryArticle)
                        categoryArticle.categoryId = category.id
                        UtilsService.removeObjectFromArray(foundArticle, articles)
                    }
                }

                // Association des articles au bundle
                let bundle = articles.find(article => article.code == bundleCode)
                if (bundle) {
                    bundle.children = categoryArticles
                    this.quoteService.addToQuote(bundle).subscribe(
                        (data) => {},
                        (error) => { this.errorCallback && this.errorCallback(error) }
                    )
                } else {
                    this.errorCallback && this.errorCallback('bundle article code invalid')
                }
            }
            /*
            this.categoryService.getCategories().subscribe(
                (data) => { categories = data; processQuoteAdd() },
                (error) => { this.errorCallback && this.errorCallback(error) }
            )*/
            this.articleService.getArticleFromCodes(articleCodes).subscribe(
                (data) => { articles = data; processQuoteAdd() },
                (error) => { this.errorCallback && this.errorCallback(error) }
            )

            data = data.slice(messageLength)
        }
        this.data = data
    }
}