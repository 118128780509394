import { UtilsService } from "../services/utils.service"

import { QuoteItem } from "./quoteItem";
import { Payment } from "./payment";
import { MethodOfPayment } from "./method-of-payment";
import { PickUpHour } from "./pick-up-hour";
import { Store } from "./store";

export class Quote {
    id: number;
    data_aco: number;
    data_ava: number;
    data_opeco: number;
    items: QuoteItem[] = [];
    amount_et: number;
    amount_vat: number;
    amount_ati: number;
    discount_amount_ati: number;
    created_at: Date;
    updated_at: Date;
    quote_id: number;
    status : any = null;
    paid : boolean = false;
    payments: Payment[];
    method_of_payment: MethodOfPayment;
    pseudo: string; // pas cohérent avec user.pseudonyme. (nickname semble plus approprié)
    pick_up_hour: PickUpHour;
    pick_up_day: string;
    pick_up_date: string;
    delivery_address: string;
    offers: any[] = [];
    store:Store = null;

    get amountTotal() { return this.amount_ati + this.discount_amount_ati  }
    get amountTotalWithDiscount() { return this.amount_ati }

    // dupliqué (et adapté) de order.ts dans AdminFront
    public get splittedAmountsByVat(): {rate: number, amount_et: number, amount_ati: number, amount_vat: number}[] {
        const splittedAmountsByVat: {[id:number]:{rate: number, amount_et: number, amount_ati: number, amount_vat: number}} = {}
        const recurse = (items: QuoteItem[]) => {
            for (const item of items) {
                const vat = item.vat
                const rate = vat ? Number(vat.rate) ||0.0 : 0.0
                let amounts = splittedAmountsByVat[rate]
                if (!amounts) splittedAmountsByVat[rate] = amounts = { rate: rate, amount_et: 0, amount_ati: 0, amount_vat: 0 }
                amounts.amount_et += item.amount_et
                amounts.amount_vat += item.amount_vat
                amounts.amount_ati += item.amount_ati

                if (item.children) recurse(item.children)
            }
        }

        recurse(this.items)

        return Object.keys(splittedAmountsByVat).sort((a,b) => Number(a) - Number(b)).map(key => splittedAmountsByVat[key])
    }

    constructor(data = null) {
        if (typeof data === "undefined" || data === null) {
            this.id = -1;
            this.quote_id = -1;
            this.data_aco = 0;
            this.data_ava = 0;
            this.data_opeco = 0;
            this.items = [];
            this.amount_et = 0;
            this.amount_vat = 0;
            this.amount_ati = 0;
            this.discount_amount_ati = 0;
            this.created_at = new Date();
            this.updated_at = new Date();
            this.offers = [];
        }
        else {
            this.setData(data);
        }
    }

    public get allowTakeAway(): boolean {
        let items = this.items
        for (let item of items) {
            let article = item.article
            if (article && article.takeaway) return true
        }

        return false
    }

    private refreshQuoteItems(quoteItems: QuoteItem[], dataItems: any[]) {
        if (typeof dataItems !== "undefined" && (dataItems.length > 0 || Object.keys(dataItems).length > 0)) {
            let dataItem: any;

            // Supprime les items qui n'ont pas de correspondance dans dataItem
            // c-a-d les articles supprimé
            let isDeletedItem: boolean;
            let quoteItemsCopy = quoteItems.slice()
            for (let quoteItem of quoteItemsCopy) {
                for (let i in dataItems) {
                    dataItem = dataItems[i];
                    isDeletedItem = dataItem.id != quoteItem.id;
                    if (!isDeletedItem) break; // stop si l'item n'est pas supprimé
                }

                if (isDeletedItem) UtilsService.removeObjectFromArray(quoteItem, quoteItems);
            }

            // Modifie ou ajoute les items selon le contenu de dataItem
            // note: for...in car le ws renvois data.items sous forme [] ou {} du au serializer json php
            let quoteItem: QuoteItem;
            for (let i in dataItems) {
                quoteItem = null;
                dataItem = dataItems[i];
                for (let existingItem of quoteItems) {
                    // Cherche si l'item existe dans le panier local
                    if (existingItem.id == dataItem.id) {
                        quoteItem = existingItem
                    }
                }

                if (quoteItem) quoteItem.setData(dataItem);
                else quoteItems.push(new QuoteItem(dataItems[i]));
            }
        } else {
            quoteItems.splice(0);
        }
    }

    setData(data) {
      if (typeof data !== "undefined" && data !== null) {
        this.id = typeof data.id !== "undefined" ? data.id : undefined;
        this.quote_id = typeof data.quote_id !== "undefined" ? data.id : undefined;
        this.data_aco = typeof data.data_aco !== "undefined" ? data.data_aco : 0;
        this.data_ava = typeof data.data_ava !== "undefined" ? data.data_ava : 0;
        this.data_opeco = typeof data.data_opeco !== "undefined" ? data.data_opeco : 0;
        this.amount_et = typeof data.amount_et !== "undefined" ? data.amount_et : 0;
        this.amount_vat = typeof data.amount_vat !== "undefined" ? data.amount_vat : 0;
        this.amount_ati = typeof data.amount_ati !== "undefined" ? parseFloat(data.amount_ati) : 0;
        this.created_at = typeof data.created_at !== "undefined" ? data.created_at : new Date();
        this.updated_at = typeof data.updated_at !== "undefined" ? data.updated_at : new Date();
        this.discount_amount_ati = typeof data.discount_amount_ati !== "undefined" ? parseFloat(data.discount_amount_ati) : 0;
        this.status = typeof data.status !== "undefined" ? data.status : null;
        this.paid = typeof data.paid !== "undefined" ? data.paid : false;
        this.pseudo = typeof data.pseudo !== "undefined" ? data.pseudo : undefined;
        if (typeof data.payments !== "undefined") {
            this.payments = []
            for (const payment of data.payments) {
                this.payments.push(new Payment(payment));
            }
        }
        if (data.method_of_payment) this.method_of_payment = new MethodOfPayment(data.method_of_payment)
        this.pick_up_date = data.pick_up_date
        this.pick_up_day = data.pick_up_day
        this.pick_up_hour = data.pick_up_hour ? new PickUpHour(data.pick_up_hour) : null
        this.delivery_address = data.delivery_address
        
        this.offers = typeof data.offers !== "undefined" ? data.offers : [];
        
        this.refreshQuoteItems(this.items, data.items);
      }
    }
    /**
     * Vérifie si le panier est vide
     * @return {boolean}
     */
    isEmpty(): boolean {
        return !this.items || this.items.length === 0;
    }
}
