import { Injectable, Inject } from '@angular/core';
import { Observable, ReplaySubject }  from 'rxjs';

import { Constants } from '../../config/constants';
import { ApiService } from './api.service';
import { StorageService } from '../storage.service';
import { UtilsService } from '../utils.service';
import { StorageData } from '../../models/storageData';
import { ApiRequest } from '../../models/apirequest';
import { CategoryNav } from '../../models/category';

@Injectable({
	providedIn: 'root'
})
export class CategoryService {

	private _categories: {[id: number]: CategoryNav}
	private setCategories(categories: {[id: number]: CategoryNav}) { this._categories = categories }
	get categories() { return  this._categories }

	constructor( 
		private apiService: ApiService,
		private utilsService: UtilsService
	) {
	  apiService.delegate = this
	}

	public getCategoryWithId(categoryId: number) {
	  if (!this._categories) {
	      let storedData = this.getCategoriesInStorage().getValue(true)
	      this.hydrateCategories(storedData)
	  }{}

	  return categoryId && this._categories ? this._categories[categoryId] : null;
	}

	private hydrateCategories(rawCategories: CategoryNav[]) {
	  	let categories = {};
	  	for (let i in rawCategories) {
	      let category = new CategoryNav(rawCategories[i]);
	      categories[category.id] = category;
	  	}

	  	this.setCategories(categories);
	}

	/**
	* Vérifie si les catégories en cache existent et ne sont pas expirés
	*/
	private hasStoredCategoriesExpired(): boolean {
	  var Categories = this.getCategoriesInStorage();
	  return (!Categories || Categories.isExpired());
	}

	private callApiForCategories() : Observable<any>  {
	  	let observer = new ReplaySubject<any>();
	  	var apiRequest = new ApiRequest<any>(
	      Constants.URL_CATEGORIES,
	      null
	  	);
	  	this.apiService.get(apiRequest).subscribe(
	      (data) => {
	          this.hydrateCategories(data);
	          this.saveCategoriesInStorage();
	          observer.next(this.categories);
	      },
	      (error) => {
	          observer.error(error);
	      },
	      () => {
	          observer.complete();
	      });
	  	return observer;
	}

	private getCategoriesInStorage(): StorageData {
	  return StorageService.get(Constants.STORAGE_KEY_CATEGORIES, true);
	}

	private saveCategoriesInStorage() {
	  	var expireDate = this.utilsService.addSecondsToDate(new Date(), Constants.CATEGORIES_CACHE_EXPIRES);
	  	let serializedCategories = JSON.stringify(this.categories, (k,v) => {
	      // TODO : trouver une meilleurs solution pour les propriétés a exclure
	      return k.charAt(0) == '_' ? undefined : v;
	   })
	  	StorageService.set(Constants.STORAGE_KEY_CATEGORIES, serializedCategories, Constants.TYPE_STORAGE_LOCAL, expireDate);
	}

	public getCategories(): Observable<any>  {
	  	if (this.hasStoredCategoriesExpired()) {
	      return this.callApiForCategories();
	  	} else {
	      try {
					let storedData = this.getCategoriesInStorage().getValue(true)

					let observer = new ReplaySubject<any>();
					this.hydrateCategories(storedData)

					observer.next(this.categories);
					observer.complete();
					return observer;
	      }
	      catch (ex) {
	         // console.log(ex);
	         return this.callApiForCategories();
	      }
	  	}
	}

	
}
