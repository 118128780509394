import { Article } from './article';
import { Constants } from '../config/constants';

export interface CustomNavArticleList {
    position: number;
    article: Article;
}

export interface CustomNavResponse {
    id?: number;
    type?: string;
    label?: string;
    description?: string;
    position?: number;
    children: CustomNavResponse[];
    articles?: CustomNavArticleList[];
    i18n_label?: any;
    parent?: CustomNavResponse;
    level?: number;
    img?: string;
    image?: string;
}

// TODO: interface navigation child
export class NavigationArticle {
    public position: number;
    public article: Article;

    get id(): number {return this.article.id };
    get type(): string { return null }
    get label(): string { return this.article.label };
    get description(): string { return this.article.description };
    get children(): CustomNavResponse[] { return [] };
    get articles(): NavigationArticle[] { return [] };
    get notShowNav(): boolean {return this.article.not_show_nav}
    private _parent: CustomNavResponse;
    get parent() { return this._parent; }
    private _level: number;
    get level() { return this._level; }
    // note: a ce jour les NavigationArticle ne sont pas affiché,
    //       leurs Article sont extrait pour être affiché en tant qu'article
    //       cette propriété n'est donc pas utilisé a ce jour.
    get img() { return Constants.BASE_ARTICLES_IMAGE_URL + this.article.code + '_0.png'; }
    get image() { return this.img; }

    constructor(data, parent: CustomNavResponse = null) {
        this.position = typeof data.position !== "undefined" ? Number(data.position) :undefined;
        this.article = typeof data.article !== "undefined" ? new Article( data.article ) : undefined;
        this._parent = parent;
        this._level = parent ? parent.level + 1 : 0;
    }
}