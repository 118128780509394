import { Order } from "./order";
import { Quote } from "./quote";
import { MethodOfPayment } from "./method-of-payment";
import { User } from "./user";

export class Payment {
    public id: number
    public user_token: string
    public transaction_site_id: number
    public transaction_id: number
    public transaction_date: Date
    public amount: number
    public authorization_number: string
    public card_number: string
    public transaction_uuid: string
    public order: Order
    public quote: Quote
    public method_of_payment: MethodOfPayment
    public user: User

    constructor(payment?: Payment) {
        if (payment) {
            if (payment.id != undefined) this.id = payment.id
            if (payment.user_token != undefined) this.user_token = payment.user_token
            if (payment.transaction_site_id != undefined) this.transaction_site_id = payment.transaction_site_id
            if (payment.transaction_id != undefined) this.transaction_id = payment.transaction_id
            if (payment.transaction_date != undefined) this.transaction_date = payment.transaction_date
            if (payment.amount != undefined) this.amount = payment.amount
            if (payment.authorization_number != undefined) this.authorization_number = payment.authorization_number
            if (payment.card_number != undefined) this.card_number = payment.card_number
            if (payment.transaction_uuid != undefined) this.transaction_uuid = payment.transaction_uuid
            if (payment.order != undefined) this.order = payment.order
            if (payment.quote != undefined) this.quote = payment.quote
            if (payment.method_of_payment != undefined) this.method_of_payment = payment.method_of_payment
            if (payment.user != undefined) this.user = payment.user
        }
    }
}
