import { Plannable } from "../capinfo/planning/planning.component";
import '../capinfo/extensions/date.extensions'

export class PickUpHour implements Plannable {
	public id: number
	public begin: Date
	public end: Date
	public enabled: boolean
	public max_orders_per_day: number
	public orders_per_day: {[id: string]: number}

	public selected: boolean
	public outdated: boolean
	constructor(
		pickUpHour?: PickUpHour
	) {
		if (pickUpHour) {
			this.id = pickUpHour.id
			this.begin = Date.instance(pickUpHour.begin)
			this.end = Date.instance(pickUpHour.end)
			this.enabled = pickUpHour.enabled
			this.max_orders_per_day = pickUpHour.max_orders_per_day
			this.orders_per_day = pickUpHour.orders_per_day
		}
	}

	public isAvailableForDay(date: string) {
		return this.max_orders_per_day == 0
			|| !this.orders_per_day
			|| !this.orders_per_day[date]
			|| this.orders_per_day[date] < this.max_orders_per_day
	}
}