// src/models/article.ts
import { Constants } from '../config/constants'

import { ArticleAttribute}  from './article-attribute'
import { Attribute}         from './attribute'
import { Bundle }           from "./bundle"
import { Option }           from "./option"
import { Price }            from "./price"
import { PriceLayer }       from "./price-layer"
import { PriceLayerGroup }  from "./price-layer-group"
import { ArticleOffer }     from "./article-offer"
import { ArticleOfferGroup } from "./article-offer-group"
import { Stock }            from "./stock"
import * as moment from 'moment';


export enum OptionType { both, hidden, visible, active, empty }

export class Article {
    private i18nLabel               : i18nString
    private _label                  : string
    private i18nDescription         : i18nString

    public id?                      : number
    public code                     : string
    public image                    : string
    public _description             : string
    public price                    : number
    public stocks                   : Stock[]       = undefined
    public prices                   : {}            = undefined // Tableau associatif de Price (Map<String,Price>)
    public options                  : Option[]      = null
    public bundle                   : Bundle        = null
    public children                 : Article[]     = null
    public categoryId               : number        = null
    public supplement               : number|null   = null
    public stockable                : boolean       = false
    public is_available             : boolean       = true
    public deferred_takeaway        : boolean       = false
    public takeaway                 : boolean       = false
    public specific_attribute       : string        = ''
    public deferred_takeaway_result : boolean       = false
    public merchandise_structure_1  : number
    public merchandise_structure_2  : number
    public merchandise_structure_3  : number
    public merchandise_structure_4  : number
    public merchandise_structure_5  : number
    public cross_sellings           : number[]
    public position                 : number
    // TODO: trouver mieux, n'est utile que pour l'affichage des articles dans les ventes additionnelles
    public isChecked                : boolean = null
    public isMultiple               : boolean = null
    public article_attributes       : ArticleAttribute[]
    public article_attributes_by_attribute_ids: {[id:number]:ArticleAttribute[]}
    public attributes               : Attribute[]
    public price_layers             : PriceLayer[]|null
    public price_layers_groups      : PriceLayerGroup[]|null
    public article_options          : {position:number, option: Option}[]
    public delivery                 : boolean       = false
    public delivery_time            : number
    public get priceLayerPrice() {
        
        if(!this.price_layers_groups) {
            return null
        }
        
        const priceLayersGroups = this.price_layers_groups
        let priceLayersGroup: PriceLayerGroup = priceLayersGroups.find(priceLayerGroup =>
            moment(Date.instance(priceLayerGroup.from_date)).utc().toDate() <= moment(Date.instance()).utc(true).toDate()
            && moment(Date.instance(priceLayerGroup.to_date)).utc().toDate() >= moment(Date.instance()).utc(true).toDate()
            )
            if(priceLayersGroup) {
                return priceLayersGroup.priceLayerPrice
            }
            
            return null
        }
        
    public not_show_nav             : boolean
    public article_offers           : ArticleOffer[]|null
    public article_offers_groups    : ArticleOfferGroup[]|null
    public get articleOfferPrice() {
        
        if(!this.article_offers_groups) {
            return null
        }

        const articleOffersGroups = this.article_offers_groups
        let articleOffersGroup: ArticleOfferGroup = articleOffersGroups.find(articleOffersGroup =>
            moment(Date.instance(articleOffersGroup.from_date)).utc().toDate() <= moment(Date.instance()).utc(true).toDate()
            && moment(Date.instance(articleOffersGroup.to_date)).utc().toDate() >= moment(Date.instance()).utc(true).toDate()
        )


        if(articleOffersGroup) {
            return articleOffersGroup.articleOfferPrice
        }

        return null
    }

    public get articleOfferLabel1() {
        
        if(!this.article_offers_groups) {
            return null
        }

        const articleOffersGroups = this.article_offers_groups
        let articleOffersGroup: ArticleOfferGroup = articleOffersGroups.find(articleOffersGroup =>
            moment(Date.instance(articleOffersGroup.from_date)).utc().toDate() <= moment(Date.instance()).utc(true).toDate()
            && moment(Date.instance(articleOffersGroup.to_date)).utc().toDate() >= moment(Date.instance()).utc(true).toDate()
        )


        if(articleOffersGroup) {
            return articleOffersGroup.label1
        }

        return null
    }

    public get articleOfferLabel2() {
        
        if(!this.article_offers_groups) {
            return null
        }

        const articleOffersGroups = this.article_offers_groups
        let articleOffersGroup: ArticleOfferGroup = articleOffersGroups.find(articleOffersGroup =>
            moment(Date.instance(articleOffersGroup.from_date)).utc().toDate() <= moment(Date.instance()).utc(true).toDate()
            && moment(Date.instance(articleOffersGroup.to_date)).utc().toDate() >= moment(Date.instance()).utc(true).toDate()
        )


        if(articleOffersGroup) {
            return articleOffersGroup.label2
        }

        return null
    }

    public get label()                              { return localize(this.i18nLabel, this._label)                          }
    public get description()                        { return localize(this.i18nDescription, this._description)              }
    public get isSupplement()      : boolean        {return this.supplement != null                                        }
    public get isOptionnable()     : boolean        { return this.options && this.options.length > 0                        }
    public get isComposable()      : boolean        { return !!this.bundle                                                  }
    public get isAdultOnly()       : boolean        {return this.specific_attribute && this.specific_attribute == 'ALCOOL' }
    public get hasCrossSellings()  : boolean        {return this.cross_sellings && this.cross_sellings.length > 0          }
    public get infoImage()         : string         { return Constants.BASE_INFO_ARTICLE_IMAGE_URL + this.code + "_0.png"     }
    public get altInfoImage()      : string         { return Constants.BASE_ARTICLES_IMAGE_URL + this.code + "_0.png"         }
    public get isDelivery()        : boolean        { return this.delivery                                                  }
    public get isAvailable()       : boolean        { return this.is_available                                               }
    public set isAvailable(isAvailable: boolean)    { this.is_available = isAvailable }
    public set isDelivery(isDelivery: boolean)      { this.delivery = isDelivery }

    constructor(data) {
        this.id                         = this._setData(data.id,                       undefined)
        this.code                       = this._setData(data.code,                     undefined)
        this._label                     = this._setData(data.label,                    undefined)
        this._description               = this._setData(data.description,              undefined)
        this.price                      = this._setData(data.price,                    undefined)
        this.categoryId                 = this._setData(data.categoryId,               null)
        this.supplement                 = this._setData(data.supplement,               undefined)
        this.deferred_takeaway          = this._setData(data.deferred_takeaway,        false)
        this.stockable                  = this._setData(data.stockable,                false)
        this.is_available               = this._setData(data.is_available,             true)
        this.takeaway                   = this._setData(data.takeaway,                 false)
        this.specific_attribute         = this._setData(data.specific_attribute,       '')
        this.deferred_takeaway_result   = this._setData(data.deferred_takeaway_result, false)
        this.delivery                   = this._setData(data.delivery,                 false)
        this.not_show_nav               = this._setData(data.not_show_nav,                 false)
        this.i18nLabel                  = typeof data.i18n_label              !== "undefined" ? data.i18n_label                      : data.i18nLabel
        this.i18nDescription            = typeof data.i18n_description        !== "undefined" ? data.i18n_description                : data.i18nDescription
        this.bundle                     = data.bundle && typeof data.bundle   !== "undefined" ? new Bundle(data.bundle) : null
        this.merchandise_structure_1    = typeof data.merchandise_structure_1 !== "undefined" ? Number(data.merchandise_structure_1) : 0
        this.merchandise_structure_2    = typeof data.merchandise_structure_2 !== "undefined" ? Number(data.merchandise_structure_2) : 0
        this.merchandise_structure_3    = typeof data.merchandise_structure_3 !== "undefined" ? Number(data.merchandise_structure_3) : 0
        this.merchandise_structure_4    = typeof data.merchandise_structure_4 !== "undefined" ? Number(data.merchandise_structure_4) : 0
        this.merchandise_structure_5    = typeof data.merchandise_structure_5 !== "undefined" ? Number(data.merchandise_structure_5) : 0

        // this.option_item = typeof data.option_item !== "undefined" ? new OptionItem(data.option_item) : null;
        if(typeof data.stocks !== "undefined") {
            this.stocks = []
            for (let i in data.stocks) {
                let stock = new Stock(data.stocks[i])
                if(typeof stock.store.id == "undefined")
                    stock.store.id = parseInt(i)
                this.stocks.push(stock)
            }
        }

        if(typeof data.prices !== "undefined") {
            this.prices = {}

            for (let i in data.prices) {
                this.prices[i] = new Price(data.prices[i])
            }
        }

        if(typeof data.options !== "undefined") {
            this.options = []

            for (let i in data.options) {
                this.options.push(new Option(data.options[i]))
            }
        }

        if (data.cross_sellings) {
            this.cross_sellings = []

            for (let i in data.cross_sellings) {
                let cross_selling = data.cross_sellings[i]
                let cross_article = cross_selling.cross_article

                if (cross_article) {
                    this.cross_sellings.push(cross_article.id)
                } else {
                    this.cross_sellings.push(cross_selling)
                }
            }
        }

        this.attributes                          = []
        this.article_attributes                  = []
        this.article_attributes_by_attribute_ids = {}
        if (data.article_attributes && data.article_attributes.length > 0) {
            for (let i in data.article_attributes) {
                let a = this.article_attributes_by_attribute_ids[data.article_attributes[i].attribute.id]

                if (!a) {
                    a = this.article_attributes_by_attribute_ids[data.article_attributes[i].attribute.id] = []
                }

                a.push(data.article_attributes[i])

                if (!this.attributes.find(attribute => attribute.id == data.article_attributes[i].attribute.id)) {
                    this.attributes.push(new Attribute(data.article_attributes[i].attribute))
                }

                this.article_attributes.push(new ArticleAttribute(data.article_attributes[i]))
            }
        }

        if(data.price_layers) {
            this.price_layers = []
            for (const price_layer of data.price_layers) {
                this.price_layers.push( new PriceLayer(price_layer) );
            }
        }

        if(data.price_layers_groups) {
            this.price_layers_groups = []
            for (const price_layers_group of data.price_layers_groups) {
                this.price_layers_groups.push( new PriceLayerGroup(price_layers_group) );
            }
        }

        if(data.article_offers) {
            this.article_offers = []
            for (const article_offer of data.article_offers) {
                this.article_offers.push( new ArticleOffer(article_offer) );
            }
        }

        if(data.article_offers_groups) {
            this.article_offers_groups = []
            for (const article_offers_group of data.article_offers_groups) {
                this.article_offers_groups.push( new ArticleOfferGroup(article_offers_group) );
            }
        }

        if(data.article_options) {
            this.article_options = []
            for(const obj of data.article_options) {
                this.article_options.push(obj)
            }
        }

        if(data.delivery_time) {
            this.delivery_time = data.delivery_time
        }
    }

    private _setData(field, value = undefined) {
        const result = (typeof field !== "undefined")
            ? field
            : value

        return result
    }

    public getOptionsWithType(optionType?: OptionType) {
        let filter :(value: Option) => boolean

        switch(optionType) {
            case OptionType.active:
                filter = (value: Option) => value.active
                break
            case OptionType.hidden:
                filter = (value: Option) => value.hidden
                break
            case OptionType.visible:
                filter = (value: Option) => !value.hidden
                break
            case OptionType.empty:
                filter = (value: Option) => value.items !== undefined
                break
            default:
                filter = (value: Option) => true
                break
        }

        const result = this.options
            ? this.options.filter(filter)
            : null

        return result
    }

    /**
     * Indique si l'article a des options ou non
     * @param optionType retourne les options correspondant au type défini
     * @return {boolean}
     */
    public hasOptions(optionType?: OptionType): boolean {
        let filteredOptions = this.getOptionsWithType(optionType)

        const result = filteredOptions && filteredOptions.length > 0

        return result
    }

    /**
     * Retourne le prix de l'article correspondant au store ou le prix par défaut
     * Si l'article n'a pas de prix, ou que l'article n'a pas de prix pour un store, on retournera sa valeur par défaut (this.price)
     * Sinon, on retourne le prix au store_id correspondant
     * @param {number|null} store_id l'id du store
     * @return {number|null} le prix de l'article dans le store store_id ou null
     */
    getPrice(store_id: number = null): number|null {
        const result = store_id
        && this.prices
        && this.prices[store_id]
            ? this.prices[store_id].price
            : this.price

        return result
    }

    /**
     * Retourne le prix à la quantité de l'article correspondant au store ou le prix par défaut
     * Si l'article n'a pas de prix de prix pour un store, on retournera 0
     * Sinon, on retourne le prix au store_id correspondant
     * @param {number|null} store_id l'id du store
     * @return {number|null} le prix de l'article dans le store store_id ou null
     */
    getPricePerUnit(store_id: number = null): number|null {
        const result = store_id
        && this.prices
        && this.prices[store_id]
            ? this.prices[store_id].price_per_unit
            : 0

        return result
    }

    /**
     * TODO: non utilisé pour le moment
     * Retourne le stock de l'article sur le store store_id
     * Retourne 99 si le store_id est null (falsy) pour éviter de bloquer l'ajout au panier si on a pas choisi de store
     * @param {number|null} store_id l'id du store
     * @return {Stock|null} le stock
     */
    public getStock(store_id: number = null): Stock {
        if(this.stocks && store_id) {
            for(let i in this.stocks) {
                let stock = this.stocks[i]

                if (stock.storeId === store_id) {
                    return stock
                }
            }
        }

        return null
    }

    public getisAvailable(store = null) : boolean { 
        let result = false
        let stock = this.getStock(store);
        if(this.stockable) {
            
            if( stock !== null){

                result = stock.qty != 0 && !stock.comingSoon && !stock.outOfStock
            }else
                result = false
        }else{
            if( stock !== null){

                result = !stock.comingSoon && !stock.outOfStock
            }else
                result = true
        }
        this.is_available = result
        return result
    }
    /**
     * TODO: non utilisé pour le moment
     * Défini le stock de l'article sur le store store_id
     * @param {Stock} newStock le stock a définir
     */
    public setStock(newStock: Stock) {
        let isSet  = false
        let stocks = this.stocks

        if(stocks && newStock.storeId) {
            for(let i in stocks) {
                let stock = stocks[i]

                if(stock.storeId === newStock.storeId) {
                    stock.setData(newStock)

                    isSet = true

                    break
                }
            }
        }

        if (!isSet) {
            if (!stocks) {
                stocks = this.stocks = []
            }

            stocks.push(newStock)
        }
    }
}
