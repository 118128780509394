import { Action } from "../std/action";
// import { App } from "ionic-angular";
import { CommandPipeService } from "./command-pipe-service";
import { PlaceService } from "../services/api/place.service";
import { QuoteService } from "../services/api/quote.service";
// import { PickUpHourPage } from "../../pages/command-pipe/pick-up-hour/pick-up-hour.page";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

export class CommandStepPickUpHour implements Action {
	static readonly id = 'CommandStepPickUpHour'

	constructor(
		// private app: App, 
		public router: Router,
		private commandPipeService: CommandPipeService, 
		private placeService: PlaceService, 
		private quoteService: QuoteService
	) {}

	public do() {
		const place = this.commandPipeService.place;
		const placeType = this.commandPipeService.placeType;

		// console.log('CommandStepPickUpHour place: ', place);
		// console.log('CommandStepPickUpHour placeType: ', placeType);
		// console.log('CommandStepPickUpHour pickUpTime: ', this.commandPipeService.pickUpTime);

		if (place && (placeType.code == 1 || placeType.code == 3) && !this.commandPipeService.pickUpTime) {
			this.placeService.getTimeSlots(placeType.code).subscribe(
				timeSlots => { 
					const navigationExtras: NavigationExtras = {
						state: {
							timeSlots: timeSlots
						}
					};
					console.log(timeSlots);
					this.router.navigate(['tabs/pick-up-hour'], navigationExtras);
					// Comment by sajid and replace by router
					// this.app.getActiveNav().push(PickUpHourPage, { timeSlots: timeSlots }) 
				}
			)
		} else this.commandPipeService.next(CommandStepPickUpHour.id, true)
	}

	public undo() {
		this.commandPipeService.pickUpTime = null
		//optimisation pour éviter de faire des appels API après validation commande
		if(this.commandPipeService.isPurchasing) {
			this.quoteService.removePickUpDate()
		}
	}
}