import { Injectable } from "@angular/core";

export interface Reader {
    incomingData(buffer: ArrayBuffer)
}

@Injectable({
  providedIn: 'root'
})
export class SerialUsbService {

  public start(reader: Reader, errorCallback?: (error) => void, serialOptions?: SerialOptions) {
    if ((window as any).serial) serial.requestPermission(
        () => {
            serial.open(
                serialOptions,
                () => {
                    serial.registerReadCallback(
                        (data) => { reader.incomingData(data) },
                        () => { errorCallback && errorCallback('serial can\'t register callback') }
                    )
                },
                () => { errorCallback && errorCallback('serial open fail') }
            )
        },
        () => { errorCallback && errorCallback('serial error'); }
    )
    else console.warn("serial not available")
  }

  public stop() {
      if ((window as any).serial) serial.close(
          () => {},
          () => {}
      )
      else console.warn("serial not available")
  }
}
