import { Quote } from "./quote";
import { Store } from "./store";

export class Order extends Quote {
    public order_number: string;
    public store : Store;
    public placeId: number;
    public statusId: number;
    public isTakeaway: boolean; 

    public get isTerminated(): boolean { return this.statusId >= 11 }

    public setData(data: any) {
        super.setData(data);
        this.convertData(data);
        if (data.placeId) this.placeId = data.placeId;
        if (data.statusId) this.statusId = data.statusId;
        this.isTakeaway = data.takeaway ? data.takeaway : false;
        this.order_number = typeof data.order_number !== "undefined" ? data.order_number : '';
        this.store = typeof data.store !== "undefined" ? new Store(data.store) : null;
    }

    private convertData(data) {
        if (data.place && data.place.id) data.placeId = data.place.id;
        if (data.status && data.status.id) data.statusId = data.status.id;
    }
}
