import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';

import { StorageService } from './storage.service';
import { Constants } from '../config/constants';

import { Quote } from '../models/quote';
import { User } from '../models/user';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  	currentPanier: Quote = null;
    currentUser: User = null;
    idPanier: number = -1;

    constructor(
    	public http: Http
    ) {}

    public viderPanierLocal() {
        this.currentPanier = new Quote(null);
        this.idPanier = -1;
        StorageService.delete(Constants.STORAGE_KEY_IDPANIER);
        StorageService.delete(Constants.STORAGE_MY_CARDS);
        StorageService.delete(Constants.STORAGE_KEY_CURRENTPANIER);
        StorageService.delete(Constants.STORAGE_KEY_UPSELLING);
    }
}
