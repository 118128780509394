/**
 * Equivalent de la classe Article
 *  avec seulement les propriétés nécessaires à l'envois
 */
export class ArticleForSend {
    id?: number;
    qty: number;
    children : ArticleForSend[] = null;
    options : OptionForSend[] = null;
    category : number = null;
    deferred_takeaway : boolean = false;
    price?: number;

    constructor(data) {
        this.id = typeof data.id !== "undefined" ? data.id : undefined;
        this.qty = typeof data.qty !== "undefined" ? data.qty : 1;
        this.category = typeof data.categoryId !== "undefined" ? data.categoryId : null;
        this.deferred_takeaway = typeof data.deferred_takeaway_result !== "undefined" ? data.deferred_takeaway_result : false;
        if(typeof data.children !== "undefined" && data.children !==null ) {
            this.children = [];
          for(var i = 0; i < data.children.length; i++) {
            var articleForSend = new ArticleForSend(data.children[i]);
            this.children.push(articleForSend);
          }
        }
        if(typeof data.options !== "undefined" && data.options !==null ) {
            let that = this;
            this.options = [];

            // Récupère les OptionsItem a envoyé dans les Options de l'article
            //  seul ses OptionsItem sélectionné sont ajouté a l'envois
            data.options.map(function(option) {
                for(let item of option.items) {
                    if (item.isChecked) that.options.push(new OptionForSend(item));
                }
            })
        }
        this.price = typeof data.price !== "undefined" ? data.price : undefined;
    }
}

export class OptionForSend {
    id?: number;
    qty: number;
    constructor(data) {
        this.id = typeof data.id !== "undefined" ? data.id : undefined;
        this.qty = typeof data.qty !== "undefined" ? data.qty : 1;
    }
}
