import { Component, Input, Injectable, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NavController } from "@ionic/angular";

import { MopComponent } from "../mop-component";

import { MethodOfPayment } from "../../../../../models/method-of-payment";

import { AlertService } from "../../../../../services/alert.service";
import { QuoteService } from "../../../../../services/api/quote.service";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

@Component({
	selector: 'etp-mop',
	templateUrl: './etp-mop.component.html',
	styleUrls: ['./etp-mop.component.scss'],
})
export class EtpMopComponent implements OnInit, MopComponent {
	@Input('valuePrefix') public valuePrefix?: string
	public title: string
	public mop: MethodOfPayment
	public get firstValue(): string { 
		let valuePrefix; return (valuePrefix = this.valuePrefix) ? valuePrefix + 'etp' : 'etp' 
	}

	constructor(
		private navCtrl: NavController, 
		private quoteService: QuoteService, 
		private translateService: TranslateService, 
		private alertService: AlertService,
		public router: Router,
		private route: ActivatedRoute
	) {}

	/* MopComponent */
	public processValue(value: string): boolean {
		let valuePrefix = this.valuePrefix
		if (valuePrefix && !value.startsWith(valuePrefix)) return false

		const infoTime = this.quoteService.getFullDate();

		this.quoteService.setMethodOfPayment(this.mop, infoTime).subscribe(
			data =>{
				// this.navCtrl.push(ETPPage)
			   this.router.navigate(['tabs/etp']);
			}, 
			error => {
				this.alertService.show({
					cssClass:'alert-margintop11',
					header: this.translateService.instant('alert.title.error'),
					message: this.translateService.instant("etp-mop.payment-error-alert.message"),
					buttons: [{
						text: this.translateService.instant('ok'),
						cssClass: 'btnvalider'
					}]
				});
			}
		)
		return true;
	}

	public init() {}

	ngOnInit() {}

}
