import { Component } from '@angular/core';
import { 
	Platform, 
	NavController, 
	AlertController, 
	ModalController
} from '@ionic/angular';

import { Plugins, Network } from '@capacitor/core';
import { Router, ActivationEnd, NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AppConfig } from 'src/app/config/app-config';
import { Constants } from './config/constants';

import { CustomNavResponse } from './models/navigation';

import { ConfigurationService } from './services/configuration.service';
import { TranslateConfigService } from './services/translate-config.service';
import { UserService } from './services/api/user.service';
import { AuthenticationService } from './services/authentication.service';
import { GlobalService } from './services/global.service';
import { EventsService } from './services/events.service';
import { TranslationsService } from './services/api/translations.service';
import { StoreService } from './services/api/store.service';
import { IdleScreenService } from './services/api/idle-screen.service';
import { QuoteService } from './services/api/quote.service';
import { NavigationService } from './services/api/navigation.service';
import { ImageService } from './services/api/image.service';
import { PagesIndexService, Page } from './services/pages-index.service';
import { PlaceService } from './services/api/place.service';
import { IdleDetectorService } from './services/idle-detector.service';
import { AlertService } from './services/alert.service';
import { ArticleService } from './services/api/article.service';
import { CategoryService } from './services/api/category.service';
import { DirectoryService } from './services/directory.service';
import { MethodOfPaymentService } from './services/api/method-of-payment.service';
import { UtilsService } from './services/utils.service';
import { TakeBackComponentService } from './services/take-back-component.service';

import { CommandPipeService } from './command-pipe/command-pipe-service';
import { CommandStepMop } from './command-pipe/command-step-mop';
import { CommandStepTakeAway } from './command-pipe/command-step-take-away';
import { CommandStepPickUp } from './command-pipe/command-step-pick-up';
import { CommandStepPickUpPlace } from './command-pipe/command-step-pick-up-place';
import { CommandStepPickUpHour } from './command-pipe/command-step-pick-up-hour';
import { CommandStepQuoteReview } from './command-pipe/command-step-quote-review';
import { CommandStepReservation } from './command-pipe/command-step-reservation';
import { CommandStepDeliveryAddress } from './command-pipe/command-step-delivery-address';
import { CommandStepTakeBack } from './command-pipe/command-step-take-back';
import { CommandStepNickname } from './command-pipe/command-step-nickname';
import { CommandPipeBuilder } from './command-pipe/command-pipe-builder';

import { BeesketArticleReader } from './3rdlib/beesket/beesket-article-reader';
import { Action } from './std/action';

import { IdlePage } from './pages/idle/idle.page';
import { LoadNavigationService } from './services/load-navigation.service';
import { LanguageService } from './services/api/language.service';


const { App, SplashScreen } = Plugins;

let lastTimeBackPress = 0;
const timePeriodToExit = 2000;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	locale:string = '';
	version:string = AppConfig.CONF.APP_VERSION;
	tabPages:any = [];
	currentUser;
	mode = AppConfig.CONF.MODE;
	pages: Page;
	articlePageLayout = `article-page-layout-${Constants.ARTICLES_PAGE_LAYOUT}`;

	slideOpts = {
		initialSlide: 1,
		speed: 3000,
		autoplay:true,
		loop:true,
		slidesPerView:1
	};

	public idleList: any = []
	public showSlides: boolean = true;
	private waitingScreen: any;

	private _quoteQuantity: number;
	selectedNavigationItem: any;
	
	constructor(
		private platform: Platform,
		public router: Router,
		public navCtrl: NavController,
		private modalCtrl: ModalController,
		private alertController: AlertController,
		public configuration: ConfigurationService,
		private translateConfigService: TranslateConfigService,
		public userService: UserService,
		private authenticationService:AuthenticationService,
		private global: GlobalService,
		private eventsService: EventsService,
		private translationsService:TranslationsService,
		private translateService: TranslateService,
		private storeService: StoreService,
		private idleScreenService: IdleScreenService,
		private idleDetectorService: IdleDetectorService,
		private quoteService: QuoteService,
		private alertService: AlertService,
		public navigationService: NavigationService,
		public imageService: ImageService,
		private pagesIndexService: PagesIndexService,
		private articleService: ArticleService,
		private categoryService: CategoryService,
		private directoryService: DirectoryService,
		private commandPipeService: CommandPipeService,
		private methodOfPaymentService: MethodOfPaymentService,
		private placeService: PlaceService,
		private utilsService: UtilsService,
		private takeBackComponentService: TakeBackComponentService,
		private loadNavigationService: LoadNavigationService,
		private languageService: LanguageService
	) {
		console.log('Application start');

		this.eventsService.getLocaleObservable().subscribe(lang => {
			console.log('app component: ', lang);
			this.locale = lang;
			this.locale = this.translateConfigService.getDefaultLanguage();
		});	

		this.eventsService.getUserObservable().subscribe(user => {
			this.currentUser = this.global.currentUser;
		});	

		this.currentUser = this.global.currentUser;

		this.locale = this.translateConfigService.getDefaultLanguage();

		// note: La home est obligatoirement la première page et est commune a tous les types de device (mobile, terminal...)
		// Si aucune page n'est fournis au lancement de l'appli, le tabcontroller n'apparait pas
		this.pages = {
			id: 'HOME', 
			title: 'tab.home', 
			url: '/tabs/home', 
			icon: 'home-sharp', 
			index: 0, 
			badge: () => 0, 
			'tab': 'home'
		}
		this.pagesIndexService.addPages(this.pages);

		const onInitialize = () => {
			console.log('App', 'Initialize');
			
			this.getRemoteInfo();

			// force la rénitialisation du mot de passe sur le panier,
			// sinon l'écran de mot de passe ne sera pas présenté
			// note: ce cas de figure n'arrive que lorsque l'application est tuée
			this.quoteService.removeNickname();

			// Permet d'empecher la double selection d'un onglet
			// Tabs conf
			let i = 1;

			// this.pages = {
			// 	id: 'SCANCODE', 
			// 	title: 'tab.code-scan-test', 
			// 	url: '/tabs/code-scan-test', 
			// 	icon: 'scan-sharp', 
			// 	index: i++, 
			// 	badge: () => 0, 
			// 	'tab': 'code-scan-test'
			// };
			// this.pagesIndexService.addPages(this.pages);
					
					
			// note: sélectionner 2 fois le meme onglet dans le cas de la liste de magasin est problématique
			// const isNotSelected = (id: string) => false // permet de voir le dit problème
			// const isSelected = (id: string) => this.tabs.getSelected() && this.tabs.getSelected().index == pagesIndexService.getPageIndex(id)

			//si on est sur la borne magasin
			const device = this.configuration.platformConf.device
			const menuCode = this.configuration.platformConf.mode.menuMode
			document.getElementsByTagName('ion-app')[0].classList.add(`device-type-${device}`);

			if (device == 'terminal') {
				//    this.pages = {
				//     id: 'HOME', 
				//     title: 'tab.home', 
				//     url: '/tabs/home', 
				//     icon: 'home-sharp', 
				//     index: 0, 
				//     badge: () => 0, 
				//     'tab': 'home'
				// };
				// this.pagesIndexService.addPages(this.pages);
				// TODO: sortir le hidden dans une conf
				this.pages = {
					id: 'SHOP', 
					title: 'tab.shop', 
					url: '/tabs/directory', 
					icon: 'restaurant-sharp', 
					index: i++, 
					badge: () => 0, 
					'tab': 'directory',
					hidden: true
				};
				this.pagesIndexService.addPages(this.pages);

				this.pages = {
					id: 'CART', 
					title: 'tab.cart', 
					url: '/tabs/quote', 
					icon: 'bag-handle-sharp', 
					index: i++, 
					badge: () => this.quoteQuantity, 
					'tab': 'quote'
				};
				this.pagesIndexService.addPages(this.pages);
				//sinon on est sur un une tablette ou un panel pc ( beesket )
			} else if (device == 'terminal_2') {

				if (menuCode == "scan") {
					this.pages = {
						id: 'SCAN', 
						title: 'tab.scan', 
						url: '/tabs/scan', 
						icon: 'scan-sharp', 
						index: i++, 
						badge: () => 0, 
						'tab': 'scan'
					};
					this.pagesIndexService.addPages(this.pages);
					
				} else if (menuCode == "scan+menu") {
					this.pages = {
						id: 'SCAN', 
						title: 'tab.scan', 
						url: '/tabs/scan', 
						icon: 'scan-sharp', 
						index: i++, 
						badge: () => 0, 
						'tab': 'scan'
					};
					this.pagesIndexService.addPages(this.pages);
					this.pages = {
						id: 'SHOP', 
						title: 'tab.shop', 
						url: '/tabs/directory', 
						icon: 'restaurant-sharp', 
						index: i++, 
						badge: () => 0, 
						'tab': 'directory',
						hidden: true
					};
					this.pagesIndexService.addPages(this.pages);
				} else {
					this.pages = {
						id: 'SHOP', 
						title: 'tab.shop', 
						url: '/tabs/directory', 
						icon: 'restaurant-sharp', 
						index: i++, 
						badge: () => 0, 
						'tab': 'directory',
						hidden: true
					};
					this.pagesIndexService.addPages(this.pages);
				}
				this.pages = {
					id: 'CART', 
					title: 'tab.cart', 
					url: '/tabs/quote', 
					icon: 'bag-handle-sharp', 
					index: i++, 
					badge: () => this.quoteQuantity, 
					'tab': 'quote'
				};
				this.pagesIndexService.addPages(this.pages);
				//sinon on est sur un smartphone ou une tablette 
			} else {
				this.pages = {
					id: 'STORES', 
					title: 'tab.stores', 
					url: '/tabs/stores-list', 
					icon: 'locate-sharp', 
					index: i++, 
					badge: () => 0, 
					'tab': 'stores-list'
				};
				this.pagesIndexService.addPages(this.pages);

				if (menuCode == "scan") {
					this.pages = {
						id: 'SCAN', 
						title: 'tab.scan', 
						url: '/tabs/scan', 
						icon: 'scan-sharp', 
						index: i++, 
						badge: () => 0, 
						'tab': 'scan'
					};
					this.pagesIndexService.addPages(this.pages);
				} else if (menuCode == "scan+menu") {
					this.pages = {
						id: 'SCAN', 
						title: 'tab.scan', 
						url: '/tabs/scan', 
						icon: 'scan-sharp', 
						index: i++, 
						badge: () => 0, 
						'tab': 'scan'
					};
					this.pagesIndexService.addPages(this.pages);
					this.pages = {
						id: 'SHOP', 
						title: 'tab.shop', 
						url: '/tabs/directory', 
						icon: 'restaurant-sharp', 
						index: i++, 
						badge: () => 0, 
						'tab': 'directory',
						hidden: true
					};
					this.pagesIndexService.addPages(this.pages);
				} else {
					this.pages = {
						id: 'SHOP', 
						title: 'tab.shop', 
						url: '/tabs/directory', 
						icon: 'restaurant-sharp', 
						index: i++, 
						badge: () => 0, 
						'tab': 'directory',
						hidden: true
					};
					this.pagesIndexService.addPages(this.pages);
				}

				this.pages = {
					id: 'CART', 
					title: 'tab.cart', 
					url: '/tabs/quote', 
					icon: 'bag-handle-sharp', 
					index: i++, 
					badge: () => this.quoteQuantity, 
					'tab': 'quote'
				};
				this.pagesIndexService.addPages(this.pages);
				this.pages = {
					id: 'PROFILE', 
					title: 'tab.profile', 
					url: '/tabs/profile', 
					icon: 'person-circle-sharp', 
					index: i++, 
					badge: () => 0, 
					'tab': 'profile'
				};
				this.pagesIndexService.addPages(this.pages);

			}

			this.tabPages = this.pagesIndexService.getPages();

			// Command pipe conf
			// cv. validation-order
			this.commandPipeService.allowTakeBackLater = false
			//let authentification = new CommandStepAuthentification(app, commandPipeService, pagesIndexService, userService)
			let beginAction = {
				do: () => {
					this.commandPipeService.isPurchasing = true
					this.commandPipeService.next('beginAction', true)
				}
			}
			let payment = new CommandStepMop(
				this.router, 
				this.commandPipeService, 
				this.methodOfPaymentService, 
				this.translateService
			);
			let takeaway = new CommandStepTakeAway(
				this.router, 
				this.commandPipeService, 
				this.quoteService
			);
			let quoteReview = new CommandStepQuoteReview(
				this.router, 
				this.commandPipeService
			);
			let deliveryAdress = new CommandStepDeliveryAddress(
				this.router, 
				this.placeService, 
				this.commandPipeService, 
				this.quoteService
			);
			let pickUp = new CommandStepPickUp(
				this.router, 
				this.commandPipeService, this.placeService
			);
			let pickUpPlace = new CommandStepPickUpPlace(
				this.router,  
				this.commandPipeService, 
				this.placeService, 
				this.userService, 
				this.quoteService, 
				this.utilsService, 
				this.translateService,
				this.alertService
			);
			let pickUpHour = new CommandStepPickUpHour(
				this.router, 
				this.commandPipeService, 
				this.placeService, 
				this.quoteService
			);
			let takeback = new CommandStepTakeBack(
				this.router, 
				this.commandPipeService, 
				this.translateService, 
				this.takeBackComponentService, 
				this.alertService
			);
			let reservation = new CommandStepReservation(
				this.router,  
				this.commandPipeService, 
				this.placeService, 
				this.quoteService, 
				this.userService
			);
			let nickname = new CommandStepNickname(
				this.router,   
				this.commandPipeService, 
				this.quoteService, 
				this.userService, 
				this.configuration.platformConf
			);
			// let print = new CommandPrinterPrint(this.commandPipeService, this.printerService);
			// let printOrderAction = new CommandStepPrintOrder(
			// 	this.commandPipeService, 
			// 	print, 
			// 	printerPrint, 
			// 	this.storeService
			// );
			let endAction = {
				do: () => {
					this.commandPipeService.isPurchasing = false
					let message = "command-pipe.end-alert.default-message"
					switch (commandPipeService.placeType.code) {
						case 0: // sur place
							if (commandPipeService.quote.method_of_payment.type.id == 1) {
								message = "command-pipe.end-alert.eat-in-message-cash"
							} else {
								message = "command-pipe.end-alert.eat-in-message"
							}
							break;
						case 1: // Retrait express
							message = "command-pipe.end-alert.express-message"
							break;
						case 3: // Livraison
							message = "command-pipe.end-alert.shipping-message"
							break;
						case 4: // Réservation
							message = "command-pipe.end-alert.reservation-message"
							break;
					}

					this.alertService.show({
						cssClass: 'alert-paiement alert-margintop11',
						header: translateService.instant("command-pipe.end-alert.title"),
						message: translateService.instant(message),
						icon: 'test',
						buttons: [{
							cssClass: 'btnvalider',
							text: translateService.instant("ok"),
							handler: () => { commandPipeService.next('endAction', true) }
						}]
					});
  
				}
			}
			// comment by sajid because of cordova-plugin-posprinter/www packages
			// let printAction = {
			//     do: () => {
			//         const printerConf = this.configuration.printerConf
			//         if (printerPrint.printerConf = printerConf) {
			//             print.printStrategy = new MultiplePrint([postPrint, printerPrint], true)
			//         } else {
			//             print.printStrategy = postPrint
			//         }

			//         print.clearDataOnSucess = true
			//         print.id = 'PrintAction'
			//         print.do()
			//     }
			// }
			// let filePrintAction = {
			//     do: () => {
			//         const printerConf = this.configuration.printerConf
			//         if ((filePrint.printerConf = printerConf) && printerConf.logFilename) {
			//             print.printStrategy = filePrint
			//             print.id = 'FilePrintAction'
			//             print.clearDataOnSucess = true
			//             print.do()
			//         } else commandPipeService.next('FilePrintAction', true)
			//     }
			// }
			// let alertPrintAction = {
			//     do: () => {
			//         if (commandPipeService.printerData) {
			//             let message
			//             const printerConf = this.configuration.printerConf
			//             if (!printerConf) {
			//                 message = translateService.instant("command-pipe.print-alert.message.save-into-file-fail")
			//             } else if (printerConf.logFilename) {
			//                 message = translateService.instant("command-pipe.print-alert.message.print-fail")
			//             } else {
			//                 message = translateService.instant("command-pipe.print-alert.message.print-fail")
			//             }

			//             this.alertService.show({
			//                 cssClass: 'alert-margintop11',
			//                 header: translateService.instant("alert.title.error"),
			//                 message: translateService.instant(message),
			//                 buttons: [{
			//                     cssClass: 'btnvalider',
			//                     text: translateService.instant("ok")
			//                 }]
			//             })
			//             .then(_ => { commandPipeService.next('AlertPrintAction', true) })
			//             .catch((_ => {
			//             	commandPipeService.next('AlertPrintAction', true)
			//             }))
			//             this.commandPipeService.printerData = null
			//         } else commandPipeService.next('AlertPrintAction', true)
			//     }
			// }
			const flushCartAction = {
				do: () => {
					this.global.viderPanierLocal();
					commandPipeService.next('FlushCartAction', true)
				}
			}

			let commandPipeBuilder = new CommandPipeBuilder(commandPipeService)
			const addAllSteps = () => {
				let steps: { [id: string]: Action } = {}
				steps['beginAction'] = beginAction
				//steps[CommandStepAuthentification.id] = authentification
				if (Constants.NICKNAME_PAGE_ORDER == 'before-code'){
					steps[CommandStepNickname.id] = nickname
				} 
				steps[CommandStepPickUp.id] = pickUp
				steps[CommandStepDeliveryAddress.id] = deliveryAdress
				steps[CommandStepPickUpPlace.id] = pickUpPlace
				steps[CommandStepTakeBack.id] = takeback
				steps[CommandStepQuoteReview.id] = quoteReview
				steps[CommandStepPickUpHour.id] = pickUpHour
				steps[CommandStepReservation.id] = reservation
				steps[CommandStepTakeAway.id] = takeaway
				if (Constants.NICKNAME_PAGE_ORDER == 'after-code'){
					steps[CommandStepNickname.id] = nickname
				} 
				steps[CommandStepMop.id] = payment
				// comment by sajid because of cordova-plugin-posprinter/www packages
				// steps['PrintAction'] = printAction
				// steps['FilePrintAction'] = filePrintAction
				// note: cette etape est forcément après les étapes Print/FilePrint pour ne pas écraser commandPipeService.printData
				// comment by sajid because of cordova-plugin-posprinter/www packages
				// steps[CommandStepPrintOrder.id] = printOrderAction
				// steps['AlertPrintAction'] = alertPrintAction
				steps['FlushCartAction'] = flushCartAction
				if (Constants.NICKNAME_PAGE_ORDER == 'after-order'){
					steps[CommandStepNickname.id] = nickname
				} 
				steps['endAction'] = endAction

				commandPipeBuilder.setSteps(steps)
				commandPipeService.exitAction = {
					do: () => {
						// comment by sajid and replace by router
						// let navCtrl = app.getActiveNav()
						// navCtrl.popToRoot();
						// this.tabs.select(this.pagesIndexService.getPageIndex('home'));
						this.router.navigate(['tabs/home']);
						let idleDetector = this.idleDetectorService;
						this.showSlides = true;
						idleScreenService.getScreens().subscribe(
							(data) => {
								if(this.idleList.length > 0) this.idleList = []
								for(let idleScreen of data){
									// comment by sajid
									/*const modal = await this.modalCtrl.create({
										component: IdlePage,
										componentProps: {
											'html': idleScreen.html
										}
									});
									await modal.present();
									modal.onDidDismiss().then(data => {
										idleDetector && idleDetector.start()
									});
									this.idleList.push(modal)*/
									this.idleList.push(idleScreen);
								}
								idleDetector && idleDetector.start()
							},
							(error) => idleDetector && idleDetector.start()
						)
					}
				}
			}

			// comment by sajid because of cordova-plugin-posprinter/www packages
			/*
			const runPrintTest = (commandPipeId: string) => {
				const printerConf = printerPrint.printerConf = new PrinterConf
				if (printerPrint.printerConf = printerConf) {
					print.printStrategy = new MultiplePrint([postPrint, printerPrint], true)
				} else {
					print.printStrategy = postPrint
				}
				//print.printStrategy = printerPrint

				commandPipeService.printerData =
					`NEPTING
					HEADER 1999 10 0
					A0000000421010
					CB
					Le 08/08/2019 a 17:30:13
					Nepting
					1999188 340001999100 34000
					############2992
					F9A2380BC7DAD482
					001 001 000022 C @
					NUM AUTO : 6c5b4
					MONTANT :
					8,00 EUR
					Pour information :
					52,48 FRF
					DEBIT
					TICKET CLIENT
					A CONSERVER
					MERCI AU REVOIR
					1651`
				print.clearDataOnSucess = true
				print.id = commandPipeId
				print.do()
			}

			const addTestStep = (i: number = 0) => {
				commandPipeBuilder.addStep(`command-pipe-test-${i}`, {
					do: () => {
						const alert = this.alertService.show({
							message: "Try number: " + i,
							buttons: [{
								text: "ok",
								handler: () => {
									addTestStep(i + 1)
									runPrintTest(`command-pipe-test-${i}`)
								}
							}, {
								text: "cancel",
								handler: () => {
									addAllSteps()
									commandPipeService.next(`command-pipe-test-${i}`, true)
								}
							}]
						});
					}
				})
			}

			const addSteps = (debug: boolean) => debug ? addTestStep() : addAllSteps()
			*/
			const addSteps = (debug: boolean) => addAllSteps()

			addSteps(false)
		}

		this.initializeApp(onInitialize)
	}

	private initializeApp(callback: () => void) {
		this.platform.ready().then(() => {

			if(this.platform.is('ios')){
				this.configuration.isDisplaySideMenu = false;
			}
			
			console.log('App', 'platform.ready');

			Constants.locale = getLocale = () => this.translateConfigService.locale;

			// Hide Spalash screen 
			setTimeout(()=>{
				SplashScreen.hide({fadeOutDuration: 500});
				console.log('Splash screen hide');
			},1000);

			this.getLaunchUrl();

			// Check Network status
			this.checkNetworkStatus();

			// Lecture du token anonyme et de la conf avant tout appel api
			const CONF_READ_FLAG = 1;
			const TOKEN_READ_FLAG = 2;
			let confReadState = 0;

			const initIdleIdleDetector = () => {

				if (confReadState != (CONF_READ_FLAG | TOKEN_READ_FLAG)) return;

				const platformConf = this.configuration.platformConf;
				const idleTimeOut = platformConf.idleTimeout;
				const idleExtratime = platformConf.idleExtratime;

				const showIdlePage = (idleDetector?: IdleDetectorService) => {
					const idleScreenService = this.idleScreenService

					// cet appel permet d'initialiser le storeId dans l'injecteur d'entete http api/api-header-service
					this.storeService.getCurrentStore();

					this.showSlides = true
					idleScreenService.getScreens().subscribe(
						(data) => {
							if(this.idleList.length > 0) this.idleList = []
							for(let idleScreen of data){
								// comment by sajid
								// this.idleModal(idleScreen.html, idleDetector);
								// let modal = this.modalCtrl.create(IdlePage, { html: idleScreen.html })
								// modal.onDidDismiss(() => { idleDetector && idleDetector.start() })
								this.idleList.push(idleScreen);
							}
						},
						(error) => idleDetector && idleDetector.start()
					)
				}

				if (idleTimeOut) {
					const idleDetectorService = this.idleDetectorService;

					idleDetectorService.delay = idleTimeOut
					idleDetectorService.action = {
						do: () => {
							let that = this
							idleDetectorService.stop()

							function returnBackHome(): any {
								this.router.navigate(['tabs/home']);
								// comment by sajid
								// that.gotoPage(that.pagesIndexService.getPage('home'))
								that.commandPipeService.reset()
								that.quoteService.clearQuote()
								this.userService.logout().subscribe(null, null, () => {
									this.router.navigate(['tabs/home']);
									this.eventsService.updateUser();
								});
								// that.etpService.cancel()
								that.alertService.dismissAll()
								showIdlePage(idleDetectorService)
							}

							let currentQuote = that.quoteService.quote
							if (!idleExtratime || ((!currentQuote || !currentQuote.items || currentQuote.items.length == 0) && !that.userService.isLoggedIn())) { returnBackHome() }
							else {
								const alert = this.alertService.show({
									message: this.translateService.instant("command-pipe.are-you-still-here-alert.message"),
									buttons: [{
										text: this.translateService.instant("yes"),
										handler: () => idleDetectorService.restart()
									}]
								})

								// const timeout = setTimeout(
								//     () => {
								//         alert.dismiss().then(() => {
								//             returnBackHome()
								//         })
								//     },
								//     idleExtratime
								// )

								// alert.present()
								// alert.onDidDismiss(() => clearTimeout(timeout))
							}
						}
					}

					showIdlePage(idleDetectorService);
				} else {
					showIdlePage();
				}

				// comment by sajid
				// if (this.configuration.neptingConf) this.etpService.login()
				callback()
			}

			const fileReadCallback = (key: string, flag: number, success: boolean) => {
				console.log('App', `${key} - ${success ? 'success' : 'fail'}`);
				confReadState |= flag
				if (flag == CONF_READ_FLAG && this.configuration.platformConf.translationAsKey){
					this.translationsService.reloadTranslations([this.translateConfigService.locale])
				} 
				initIdleIdleDetector();
			}

			this.configuration.read().subscribe(
				data => fileReadCallback('confRead', CONF_READ_FLAG, true),
				error => fileReadCallback('confRead', CONF_READ_FLAG, false),
			);
				
			// Comment by Sajid
			this.authenticationService.readTokenFromConf().subscribe(
				data => fileReadCallback('tokenRead', TOKEN_READ_FLAG, true),
				error => fileReadCallback('tokenRead', TOKEN_READ_FLAG, false),
			);
			

		});

		this.handleBackButton();
	}

	async getLaunchUrl() {
		const urlOpen = await Plugins.App.getLaunchUrl();
		if(!urlOpen || !urlOpen.url) return;
		console.log('Launch URL', urlOpen);
	}

	ionViewDidEnter() {
	}

	protected get quoteQuantity() {
		let quoteQuantity = this.quoteService.getQuoteQuantity();
		if (this._quoteQuantity && this._quoteQuantity != quoteQuantity) {
			let badges = document.querySelectorAll('ion-badge');
			for (let i = 0; i < badges.length; i++) {
				let badge = badges.item(i);
				badge.classList.add('highlight');
				badge.addEventListener('animationend', () => { badge.classList.remove('highlight') });
				let htmlElementBro = badge.nextSibling;
				let parent = badge.parentElement;
				badge.remove();
				parent.insertBefore(badge, htmlElementBro);
			}
		}

		return this._quoteQuantity = quoteQuantity;
	}
	
	/**
	 * Récupère les infos nécessaire au bon fonctionnement de l'appli
	 * Prévoir les possibilité de non retour ou retour mal formé.
	 */					
	private getRemoteInfo() {
		this.imageService.load();

		this.storeService.getCurrentStore() // cet appel permet d'initialiser le storeId dans l'injecteur d'entete http api/api-header-service
		this.getNavigation();
		this.getLanguages();
		// comment by sajid
		// if (document.URL.indexOf("?str=") > 0) {
		//     let splitURL = document.URL.split("?str=");
		//     let param = splitURL[1].split('_');

		//     this.storeService.setStoreFromExternLink(param[0]).subscribe(
		//         (data) => {
		//             let articles = []

		//             if (isNaN(parseInt(param[1]))) {
		//                 this.loadNavigationService.getNavigation(decodeURI(param[1])).subscribe(
		//                     (_navigation) => {
		//                         console.log(_navigation)
		//                         this.router.navigate(['tabs/directory']);	
		//                         // this.tabs.select(this.pagesIndexService.getPageIndex('shop'));
		//                         console.log('nav', that.navigationService.navigation)

		//                     }
		//                 );
		//             } else {
		//                 articles.push(param[1])
		//                 let params = ["extraInfo"];
		//                 this.articleService.getArticleFromCodes(articles, params).subscribe(
		//                     (remoteArticles: Article[]) => {
		//                         for (let remoteArticle of remoteArticles) {
		//                             console.log(remoteArticle)
		//                             let price_layers_groups = []
		//                             if (remoteArticle.price_layers && remoteArticle.price_layers.length > 0) {
		//                                 price_layers_groups = this.priceLayerService.groupFilterOrderPriceLayers(remoteArticle.price_layers, this.storeService.getCurrentStore())

		//                                 if (price_layers_groups.length > 0) {
		//                                     remoteArticle.price_layers_groups = price_layers_groups
		//                                 }
		//                             }
		//                             this.modalCtrl.create(InfoPage, { article: remoteArticle }).present()
		//                         }
		//                     }
		//                 );
		//             }
		//         }

		//     );
		// }
		!this.configuration.platformConf.translationAsKey && this.translationsService.getTranslations().subscribe(data => { }, error => { })
	}

	private alertError() {
		let alertOptions;
		if (this.platform.is('android')) {
			alertOptions = {
				text: this.translateService.instant("quit"),
				cssClass: 'btnvalider',
				handler: function () {
					// comment by sajid
					// this.platform.exitApp();
				}
			}
		}
		this.alertService.show({
			header: this.translateService.instant("app.initialize-catalog-error.message"),
			message: this.translateService.instant("app.initialize-catalog-error.message"),
			cssClass: 'alert-margintop10',
			buttons: [alertOptions]
		});
	}

	private getUser() { 
		// that.userService.getUser().subscribe(() => { }, () => { /* Erreur silencieuse */ }, null) 
	}
	private getQuote() { 
		this.quoteService.getListArticleInQuote().subscribe(
			() => {}, 
			() => { /* Erreur silencieuse */ }
		) 
	}
	private getCategories() { 
		this.categoryService.getCategories().subscribe(
			(_category) => { }, 
			() => { /* Erreur silencieuse */ }, 
			() => {
				this.getQuote()
			}) 
	}
	private getLanguages() {
		// comment by sajid
		// that.languageService.getLanguages() 
	}

	private getNavigation() {
		this.loadNavigationService.getNavigation(0, this.storeService).then((_navigation) => {
			// BeesketArticleReader est initialisé ici pour le moment
			// car c'est le seul a utiliser la lecture rfid
			// TODO: faire évoluer l'instance en fonction des divers clients
			this.directoryService.reader = new BeesketArticleReader(
				this.articleService,
				this.categoryService,
				this.quoteService,
				err => console.log(err)
			)
			this.directoryService.navigation = _navigation
			this.getUser();
		})
		.catch((error) => {
			this.alertError() 
		})
		.finally(() => {
			this.getCategories()
		});
	}

	public getRootNavigationItem(navigationItem: CustomNavResponse) {
		return this.navigationService.getRootNavigationItem(navigationItem);
	}

	public navigateTo(navigationItem) {
		this.navigationService.selectedItem = navigationItem;
		this.router.navigate(['tabs/directory']);	
		// this.tabs.select(this.pagesIndexService.getPageIndex('shop'));
	}

	async idleModal(html:string, idleDetector: IdleDetectorService){

		const modal = await this.modalCtrl.create({
			component: IdlePage,
			componentProps: {
				'html': html
			}
		});

		await modal.present();
		modal.onDidDismiss().then(data => {
			idleDetector && idleDetector.start();
			if(data){
				console.log('idle modal close and return: ', data);
			}
		});

	}

	async checkNetworkStatus(){
		let status = await Network.getStatus();
		console.log('Network status - ', JSON.stringify(status));
		if(!status.connected){
			console.log("network.onConnect");
			if (this.waitingScreen){
				// Comment by sajid
				// this.waitingScreen.dismiss();
			}
		}else{
			console.log("network.onDisonnect");
			// Comment by sajid
			// this.waitingScreen = this.createWaitingScreen();
			// this.waitingScreen.present();
		}
	}

	gotoPageMention(){
		this.eventsService.updatePathName('profile');
		this.router.navigate(['tabs/profile/legals']);
	}
	gotoPageCGV(){
		this.eventsService.updatePathName('profile');
		this.router.navigate(['tabs/profile/gcs']);
	}

	gotoPageLogin(){
		this.router.navigate(['tabs/profile']);	
	}

	async didTouchLogout() {
		const alert = await this.alertController.create({
			cssClass: 'alert-deconnect',
			header: this.translateService.instant('profile.logout'),
			message: this.translateService.instant('profile.confirm-logout-alert.message'),
			buttons: [
				{
				  text: this.translateService.instant('cancel'),
				  role: 'cancel',
				  cssClass: 'btncancel',
				  handler: (blah) => {
					console.log('Confirm Cancel: blah');
				  }
				}, {
				  text: this.translateService.instant('logout'),
				  cssClass: 'btnvalider',
				  handler: () => {
					console.log('Confirm Okay');
					this.userService.logout().subscribe(null, null, () => {
						this.router.navigate(['tabs/home']);
						this.eventsService.updateUser();
					});
				  }
				}
			]
		});

		await alert.present();
	}


	public closeSlides() {
		this.showSlides = false
		if(!this.showSlides) this.idleDetectorService && this.idleDetectorService.start()
	}

	// Comment by sajid
	// private createWaitingScreen(): Loading {
 //        return this.loadingCtrl.create({
 //            spinner: this.platform.is('ios') ? 'ios' : 'bubble',
 //            content: this.translateService.instant('waiting-for-network')
 //        });
 //    }

	ngOnInit() {
		const path = window.location.pathname.split('/')[1];
		if (path !== undefined) {
			this.configuration.selectedIndex = this.tabPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
		}
	}

	handleBackButton(){

		// you can subscribe with a low priority instead
		let router = this.router;
		let navCtrl = this.navCtrl;
		let navigationExtras: NavigationExtras = null;
		const _this = this;
		let deviceId = null;
		let deviceName = null;
		router.events.subscribe((event)=>{
			if(event instanceof ActivationEnd) {
				const currentNav = router.getCurrentNavigation().extras.state;
				const params = event.snapshot.params
				const queryParams = event.snapshot.queryParams
				deviceId = params.deviceId;
				deviceName = params.deviceName;
				if(queryParams.deviceName){
				  deviceName = queryParams.deviceName;
				}
				if(currentNav){
					const deviceDetails = currentNav.deviceDetails;
					const ticketDetails = currentNav.ticketDetails;
					const deviceAccessProfileId = currentNav.deviceAccessProfileId;
					const deviceUser = currentNav.deviceUser;
					const user = currentNav.user;
			  
					if(deviceDetails){
						deviceId = currentNav.deviceDetails.deviceId;
						deviceName = currentNav.deviceDetails.deviceName;
					}
					navigationExtras = {
						state: {
							deviceId,
							deviceName,
							deviceAccessProfileId,
							user,
							deviceUser,
							ticketDetails
						}
					};
				}
			}
		});
   
		App.addListener('backButton', function() {
			let url1 = router.url.split('/');
			let url = '';

			if(url1.length > 2){
				url = url1[2];
			}else{
				url = url1[1];
			}
			// alert('url in backbuttun : '+url);
			//Double check to exit app  

			if(url === 'home'){
				if (new Date().getTime() - lastTimeBackPress < timePeriodToExit) {
					console.log('exitApp()');
					App.exitApp(); //Exit from app
				} else {
					_this.modalCtrl.getTop().then(modal => {
					  	if(modal){
						 	_this.modalCtrl.dismiss();
					  	}else{
						 	navCtrl.pop(); 
					  	}
				   }); 
					lastTimeBackPress = new Date().getTime();
				}
			}else{
				_this.modalCtrl.getTop().then(modal => {
				   if(modal){
					  	_this.modalCtrl.dismiss();
				   }else{
					  	router.navigate(['tabs/home']);
				   }
				}); 
			}

		});
	}
 
}
