import { Injectable, Inject }           from '@angular/core';
import { TranslateService }             from '@ngx-translate/core';
import { Observable, ReplaySubject }     from 'rxjs';

import { GlobalService }       from '../global.service';
import { Constants }    from '../../config/constants';

import { ApiService } from './api.service';

import { ApiRequest }       from '../../models/apirequest';
import { Article }          from '../../models/article';
import { Quote }            from '../../models/quote';
import { Store }            from '../../models/store';
import { QuoteItem }        from '../../models/quoteItem';
import { ArticleForSend }   from '../../models/articleForSend';
import { MethodOfPayment }  from '../../models/method-of-payment';
import { PickUpHour }       from '../../models/pick-up-hour';
import { Stock }            from '../../models/stock';

import { ProcessArticleChainAction } from '../../actions/process-article-chain.action';

import { UtilsService }     from '../utils.service';
import { StorageService }   from '../storage.service';
import { StoreService }     from './store.service';
import { AlertService }     from '../alert.service';

/*
  Generated class for the Cart provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI
*/

@Injectable({
	providedIn: 'root'
})
export class QuoteService {

	private get idPanier() { return this.global.idPanier }
    private set idPanier(idPanier: number) { this.global.idPanier = idPanier }

    public get currentQuote() {
        return this.global.currentPanier
            ? this.global.currentPanier
            : this.currentQuote = new Quote(null)
    }
    public set currentQuote(quote: Quote) { this.global.currentPanier = quote }

    public get quote() { return this.currentQuote }

    public currentPanier: any = null
    public currentModificatedItem: Record<string, any> = {}

    public articlesInProcess = []

    constructor(
        private apiService: ApiService,
        public global : GlobalService,
        private alertService : AlertService,
        private storeService : StoreService,
        private translateService : TranslateService,
        private utilsService : UtilsService
    ) {
        apiService.delegate = this

        this.currentQuote = new Quote(null);
        this.idPanier = StorageService.get(Constants.STORAGE_KEY_IDPANIER, false, null, false)
    }

    /**
     * ApiService
     */
    protected shouldDisplaySuccessMessage() { return true }

    /**
     * @return l'objet ApiRequest avec les en-têtes nécessaires
     */
    private newApiRequest(url: string, body: any): ApiRequest<any> {
        var apiRequest = new ApiRequest<any>(url, body)
        if (this.idPanier >= 0 && apiRequest) {
            apiRequest.headers = apiRequest.headers.set(Constants.HEADER_QUOTE, String(this.idPanier))
        }

        return apiRequest
    }

    /**
     * Ajoute un listener a une requete qui réinitialise le panier local sur un succes
     *
     * @param request la requete a écouter
     */
    private subscribe(request: Observable<any>): Observable<any> {
        let that = this;
        let observer = new ReplaySubject<any>()
        request.subscribe(
            (data) => {
                that.synchronizeQuoteId(data.id)
                that.currentQuote.setData(data)
                observer.next(data)
            },
            (error) => { observer.error(error) },
            () => { observer.complete() }
        )

        return observer
    }

    public getArticleStockQty(article: Article, storeId: number) {
        let totalQuantityArticle: number = -1

        if(Array.isArray(article.stocks)) {
            article.stocks.forEach(stock => {
                if(stock.storeId === storeId) {
                    totalQuantityArticle = (stock.outOfStock)
                        ? 0
                        : stock.qty
                }
            })
        } else {
            let stock: Stock = article.stocks[storeId]

            totalQuantityArticle = (stock.outOfStock)
                ? 0
                : stock.qty
        }

        return totalQuantityArticle
    }

    public setListArticleModificated(quoteItem: QuoteItem) {
        this.clearArticleModify()

        this.currentModificatedItem[quoteItem.article.id] = quoteItem.qty

        if(quoteItem.children.length !== 0) {
            quoteItem.children.forEach(child => {
                if(this.currentModificatedItem[child.article.id] !== undefined) {
                    this.currentModificatedItem[child.article.id] += child.qty
                } else {
                    this.currentModificatedItem[child.article.id] = child.qty
                }
            })
        }
    }

    public getArticleQuoteQty(article: Article): Observable<any> {
        let observer = new ReplaySubject<any>()

        this.getListInQuote().subscribe(
            (data) => {
                let qtyArticleInQuote: number = 0

                if(data) {
                    data.items.forEach(item => {
                        if(item.article.id === article.id) {
                            qtyArticleInQuote += item.qty
                        }

                        if(item.children.length !== 0) {
                            item.children.forEach(child => {
                                if(child.article.id === article.id) {
                                    qtyArticleInQuote += child.qty
                                }

                                if(child.children.length !== 0) {
                                    child.children.forEach(child => {
                                        if(child.article.id === article.id) {
                                            qtyArticleInQuote += child.qty
                                        }
                                    })
                                }
                            })
                        }
                    })
                }

                observer.next(qtyArticleInQuote)
            },
            (error) => {
                observer.error(error)
            },
            () => {
                observer.complete()
            }
        )

        return observer
    }

    public clearArticleModify() {
        this.currentModificatedItem = []
    }

    public clearArticlesInProcess() {
        this.articlesInProcess = []
    }

    public unsetAticleInProcess(article: Article) {
        this.articlesInProcess[article.id] = this.articlesInProcess[article.id] - 1
    }

    public getArticlesInProcess(article: Article) {
        return (this.articlesInProcess[article.id] === undefined)
            ? 0
            : this.articlesInProcess[article.id]
    }

    public updateArticleInProcess(article: Article) {
        if(this.articlesInProcess[article.id] === undefined) {
            this.articlesInProcess[article.id] = 1
        } else {
            this.articlesInProcess[article.id]++
        }

        return (this.articlesInProcess[article.id])
                ? this.articlesInProcess[article.id]
                : 0
    }

    public checkStockQuantity(article: Article, withErrorMessage: boolean = true): Observable<any> {
        let observer = new ReplaySubject<any>()

        let storeId     : number = this.storeService.getCurrentStore().id
        let stockQty    : number = null
        let qtyModify   : number = 0
        let quoteQty    : number = null
        let processQty  : number = null
        let remain      : number = null
    
        if(article.stockable) {
            stockQty = this.getArticleStockQty(article, storeId)

            processQty = this.getArticlesInProcess(article)

            if(stockQty - processQty <= 0) {
                if(withErrorMessage) {
                    this.alertService.show({
                        header: this.translateService.instant('alert.title.error'),
                        message:this.translateService.instant('quote.validate.is.out-of-stock'),
                        cssClass : 'alert_qte',
                        buttons: [{
                            text: 'Ok',
                            cssClass: 'btnvalider',
                            handler: () => { }
                        }]
                    });
                }

                observer.next(-1)
            } else {
                this.getArticleQuoteQty(article).subscribe(
                    (data) => {
                        quoteQty = data

                        qtyModify = 0
                        if(this.currentModificatedItem[article.id] !== undefined) {
                            qtyModify = this.currentModificatedItem[article.id]
                        }

                        remain = (stockQty + qtyModify) - (quoteQty + processQty)

                        if(remain <= 0) {
                            if(withErrorMessage) {
                                this.alertService.show({
                                    header: this.translateService.instant('alert.title.error'),
                                    message:this.translateService.instant('quote.validate.is.out-of-stock'),
                                    cssClass : 'alert_qte',
                                    buttons: [{
                                        text: 'Ok',
                                        cssClass: 'btnvalider',
                                        handler: () => { }
                                    }]
                                });
                            }

                            observer.next(-1)
                        } else {
                            observer.next(1)
                        }
                    }
                )
            }
        } else {
            observer.next(1)
        }

        return observer
    }

    public changeQuantity(itemId, quantity): Observable<any> {
        var apiRequest = this.newApiRequest(
            this.utilsService.format(Constants.URL_EDIT_QUOTE_ITEM_QUANTITY, { symbol: '@1', value: itemId}, { symbol: '@2', value: quantity }),
            {}
        );

        return this.subscribe(this.apiService.post(apiRequest));
    }

    public addToQuote(article: Article, quantity: number = 1): Observable<any> {
        // var that = this;
        // var quoteItem = new QuoteItem(null);
        // quoteItem.article = article;
        // quoteItem.qty = 1;
        // if (article.children !== null && article.children.length > 0) {
        //     quoteItem.children = [];
        //     for (var i = 0; i < article.children.length; i++) {
        //         var subQuoteItem = new QuoteItem(null);
        //         subQuoteItem.article = article.children[i];
        //         quoteItem.children.push(subQuoteItem);
        //     }
        // }
        // this.currentQuote.items.push(quoteItem);
        // that.saveCurrentQuote(that.currentQuote);
        var articleForSend = new ArticleForSend(article)
        articleForSend.qty = quantity

        articleForSend.price = article.price

        if(article.prices !== undefined) {
            if(Object.keys(article.prices).length) {
                articleForSend.price = article.prices[this.storeService.getCurrentStore().id].price
            }
        }

        if(article.priceLayerPrice !== null && !Constants.PRICE_FOR_SHOW_ONLY) {
            articleForSend.price = article.priceLayerPrice
        }

        let apiRequest = this.newApiRequest(
            Constants.URL_QUOTE_ITEM,
            articleForSend
        )

        const alert = (data) => {
            let message = this.utilsService.getErrorMessage(data)
            let title   = this.utilsService.getErrorTitle(data)

            if (message) {
                this.alertService.show({
                    header: title ? title : this.translateService.instant('alert.title.error'),
                    message: message,
                    buttons: [{
                        text: 'Ok',
                        cssClass: 'btnvalider'
                    }]
                })
            }
        }

        let observer = new ReplaySubject
        this.subscribe(this.apiService.post(apiRequest)).subscribe(
            data => {
                this.clearArticlesInProcess()
                this.clearArticleModify()

                observer.next(data)
            },
            error => {
                // alert(error)
                observer.error(error)
            }
        )

        return observer
    }

    public removeFromQuote(article): Observable<any> {
        let apiRequest = this.newApiRequest(
            this.utilsService.format(Constants.URL_QUOTE_ITEM_X, { value: article.id, symbol: '@1' }),
            null
        )

        return this.subscribe(this.apiService.delete(apiRequest))
    }

    public updateOptions(article: Article,quoteItem : QuoteItem): Observable<any> {
        let options = new ArticleForSend(article).options
        if(options.length >= 0 && article.bundle == null) {
            let apiRequest = this.newApiRequest(
                this.utilsService.format(Constants.URL_EDIT_QUOTE_ITEM_OPTIONS, { value: quoteItem.id.toString(), symbol: '@1' }),
                { options: options }
            )
            return this.subscribe(this.apiService.post(apiRequest))
        }
        
        if(article.bundle) {
            var articleForSend = new ArticleForSend(article)

            if (articleForSend.options == null) {
                articleForSend.options = []
            }

            var apiRequestBundle = this.newApiRequest(
                this.utilsService.format(Constants.URL_EDIT_QUOTE_ITEM_BUNDLE, { value: quoteItem.id.toString(), symbol: '@1'}),
                articleForSend
            )

            this.clearArticleModify()

            return this.subscribe(this.apiService.post(apiRequestBundle))
        }
    }

    public clearQuote(): Observable<any> {
        let apiRequest = this.newApiRequest(
            Constants.URL_QUOTE,
            {}
        )

        return this.subscribe(this.apiService.delete(apiRequest))
    }

    public getFullDate() {
        return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -1)
    }

    // créer un service transaction dédié a ca
    public validateQuote(): Observable<any> {
        const infoTime = this.getFullDate()

        const params = { infoTime: infoTime }

        let apiRequest = this.newApiRequest(
            Constants.URL_ORDER_VALIDATION,
            params
        )

        return this.apiService.post(apiRequest)
    }

    // créer un service transaction dédié a ca
    public getOrderFromQuote(): Observable<any> {
        const params = { embeded: 'getOrder' }

        let apiRequest = this.newApiRequest(
            Constants.URL_ORDER_VALIDATION,
            params
        )

        return this.apiService.post(apiRequest)
    }

    public setTakeAwayQuote(isTakeAway: boolean): Observable<any> {
        var apiRequest = this.newApiRequest(
            this.utilsService.format(
                Constants.URL_QUOTE_TAKEAWAY, { value: isTakeAway ? '1' : '0', symbol: '@1' }),
            {}
        );

        return this.apiService.post(apiRequest)
    }

    public getQuoteQuantity() {
        var total = 0

        try {
            if (typeof this.currentQuote !== 'undefined' && this.currentQuote !== null) {
                for (var i = 0; i < this.currentQuote.items.length; i++) {
                    total += this.currentQuote.items[i].qty
                }
            }
        }
        catch (ex) {
        }

        return total
    }

    private synchronizeQuoteId(newIdPanier) {
        if (String(newIdPanier) !== String(this.idPanier)) {
            var expireDate = this.utilsService.addSecondsToDate(new Date(), Constants.IDPANIER_STORAGE_EXPIRE)

            this.idPanier = newIdPanier

            StorageService.set(Constants.STORAGE_KEY_IDPANIER, String(this.idPanier), Constants.TYPE_STORAGE_LOCAL, expireDate)
        }
    }


    private deleteCurrentQuote() {
        this.currentQuote = new Quote(null)
        this.idPanier = null
        StorageService.delete(Constants.STORAGE_KEY_IDPANIER, Constants.TYPE_STORAGE_LOCAL)
    }

    public getListInQuote(): Observable<any> {
        var that = this
        let observer = new ReplaySubject<any>()
        var apiRequest = this.newApiRequest(Constants.URL_QUOTE, {})

        this.apiService.get(apiRequest).subscribe(
            (data) => {
                if (data !== null) {
                    observer.next(data)
                } else {
                    observer.next(null)
                }
            },
            (error) => {
                that.deleteCurrentQuote()

                observer.error(error)
            },
            () => {
                observer.complete()
            }
        )

        return observer
    }

    private countNonEmptyElems(list: any) {
        let result = 0

        list.forEach(element => {
            if(element !== "") {
                result++
            }
        })

        return result
    }

    public showArticlesOutOfStock(listLabel, listQty, listQuoteQty) {
        if(listLabel.length !== 0) {
            let articleLabels: string = ""
            let that = this

            let clear_basket = that.translateService.instant('quote.clear-basket');
           
            listLabel.forEach(function(label, id) {
                let indisponible : boolean = false
                let disponibleStr: string  = ""
                let demandeStr   : string  = ""
                let qtyMessage   : string  = ""

                if(listQty[id] === 0) {
                    disponibleStr = that.translateService.instant('quote.validate.zero')
                    indisponible = true
                } else {
                    demandeStr = (listQuoteQty[id] === 1)
                    ? that.translateService.instant('quote.validate.asked')
                    : that.translateService.instant('quote.validate.askeds')

                disponibleStr = (listQty[id] === 1)
                    ? that.translateService.instant('quote.validate.available')
                    : that.translateService.instant('quote.validate.availables')
                }

                if(indisponible) {
                    qtyMessage ="<i class='italic_popup_qte'>" + disponibleStr + "</i><br />"
                } else {
                    qtyMessage = "<i class='italic_popup_qte'>" + listQuoteQty[id] + " " + demandeStr + "</i>, "
                    qtyMessage += (indisponible)
                        ? disponibleStr + " <br />"
                        : "<i class='italic_popup_qte'>" + listQty[id] + " " + disponibleStr + "</i><br />"
                }

                articleLabels = (articleLabels === "")
                    ? "- <strong>" + label + "</strong><br />\t" + qtyMessage
                    : articleLabels + "- <strong>" + label + "</strong><br />\t" +  qtyMessage
            }, this.translateService)

            let startMsg : string = (this.countNonEmptyElems(listLabel) === 1)
                ? this.translateService.instant('quote.validate.is.out-of-stock')
                : this.translateService.instant('quote.validate.are.out-of-stock')

            this.alertService.show({
                header: this.translateService.instant('alert.title.error'),
                message: startMsg + " : <br/><div class='msg_popup_qte'>" + articleLabels + "</div>",
                cssClass : 'alert_qte',
                buttons: [{
                    text: 'Ok',
                    cssClass: 'btnvalider',
                    handler: () => { }
                },
                {
                    text: clear_basket,
                    cssClass: 'btclear',
                    handler: () => { this.clearQuote() }
                }]
            });
        } else {
            return
        }
    }

    private getCurrentQuoteArticleQty() {
        let articleQty : number[] = []

        this.currentQuote.items.forEach(item => {
            if(articleQty[item.article.id]) {
                articleQty[item.article.id] += item.qty
            } else {
                articleQty[item.article.id] = item.qty
            }

            if(item.children.length !== 0) {
                item.children.forEach(child => {
                    if(articleQty[child.article.id]) {
                        articleQty[child.article.id] += child.qty
                    } else {
                        articleQty[child.article.id] = child.qty
                    }

                    if(child.children.length !== 0) {
                        child.children.forEach(child => {
                            if(articleQty[child.article.id]) {
                                articleQty[child.article.id] += child.qty
                            } else {
                                articleQty[child.article.id] = child.qty
                            }
                        })
                    }
                })
            }
        })

        return articleQty
    }

    public getListArticleInQuote(page: string = null): Observable<any> {
        var that = this
        let observer = new ReplaySubject<any>()
        var apiRequest = this.newApiRequest(Constants.URL_QUOTE, {})
        let store: Store = this.storeService.getCurrentStore()
        let storeId: number = (store !== null) ? store.id : null

        this.apiService.get(apiRequest).subscribe(
            (data) => {
                if (data !== null) {
                    if(page === "quote") {
                        let listArticleOOStockLabel : string[] = []
                        let listArticleOOStockQty   : number[] = []
                        let listArticleQuoteQty     : number[] = []

                        data.items.forEach(item => {
                            if(item.article.stockable) {
                                let stockQty = (item.article.stocks[storeId].out_of_stock)
                                    ? 0
                                    : this.getArticleStockQty(item.article, storeId)

                                if(item.qty > stockQty) {
                                    if(listArticleOOStockLabel.indexOf(item.article.id) === -1) {
                                        listArticleOOStockLabel[item.article.id] = item.article.label
                                    }
        
                                    if(listArticleOOStockQty.indexOf(item.article.id) === -1) {
                                        listArticleOOStockQty[item.article.id] = stockQty
                                    }
        
                                    listArticleQuoteQty = this.getCurrentQuoteArticleQty()
                                }
                            }

                            if(item.children.length !== 0) {
                                item.children.forEach(child => {
                                    if(child.article.stockable) {
                                        let stockQty = (child.article.stocks[storeId].out_of_stock)
                                            ? 0
                                            : this.getArticleStockQty(child.article, storeId)

                                        if(child.qty > stockQty) {
                                            if(listArticleOOStockLabel.indexOf(child.article.id) === -1) {
                                                listArticleOOStockLabel[child.article.id] = child.article.label
                                            }
                
                                            if(listArticleOOStockQty.indexOf(child.article.id) === -1) {
                                                listArticleOOStockQty[child.article.id] = stockQty
                                            }
                
                                            listArticleQuoteQty = this.getCurrentQuoteArticleQty()
                                        }
                                    }
                                })
                            }
                        })

                        if(this.countNonEmptyElems(listArticleOOStockLabel) !== 0) {
                            this.showArticlesOutOfStock(
                                listArticleOOStockLabel,
                                listArticleOOStockQty,
                                listArticleQuoteQty
                            )
                        } else {
                            that.currentQuote.setData(data)
                            that.synchronizeQuoteId(data.id)
                        }
                    } else {
                        that.currentQuote.setData(data)
                        that.synchronizeQuoteId(data.id)
                    }
                } else {
                    that.deleteCurrentQuote()
                }

                observer.next(that.currentQuote.items)
            },
            (error) => {
                that.deleteCurrentQuote()

                observer.error(error)
            },
            () => {
                observer.complete()
            })

        return observer
    }

    private updateQuotePlace(placeCode?: string) {
        const infoTime = this.getFullDate()

        var apiRequest = this.newApiRequest(Constants.URL_QUOTE_PLACE,{})

        if (placeCode) {
            apiRequest.params = { place: placeCode, infoTime: infoTime }
        }

        const observable = placeCode ? this.apiService.post(apiRequest) : this.apiService.delete(apiRequest)

        return this.subscribe(observable)
    }

    public unplaceQuote() { return this.updateQuotePlace() }

    public placeQuote(placeCode: string) { return this.updateQuotePlace(placeCode) }

    public setMethodOfPayment(methodOfPayment: MethodOfPayment, infoTime: string) {
        this.quote.method_of_payment = methodOfPayment

        const params = { methodOfPayment: methodOfPayment, infoTime: infoTime }

        let apiRequest = this.newApiRequest(
            Constants.URL_QUOTE_METHOD_OF_PAYMENT,
            params
        )

        return this.subscribe(this.apiService.post(apiRequest))
    }

    private updateNickname(nickname?: string) {
        this.quote.pseudo = nickname

        const params = nickname ? { nickname: nickname } : null
        let apiRequest = this.newApiRequest(
            Constants.URL_QUOTE_NICKNAME,
            params
        )

        return this.subscribe(nickname ? this.apiService.post(apiRequest) : this.apiService.delete(apiRequest))
    }

    public removeNickname() { return this.updateNickname() }
    public setNickname(nickname: string) { return this.updateNickname(nickname) }

    private updateAddress(address?: string) {
        this.quote.delivery_address = address

        const params = address ? { address: address } : null
        let apiRequest = this.newApiRequest(
            Constants.URL_QUOTE_ADDRESS,
            params
        )

        return this.subscribe(address ? this.apiService.post(apiRequest) : this.apiService.delete(apiRequest))
    }
    public removeAddress() { return this.updateAddress() }
    public setAddress(address: string) { return this.updateAddress(address) }

    private updatePickUpDate(quote?: Quote, pickUpHour?: PickUpHour, pickUpDate?: string, pickUpDay?: string) {
        const params = quote ? { order_id: quote.id, pick_up_hour: pickUpHour, pick_up_date: pickUpDate, pick_up_day: pickUpDay } : null
        let apiRequest = this.newApiRequest(
            Constants.URL_QUOTE_PICK_UP_DATE,
            params
        )

        return this.subscribe(quote ? this.apiService.post(apiRequest) : this.apiService.delete(apiRequest))
    }
    public removePickUpDate() { return this.updatePickUpDate() }
    public setPickUpDate(quote: Quote, pickUpHour: PickUpHour, pickUpDate: string, pickUpDay: string) {
        return this.updatePickUpDate(quote, pickUpHour, pickUpDate, pickUpDay)
    }

    public updateTicketInfo(ticketInfo: string) {
        let apiRequest = this.newApiRequest(
            Constants.URL_PAYMENT_BY_ETP,
            { ticketInfo: ticketInfo }
        );

        return this.subscribe(this.apiService.post(apiRequest))
    }


    public setCoupon(coupon:string, deleteCoupon:boolean = false){
        let apiRequest = this.newApiRequest(
            this.utilsService.format(
                Constants.URL_COUPON, { value: coupon, symbol: '@1' }),
            {}
        );
        
        const observable = !deleteCoupon ? this.apiService.post(apiRequest) : this.apiService.delete(apiRequest)

        return this.subscribe(observable)
    }




}
