import { AppConfig } from "./app-config";

export class Constants {

    public static ASSETS_URL = '../assets/';
    static SOCIETY_ID_URL_COMPATIBLE = AppConfig.CONF.SOCIETY_ID.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
    static BASE_IMAGE_URL = `${AppConfig.CONF.BASE_URL}images/${Constants.SOCIETY_ID_URL_COMPATIBLE}/`;
    static BASE_IMAGE_FALLBACK_URL = Constants.ASSETS_URL + 'images/';
    static BASE_ARTICLES_IMAGE_URL = Constants.BASE_IMAGE_URL + 'Articles/';
    static BASE_OPTIONS_IMAGE_URL = Constants.BASE_IMAGE_URL + 'Articles/';
    static BASE_NAVIGATION_IMAGE_URL = Constants.BASE_IMAGE_URL + 'RepertoiresTablette/';
    static BASE_CATEGORY_IMAGE_URL = Constants.BASE_IMAGE_URL + 'RepertoiresTabletteCategories/';
    static BASE_FALLBACK_IMAGE_URL = Constants.BASE_IMAGE_FALLBACK_URL + 'fallback/';
    static BASE_INFO_ARTICLE_IMAGE_URL = Constants.BASE_IMAGE_URL + 'InfoArticles/';
    // HEADER ----------
    static HEADER_ANONYMOUS_USER = 'X-API-ANONYMOUS-USER';
    static HEADER_AUTHORIZATION = 'X-API-AUTHORIZATION';
    static HEADER_API_KEY = 'X-API-KEY';
    static HEADER_APP_VERSION = 'X-API-VERSION';
    static HEADER_SOCIETY = 'X-API-SOCIETY';
    static HEADER_STORE = 'X-API-STORE';
    static HEADER_QUOTE = 'X-API-QUOTE';
    static HEADER_DEVICE = 'X-API-DEVICE';

    // 3RD API
    static GEO_SEARCH_API_KEY = AppConfig.CONF.GEO_SEARCH_API_KEY

    // STORAGE ----------
    static TYPE_STORAGE_SESSION = 'session';
    static TYPE_STORAGE_LOCAL = 'local';
    static STORAGE_TYPES = [Constants.TYPE_STORAGE_SESSION, Constants.TYPE_STORAGE_LOCAL];

    static FIVE_YEARS_EXPIRE = 157680000; // 5 ans
    static DEFAULT_STORAGE_EXPIRE = 3600; // 1 heure
    static IDPANIER_STORAGE_EXPIRE = 21600; // 6 heures
    static DEFAULT_STORAGE_KEY = "key";

    // AJAX ------------
    static TIMEOUT = 10000; // une requête ajax expire après TIMEOUT millisecondes
    static RETRY_MAX = 2 // quand une requête http timeout, angular réessaiera RETRY_MAX fois

    static TYPE_GET = 'GET';
    static TYPE_POST = 'POST';
    static TYPE_PUT = 'PUT';
    static TYPE_DELETE = 'DELETE';
    static TYPE_PATCH = 'PATCH';
    static TYPES = [Constants.TYPE_GET, Constants.TYPE_POST, Constants.TYPE_PUT, Constants.TYPE_PATCH, Constants.TYPE_DELETE];

    // IMAGES
    static IMG_HOME = Constants.BASE_IMAGE_URL + "Accueil.png"
    static IMG_BREAKING = Constants.BASE_IMAGE_URL + "RUPTURE.png"
    static IMG_COMING_SOON = Constants.BASE_IMAGE_URL + "BIENTOT.png"
    static IMG_HOME_FALLBACK = Constants.BASE_IMAGE_FALLBACK_URL + "Accueil.png"
    static URL_IMAGE_TIMESTAMPS = `${AppConfig.CONF.API_URL}resources/images/timestamps/${Constants.SOCIETY_ID_URL_COMPATIBLE}`;

    // API -------------
    static API_VERSION = AppConfig.CONF.API_VERSION;
    static API_KEY = AppConfig.CONF.API_KEY;

    static locale: () => string = () => null
    static get URL_VERSIONLESS() {
        return Constants.API_VERSION == '1' ?
        `${AppConfig.CONF.API_URL}API/` :
        `${AppConfig.CONF.API_URL}${Constants.locale() || 'fr'}/API/`
    }
    static get URL() { return `${Constants.URL_VERSIONLESS}v${Constants.API_VERSION}/` }

    static HTTPCODE_401 = 401;
    static HTTPCODE_403 = 403;
    static RECONNECT_ERROR_CODES = [Constants.HTTPCODE_401, Constants.HTTPCODE_403];

    static get GET_CARDS () { return `${Constants.URL}payment/tokens`; }
    static get POST_MODIFY_CARD () { return `${Constants.URL}payment/token/`; }
    static get POST_PAYER_ORDER () { return `${Constants.URL}payment/by/token`; }
    static get URL_PAYMENT_BY_ETP () { return `${Constants.URL}payment/by/etp`; }
    static get DELETE_CARD () { return `${Constants.URL}payment/token/`; }

    static get URL_LANGUAGES() { return `${Constants.URL}languages`; }

    // API USER ---------
    static STORAGE_KEY_DEBUG_SETTINGS = "debug-settings";
    static STORAGE_KEY_USER = "user";
    static STORAGE_KEY_USER_TOKEN = "user_token";
    static STORAGE_KEY_ANONYMOUS_USER_TOKEN = "anonymous_user_token";
    static STORAGE_KEY_USER_LOGINDATA = "user_login";
    static STORAGE_MY_CARDS = "STORAGE_MY_CARDS";
    static FILE_TOKEN = "token";
    static CONF = "conf.json";
    static ASSETS_CONF = "../assets/conf.json";

    static get URL_PROFILE() { return `${Constants.URL}profile`; }
    static get URL_LOGIN() { return `${Constants.URL}login`; }
    static get URL_LOGOUT() { return `${Constants.URL}logout`; }
    static get URL_REGISTER() { return `${Constants.URL}register`; }
    static get URL_PROFILE_EDIT() { return `${Constants.URL_PROFILE}/edit`; } // mise à jour de l'user

    static get POST_RESET_PASSWORD() { return `${Constants.URL_PROFILE}/reset-password`; }
    static get URL_PASSWORD_CHANGE() { return `${Constants.URL_PROFILE}/change-password`; }

    // API NAVIGATION ----
    static STORAGE_KEY_NAVIGATION = "STORAGE_KEY_NAVIGATION";
    static NAVIGATION_CACHE_EXPIRES = 1800; // 30 minutes
    static STORAGE_KEY_NAVIGATION_CONFIGURATION = "STORAGE_KEY_NAVIGATION_CONFIGURATION";
    static NAVIGATION_CONFIGURATION_CACHE_EXPIRES = 3600; // 1h

    static get URL_NAVIGATION() { return `${Constants.URL}navigation`; }
    static get URL_NAVIGATION_CONFIGURATION() { return `${Constants.URL_NAVIGATION}/configuration`; }

    // API MERCHANDISE STRUCTURE
    static get URL_MERCHANDISE_STRUCTURE() { return `${Constants.URL}merchandise-structure`; }
    static get URL_ROOT_MERCHANDISE_STRUCTURE() { return Constants.URL_MERCHANDISE_STRUCTURE; }

    // API METHOD OF PAYMENTS
    static get URL_METHOD_OF_PAYMENTS() { return `${Constants.URL}methods-of-payments`; }

    // API ARTICLE ------
    static get URL_ARTICLES() { return `${Constants.URL}articles`; }
    static get URL_ARTICLES_X() { return `${Constants.URL_ARTICLES}/@1`; }
    static get URL_ARTICLES_X_EXTRA() { return `${Constants.URL_ARTICLES}/@1/extraInfo`; }
    static get URL_ARTICLES_CODE_X() { return `${Constants.URL_ARTICLES}/codes/@1`; }
    static get URL_UPSELLING() { return `${Constants.URL}upselling`; }
    //API STORE ---------
    static STORAGE_KEY_STORE = "STORAGE_KEY_STORE";
    static get URL_STORES() { return `${Constants.URL}stores`; }
    static get URL_STORES_X() { return `${Constants.URL_STORES}/@1`; }

    // GCS/MENTIONS
    static SOCIETY = `${Constants.URL}society`
    static get URL_SOCIETY() { return `${Constants.URL}society`; }

    //API QUOTE ---------
    static get URL_QUOTE() { return `${Constants.URL}quote`; }
    static get URL_QUOTE_PLACE() { return `${Constants.URL}quote/place`; }
    static get URL_QUOTE_NICKNAME() { return `${Constants.URL}quote/nickname`; }
    static get URL_QUOTE_ADDRESS() { return `${Constants.URL}quote/address`; }
    static get URL_QUOTE_PICK_UP_DATE() { return `${Constants.URL}quote/pick-up-date`; }
    static get URL_QUOTE_ITEM() { return `${Constants.URL}quote/item`; }
    static get URL_QUOTE_ITEM_X() { return `${Constants.URL_QUOTE_ITEM}/@1`; }
    static get URL_QUOTE_TAKEAWAY() { return `${Constants.URL}quote/takeaway/@1`; }
    static get URL_EDIT_QUOTE_ITEM_QUANTITY() { return `${Constants.URL_QUOTE_ITEM}/@1/qty/@2`; }
    static get URL_EDIT_QUOTE_ITEM_OPTIONS() { return `${Constants.URL_QUOTE_ITEM}/@1/options`; }
    static get URL_EDIT_QUOTE_ITEM_BUNDLE() { return `${Constants.URL_QUOTE_ITEM}/@1/bundle`; }
    static get URL_STATUT_PAIEMENT() { return `${Constants.URL}quote/authorized`; }
    static get URL_QUOTE_METHOD_OF_PAYMENT() { return `${Constants.URL}quote/method-of-payment`; }
    static get URL_COUPON() { return `${Constants.URL}quote/coupon/@1`; }

    static STORAGE_KEY_IDPANIER: string = "IDPANIER";
    static STORAGE_KEY_CURRENTPANIER: string = "CURRENT_PANIER";
    static STORAGE_KEY_UPSELLING:string = "UPSELLING";

    // API CATEGORY -----
    static STORAGE_KEY_CATEGORIES = "STORAGE_KEY_CATEGORIES";
    static CATEGORIES_CACHE_EXPIRES = 0; // 30 minutes

    static get URL_CATEGORIES() { return `${Constants.URL}categories`; }


    //API ORDER ---------
    static CHECK_PAYMENT_DELAY = 2000; // ms
    static CHECK_PAYMENT_LIMIT = 150;

    static get URL_ORDERS() { return `${Constants.URL}orders`; }
    static get URL_ORDER_VALIDATION() { return `${Constants.URL}order/validation`; }
    static get URL_ORDER_PLACE() { return `${Constants.URL}order/place`; }
    static get URL_ORDER_NICKNAME() { return `${Constants.URL}order/nickname`; }
    static get URL_ORDER_PICK_UP_DATE() { return `${Constants.URL}order/pick-up-date`; }

    static get URL_DETAIL_PAIEMENT() { return `${Constants.URL}payment/params`; }
    static get URL_DETAIL_EMBEDED() { return `${Constants.URL}embeded/params`; }
    // API PLACES
    static get URL_PLACE_TYPES() { return `${Constants.URL}place-types` }
    static get URL_ALL_PLACE_TYPES() { return `${this.URL}place-types-all` }
    static get URL_PLACES_WITH_TYPE() { return `${Constants.URL}places/@1` }
    static get URL_TIMESLOTS_WITH_TYPE() { return `${Constants.URL}time-slots/@1` }

    // RECAP
    static get URL_RECAP() { return `${Constants.URL}recap` }

    // TRANSLATIONS
    static get URL_TRANSLATIONS() { return `${Constants.URL}translations` }

    // IDLE SCREEN
    static get URL_IDLE_SCREEN() { return `${Constants.URL}idle-screen` }
    static get URL_IDLE_SCREENS() { return `${Constants.URL}idle-screens` }

    // MODE
    static ALLOW_ANONYMOUS: boolean = true;
    static ARTICLES_PAGE_LAYOUT: string = "grid"; //articlesPageLayout
    static ARTICLE_SELECTION_PAGE_SEQUENCE: string = "cart"; //articleSelectionPageSequence
    static TAKE_BACK_MODE: string = "scan+type"; //takeBackMode
    static NICKNAME_PAGE_ORDER: string = ""; //nicknamePageOrder
    static QUOTE_FLUSH_MODE: string = "soft"; //quoteFlushMode
    static SHOW_TVA: boolean = true;
    static SHOW_TOASTER_ADD_QUOTE: boolean = false;
    static SHOW_CART: boolean = true;
    static MENU_MODE: string = "menu"; //menuMode
    static POSITION_CART: string = "bottom"; //positionCart
    static SHOW_STRUCTURE: boolean = false;
    static COUPON_FID: boolean = false;
    static PRICE_FOR_SHOW_ONLY: boolean = false
    static SAVE_STORE_IN_STORAGE: boolean = true
    static ALLOW_OOS_PRODUCT_INFO: boolean = false
    static POSITION_MENU: string = "top"; //positionMenu
    static NB_GRID: string = "4"; //nbGrid
    static CHECK_CGV: boolean = false;
    static NUM_INTERNATIONAL: boolean = true;

    //PLANNING
    static PLANNABLE_DAYS: number = 7;
}
