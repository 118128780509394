import { Component, OnInit, ViewChild } from '@angular/core';
import { NavParams, IonInput } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {ENTER_STATE, LEAVE_STATE, TITLE_ANIMATION, FOOTER_ANIMATION} from '../../../animations/animations';
// Models
import { Order } from '../../../models/order';

// Service
import { UserService } from '../../../services/api/user.service';
import { QuoteService } from '../../../services/api/quote.service';
import { AlertService } from '../../../services/alert.service';
import { UtilsService } from '../../../services/utils.service'
import { CommandPipeService } from '../../../command-pipe/command-pipe-service';
import { CommandStepTakeBack } from '../../../command-pipe/command-step-take-back';
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';
import { Constants } from "../../../config/constants";
import { ReplaySubject } from "rxjs";
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { Platform } from "@ionic/angular";
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

@Component({
	selector: 'app-code-type',
	templateUrl: './code-type.page.html',
	styleUrls: ['./code-type.page.scss'],
	animations: [ TITLE_ANIMATION, FOOTER_ANIMATION]
})
export class CodeTypePage implements OnInit {

	@ViewChild('codeInput') private inputElement : IonInput;
	// animation
	public stateTitle = ENTER_STATE;
	public stateFooter = ENTER_STATE;

	private order: Order
	public placeCode: string
	private enterKeyListener = (e) => { 
		if (e.code && e.code.toLocaleLowerCase() == "enter") this.didTouchValidate() 
	}

	isScan:boolean = false;

	constructor(
		// private navParams: NavParams,
		private userService: UserService,
		private quoteService: QuoteService,
		private utilsService: UtilsService,
		private commandPipeService: CommandPipeService,
		private alertService: AlertService,
		private translateService: TranslateService,
		public router: Router,
		private route: ActivatedRoute,
		private qrScanner: QRScanner,
		private androidPermissions: AndroidPermissions,
		private platform: Platform, 
	) {
		// this.order = this.navParams.get('order')
		this.route.queryParams.subscribe(params => {
			if (this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state !== undefined) {
				this.order = this.router.getCurrentNavigation().extras.state.order;
				// console.log(this.order);
			}
		});
	}

	ionViewDidEnter() {
		this.commandPipeService.reset(CommandStepTakeBack.id)
		document.addEventListener('keypress', this.enterKeyListener)
		this.getBackFocus();
		this.placeCode = '';
	}
	ionViewDidLeave() { 
		document.removeEventListener('keypress', this.enterKeyListener);
		this.qrScanner.destroy();
		this.placeCode = '';
	}

	didTouchValidate() {
		this.validateCodeAction(this.placeCode, () => this.getBackFocus());
	}

	public validateCodeAction(code: string, callback: () => void) {
		let observer = this.order ? this.userService.placeOrder(this.order.id, code) : this.quoteService.placeQuote(code);

		observer.subscribe((data) => {
			this.commandPipeService.isPlaced = true
			this.commandPipeService.next(CommandStepTakeBack.id)
		}, (error) => {
			let message = this.utilsService.getErrorMessage(error);
			let title = this.utilsService.getErrorTitle(error);
			this.alertService.show({
				cssClass : 'alert-margintop11',
				header: title ? title : this.translateService.instant("code-validation.invalid-alert.title"),
				message: message ? message : this.translateService.instant("code-validation.invalid-alert.message"),
				buttons: [{
					cssClass: 'btnvalider',
					text: this.translateService.instant("ok"),
					handler: callback
				}]
			})
		});
	}

	public getBackFocus() {
		// note: les évenement angular ne nous permettent pas a l'heure actuelle de trouver de meilleure solution
		console.log(this.inputElement);
		setTimeout(() => { 
			// this.inputElement._native.nativeElement.focus() 
			this.inputElement.setFocus();
		}, 500);
	}

	ngOnInit(): void {
		setTimeout(() => this.stateTitle = LEAVE_STATE)
		setTimeout(() => this.stateFooter = LEAVE_STATE)
	}

	gotoPickType(){
		this.router.navigate(['tabs/pick-up']);
	}


	// checkScan(){
	// 	let observer = new ReplaySubject

	// 	switch(Constants.TAKE_BACK_MODE) {
	// 		case "scan":
	// 		case "type+scan":
	// 		case "scan+type": {
	// 			const scanningDisallowed = () => {
	// 				console.log('scan disallowed');
	// 				if (Constants.TAKE_BACK_MODE == "scan") {
	// 					observer.error(null)
	// 				} else {
	// 					this.isScan = false;
	// 					observer.next(CodeTypePage)
	// 				}
	// 			}
	// 			if (this.platform.is('mobile')) { // note: semble ne pas fonctionner
	// 				console.log('is mobile: ',this.platform);

	// 				// Android camera permission
	// 				this.androidPermissions.checkPermission(
	// 					this.androidPermissions.PERMISSION.CAMERA
	// 				).then( result => console.log('Has permission?',result.hasPermission),
	// 				err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
	// 				);

	// 				this.androidPermissions.requestPermissions([
	// 					this.androidPermissions.PERMISSION.CAMERA, 
	// 					this.androidPermissions.PERMISSION.GET_ACCOUNTS
	// 				]);

	// 				this.qrScanner.prepare().then((status: QRScannerStatus) => {
	// 					console.log('scan status: ',status);
	// 					if (status.authorized) {
	// 						observer.next(null);
	// 						this.isScan = true;
	// 						this.commandPipeService.reset(CommandStepTakeBack.id)
	// 						this.scan()
	// 						this.getBackFocus()
	// 						// observer.next(CodeScanPage)
	// 						// this.router.navigate(['tabs/code-scan']);
	// 					} else {
	// 						scanningDisallowed()
	// 					}
	// 				}).catch(_ =>{
	// 					this.isScan = false;
	// 					scanningDisallowed
	// 				});
	// 			} else scanningDisallowed()
	// 			break
	// 		}
	// 		case "type":
	// 		default: observer.next(CodeTypePage); break
	// 	}
	// 	return observer
	// }


	// private scan() {
	// 	console.log('scan start on scan page');
	// 	const scanSub = this.qrScanner.scan().subscribe((text: string) => {
	// 		console.log('scan subscribe');
	// 		text = text.split('/').pop()
	// 		const qrScanner = this.qrScanner
	// 		qrScanner.hide(); // hide camera preview
	// 		qrScanner.pausePreview();
	// 		scanSub.unsubscribe(); // stop scanning

	// 		this.validateCodeAction(text, () => this.scan());
	// 	});

	// 	this.qrScanner.show()
	// 	this.qrScanner.resumePreview()
	// }

	// public didFocusInput() { this.qrScanner.pausePreview() }
	// public didBlurInput() { this.qrScanner.resumePreview() }

}
