import { Category } from "./category";

export class Bundle {

    public bundleId: number;
    public categories : Category[] = null;

    constructor(data) {
        if(data !== null) {
          this.bundleId= typeof data.id !== "undefined" ? data.id :null;
          this.bundleId= typeof data.bundleId !== "undefined" ? data.bundleId :this.bundleId;
          if(typeof data.categories !== "undefined" ) {
              this.categories = [];
            for(let i in data.categories) {
              var _category = data.categories[i];
              var tempCategory = _category.category;
              if(typeof tempCategory === 'undefined') {
                  tempCategory = _category;
              }
              tempCategory.position = _category.position
              this.categories.push( new Category( tempCategory ) );
            }
          }
        }
    }
}
