import { Injectable } from '@angular/core';
import { Action } from "../std/action";

@Injectable({
  providedIn: 'root'
})
export class IdleDetectorService {

	private static EVENTS = [
        // "abort",
        "auxclick",
        // "beforecopy",
        // "beforecut",
        // "beforepaste",
        // "blur",
        // "cancel",
        // "canplay",
        // "canplaythrough",
        // "change",
        "click",
        // "close",
        // "contextmenu",
        // "copy",
        // "cuechange",
        // "cut",
        "dblclick",
        "drag",
        "dragend",
        "dragenter",
        "dragleave",
        "dragover",
        "dragstart",
        // "drop",
        // "durationchange",
        // "emptied",
        // "ended",
        // "error",
        // "focus",
        // "freeze",
        // "fullscreenchange",
        // "fullscreenerror",
        // "gotpointercapture",
        // "input",
        // "invalid",
        "keydown",
        "keypress",
        "keyup",
        // "load",
        // "loadeddata",
        // "loadedmetadata",
        // "loadstart",
        // "lostpointercapture",
        "mousedown",
        "mouseenter",
        "mouseleave",
        "mousemove",
        "mouseout",
        "mouseover",
        "mouseup",
        "mousewheel",
        // "paste",
        // "pause",
        // "play",
        // "playing",
        "pointercancel",
        "pointerdown",
        "pointerenter",
        "pointerleave",
        "pointerlockchange",
        "pointerlockerror",
        "pointermove",
        "pointerout",
        "pointerover",
        "pointerup",
        // "progress",
        // "ratechange",
        // "readystatechange",
        // "reset",
        // "resize",
        // "resume",
        //"scroll", // note: le scroll automatique du carousel dans la page de catalogue empeche l'utilisation de cet evenement
        // "search",
        // "seeked",
        // "seeking",
        // "select",
        // "selectionchange",
        // "selectstart",
        // "stalled",
        // "submit",
        // "suspend",
        // "timeupdate",
        // "toggle",
        // "visibilitychange",
        // "volumechange",
        // "waiting",
        // "webkitfullscreenchange",
        // "webkitfullscreenerror",
        // "wheel"
    ]

    private interval
    private lastEventTimestamp
    private lastTickTimestamp
    private paused: boolean

    public delay: number
    public action: Action

    constructor() {}

    private listener = (e?) => { this.lastTickTimestamp = this.lastEventTimestamp = Date.now(); /*console.log(e)*/ }

    private tick(dt: number) {
        if (this.paused) this.lastEventTimestamp += dt
        else {
            // console.log('IdleDetectorService::tick', this.lastEventTimestamp && Date.now() - this.lastEventTimestamp, this.delay)
            if (this.lastEventTimestamp && Date.now() - this.lastEventTimestamp > this.delay) {
                this.action.do()
                // définir a null le lastEventTimestamp permet d'éviter de rapeller l'action a chaque intervalle après qu'elle ai été exécuté une fois
                this.lastTickTimestamp = this.lastEventTimestamp = null
            }
        }
    }

    public start() {
        if (this.interval) return // l'appel a start plusieurs fois est indolore pour le détecteur d'innacitivité

        for (const EVENT of IdleDetectorService.EVENTS) document.addEventListener(EVENT, this.listener, true)

        const that = this
        this.interval = setInterval(
            () => {
                const now = Date.now()
                that.tick(now - that.lastTickTimestamp)
                that.lastTickTimestamp = now
            },
            100
        )
        // console.log('IdleDetectorService::start')
    }
    public restart() {
        this.stop()
        this.listener()
        this.start()
    }

    public stop() {
        // console.log('IdleDetectorService::stop')
        if (!this.interval) return // l'appel a start plusieurs fois est indolore pour le détecteur d'innacitivité

        clearInterval(this.interval)
        this.interval = null
        const listener = this.listener
        for (const EVENT of IdleDetectorService.EVENTS) document.removeEventListener(EVENT, listener, true)
    }

    public pause() { if (this.interval) this.paused = true; 
        // console.log('IdleDetectorService::pause') 
    }
    public resume() { if (this.interval) this.paused = false; 
        // console.log('IdleDetectorService::resume') 
    }
	
}
