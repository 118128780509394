// import { App } from "ionic-angular";

// std
import { Action } from "../std/action";

// Pages
// import { ReservationPage } from "../../pages/command-pipe/reservation/reservation.page";

// Services
import { CommandPipeService } from "./command-pipe-service";
import { QuoteService } from "../services/api/quote.service";
import { UserService } from "../services/api/user.service";
import { PlaceService, } from "../services/api/place.service";

import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

export class CommandStepReservation implements Action {
    static readonly id = 'CommandStepReservation'

    constructor(
        // private app: App, 
        public router: Router,
        private commandPipeService: CommandPipeService,
        private placeService: PlaceService,
        private quoteService: QuoteService,
        private userService: UserService
    ) {}

    public do() {
        const place = this.commandPipeService.place;
        const placeType = this.commandPipeService.placeType;
        if (!place) {
            if (placeType && (placeType.code == 4 ) && !this.commandPipeService.reservation ) {
                this.placeService.getPlaces(placeType.code).subscribe(
                    places => {
                        this.commandPipeService.place = places[0]
                        this.placeService.getTimeSlots(placeType.code).subscribe(
                            timeSlots => {
                                const navigationExtras: NavigationExtras = {
                                    state: {
                                        timeSlots: timeSlots
                                    }
                                };
                                this.router.navigate(['tabs/reservation'], navigationExtras);
                            } )
                    }
                )
            } else this.commandPipeService.next(CommandStepReservation.id, true)
        } else {
            const order = this.commandPipeService.order
            let observer = order ? this.userService.placeOrder(order.id, place.label) : this.quoteService.placeQuote(place.label);

            observer.subscribe(
                data => {
                    this.commandPipeService.place = place
                    this.commandPipeService.next(CommandStepReservation.id, true)
                }
            )
        }
    }

    public undo() { 
        this.commandPipeService.reservation = null
        //optimisation pour éviter de faire des appels API après validation commande
        if(this.commandPipeService.isPurchasing) {
            this.quoteService.removePickUpDate()
        }
    }
}