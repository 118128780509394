import { HttpHeaders } from '@angular/common/http';

import { Constants } from '../config/constants';

export class ApiRequest<T> {

    public method: string = Constants.TYPE_GET;
    // méthode par défaut à GET
    constructor(
        public url: string,
        public params: Object,
        public successCallback: (p?:T) => any = () => {},
        public errorCallback: (p?) => any = () => {},
        public doneCallback: () => any = () => {},
        public headers: HttpHeaders = new HttpHeaders()
    ) {}
}
 
