import { Injectable } from '@angular/core';

export interface Page {
	id: string;
	title: string;
	url: string;
	icon: string;
	index: number;
	badge: () => number;
	hidden?: boolean;
	tab?: string;
}
 
@Injectable({
	providedIn: 'root'
})

export class PagesIndexService {

	tabPages:Page [] = []; 

	addPages(pages: Page) {
		this.tabPages.push(pages);
	}

	getPages(){
		return this.tabPages;
	}

	public getPage(label: string): Page {
		let page;
		this.tabPages.forEach(data => {
			if(data.id === label){
				page = data;
			}
		});
        return page;
    }


	public getPageIndex(label: string): number {
        let page = this.getPage(label);
        return page ? page.index : null;
    }

}