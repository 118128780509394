import { Store } from "./store";

export class ArticleOffer {
    public id: number;
    public from_date: string;
    public to_date: string;
    public begin : string;
    public end: string;
    public day: number;
    public price: number;
    public store: Store;
    private i18nLabel1 : i18nString
    private _label1 : string
    private i18nLabel2 : i18nString
    private _label2 : string

    public get label1() { return localize(this.i18nLabel1, this._label1) }
    public get label2() { return localize(this.i18nLabel2, this._label2) }
    
    constructor(data) {
        if (data) {
            this.id = Number(data.id || 0)
            if(data.from_date) this.from_date = data.from_date
            if(data.to_date) this.to_date = data.to_date
            if(data.begin) this.begin = data.begin
            if(data.end) this.end = data.end
            if(data.day) this.day = Number(data.day)
            this.price = Number(data.price)
            if (data.store) this.store = new Store(data.store)
            }
            this._label1 = this._setData(data.label1, undefined)
            this._label2 = this._setData(data.label2, undefined)
            this.i18nLabel1 = typeof data.i18n_label1 !== "undefined" ? data.i18n_label1 : data.i18nLabel1
            this.i18nLabel2 = typeof data.i18n_label2 !== "undefined" ? data.i18n_label2 : data.i18nLabel2
    }

    private _setData(field, value = undefined) {
        const result = (typeof field !== "undefined")
            ? field
            : value

        return result
    }
}