export class PlaceType {
    public id: number
    public code: number
    public available_outside_timeslot: boolean
    public _label: string
    public i18n_label: i18nString
    public get label() { return localize(this.i18n_label, this._label) }
    public set label(label: string) { this._label = label }

    constructor(placeType?: PlaceType) {
        let isAnonymousObject = !(placeType instanceof PlaceType)
        if (placeType) {
            this.id = placeType.id
            this.code = placeType.code
            this.available_outside_timeslot = placeType.available_outside_timeslot
            this._label = isAnonymousObject ? placeType.label : placeType._label
            this.i18n_label = placeType.i18n_label
        }
    }
}