import { Injectable } from "@angular/core";
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { Platform } from "@ionic/angular";
import { ReplaySubject } from "rxjs";
import { Constants } from "../config/constants";
import { CodeTypePage } from "../pages/command-pipe/code-type/code-type.page";
import { CodeScanPage } from "../pages/command-pipe/code-scan/code-scan.page";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

@Injectable({
	providedIn: 'root'
})
export class TakeBackComponentService {

	constructor(
		private platform: Platform, 
		private qrScanner: QRScanner,
		public router: Router,
		private androidPermissions: AndroidPermissions
	) {}

	// this.router.navigate(['tabs/code-scan']);
	// this.router.navigate(['tabs/code-type']);

	public getTakeBackPage() {
		let observer = new ReplaySubject

		switch(Constants.TAKE_BACK_MODE) {
			case "scan":
			case "type+scan":
			case "scan+type": {
				const scanningDisallowed = () => {
					if (Constants.TAKE_BACK_MODE == "scan") {
						observer.error(null)
					} else {
						// observer.next(CodeTypePage)
						observer.next('tabs/code-type');
					}
				}
				if (this.platform.is('mobile')) { // note: semble ne pas fonctionner

					// Android camera permission
					this.androidPermissions.checkPermission(
						this.androidPermissions.PERMISSION.CAMERA
					).then( result => console.log('Has permission?',result.hasPermission),
					err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
					);

					this.androidPermissions.requestPermissions([
						this.androidPermissions.PERMISSION.CAMERA, 
						this.androidPermissions.PERMISSION.GET_ACCOUNTS
					]);

					this.qrScanner.prepare().then((status: QRScannerStatus) => {
						if (status.authorized) {
							// observer.next(CodeScanPage)
							observer.next('tabs/code-scan');
						} else {
							scanningDisallowed()
						}
					}).catch(scanningDisallowed);
				} else scanningDisallowed()
				break
			}
			case "type":
			default: observer.next(CodeTypePage); break
		}
		return observer
	}
}
