import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public popupArray = [];

  constructor(private alertCtrl: AlertController)  {}

  public async show(popupParams) {
      let popup = await this.alertCtrl.create(popupParams);
      await popup.present();
      // comment by sajid
      // this.popupArray.push(popup)
      // const alert = new AlertDecorateur(popup, popup.present())
      // alert.onDidDismiss(() => UtilsService.removeObjectFromArray(popup, this.popupArray) )
      // return alert
  }

  public dismissAll() {
    for(let popup of this.popupArray) popup.dismiss() 
  }
}
