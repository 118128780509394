export class Language {
    id: number
    position?: number
    locale: string
    label: string

    constructor(language?: Language) {
        if (language) {
            if (language.id) this.id = language.id
            if (language.position) this.position = language.position
            if (language.locale) this.locale = language.locale
            if (language.label) this.label = language.label
        }
    }
}