export class Card {
    id: number;
    label: string;
    number: string;
    brand: string;
    expiryMonth: string;
    expiryYear: string;

    constructor(data) {
        if (typeof data === "undefined" || data === null) {
            this.id = -1;
            this.label = '';
            this.number = '';
            this.brand = '';
            this.expiryMonth = '';
            this.expiryYear = '';;
        }
        else {
            this.setData(data);
        }
    }
    setData(data) {
      if (typeof data !== "undefined" && data !== null) {
        this.id = typeof data.id !== "undefined" ? data.id : -1;
        this.label = typeof data.label !== "undefined" ? data.label : '';
        this.number = typeof data.number !== "undefined" ? data.number : '';
        this.brand = typeof data.brand !== "undefined" ? data.brand : '';
        this.expiryMonth = typeof data.expiryMonth !== "undefined" ? data.expiryMonth : '';
        this.expiryYear = typeof data.expiryYear !== "undefined" ? data.expiryYear : '';
      }
    }
}
