import { PickUpHour } from "./pick-up-hour";
import { PlaceType } from "./place-type";

export class Timeslot {
    public id: number;
    public day: number;
    public date: string;
    public begin: string;
    public end: string;
    public place_type: PlaceType
    public pick_up_hours: PickUpHour[];
    public get isFulfilled() { return this.begin && this.end }
    constructor(timeslot?: Timeslot) {
        if (timeslot) {
            if (timeslot.id !== undefined) this.id = Number(timeslot.id)
            if (timeslot.day) this.day = Number(timeslot.day)
            if (timeslot.date) this.date = timeslot.date
            if (timeslot.begin) this.begin = timeslot.begin
            if (timeslot.end) this.end = timeslot.end
            if (timeslot.place_type) this.place_type = new PlaceType(timeslot.place_type)
            if (timeslot.pick_up_hours) {
                const pick_up_hours = this.pick_up_hours = []
                const data_pick_up_hours = timeslot.pick_up_hours
                for (const data_pick_up_hour of data_pick_up_hours) {
                    pick_up_hours.push(new PickUpHour(data_pick_up_hour))
                }
            }
        }
    }
}
