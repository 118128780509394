import { Attribute } from './attribute';
import { Value } from './value';
import { Article } from './article';

export class ArticleAttribute {

    public id?: number;
    public _label: string;
    public get defaultLabel() { return this.value ? this.value._label : this._label }
    public set defaultLabel(label: string) { this._label = label }
    public get label() { return this.value ? this.value.label : localize(this.i18nLabel, this._label) }
    public set label(label: string) { this._label = label }
    public i18nLabel: i18nString;
    public position: number;
    public attribute: Attribute;
    public article: Article;
    public value: Value;

    public values: string[]

    public static articleAttributes: ArticleAttribute[] = []

    constructor(data) {
        this.id = typeof data.id !== "undefined" ? data.id : undefined;
        this._label = typeof data.label !== "undefined" ? data.label : undefined;
        this.i18nLabel = typeof data.i18n_label !== "undefined" ? data.i18n_label : data.i18nLabel;
        this.position = typeof data.position !== "undefined" ? data.position : undefined;
        this.attribute = typeof data.attribute !== "undefined" ? new Attribute(data.attribute) : undefined;
        this.article = typeof data.article !== "undefined" ? data.article : undefined;
        this.value = typeof data.value !== "undefined" ? data.value : undefined;
        this.values = typeof data.values !== "undefined" ? data.values : [];
    }
}


