import { Article } from "./article"
import { PlaceType } from "./place-type"

export type leafletLayer = {
    type: string,
    features: any[]
}

export class Place {
    public id: number
    public label: string
    public delivery_time: number
    public article: Article
    public place_type: PlaceType        
    public shipping_area: leafletLayer;
    public shipping_zipcode: string;

    constructor(place?: Place) {
        if (place) {
            this.id = place.id
            this.label = place.label
            if (place.delivery_time) this.delivery_time = Number(place.delivery_time)
            if(place.article) this.article = new Article(place.article)
            if (place.place_type) this.place_type = new PlaceType(place.place_type)
            if(place.shipping_area) this.shipping_area = JSON.parse((place as any).shipping_area)
            else this.shipping_area = {
                "type": "FeatureCollection",
                "features": []
            }
            if (place.shipping_zipcode) this.shipping_zipcode = place.shipping_zipcode
        }
    }
}