import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

import { UtilsService } from "./utils.service";
import { AlertService } from "./alert.service";
import { UserService } from "./api/user.service";

@Injectable({
	providedIn: 'root'
})
export class PayService {

	constructor(
		private userService: UserService,
		private utilsService: UtilsService,
		private translateService: TranslateService,
		private alertService: AlertService,
		public router: Router,
		private route: ActivatedRoute
	) { }

	// Alerte simple avec bouton OK
	public presentErrorAlert(fallbackMessage: string, error: { message: string } = null) {
		let message = this.utilsService.getErrorMessage(error);
		this.presentAlert(this.translateService.instant('alert.title.error'), message ? message : fallbackMessage);
	}
	
	private presentAlert(title: string, message: string) {
		this.alertService.show({
			cssClass:'alert-margintop11',
			header: title,
			message: message,
			buttons: [{
				text: 'ok',
				cssClass: 'btnvalider'
			}]
		});
	}

	payAction(navigate:string) {
		console.log('navigate: ',navigate);
		this.userService.getDetailEmbeded().subscribe((data) => {
			if (data) {
				// this.navCtrl.push(PayzenEmbededPage, { publicKey: data.publicKey, formToken: data.formToken });
				const navigationExtras: NavigationExtras = {
					state: {
						publicKey: data.publicKey, 
						formToken: data.formToken 
					}
				};
				this.router.navigate([navigate], navigationExtras);

			}
		}, (error) => {
			this.presentErrorAlert(this.translateService.instant("card-mop.payment-error-alert.message"));
		});
	};


}
