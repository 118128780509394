import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { ApiRequest } from "../../models/apirequest";
import { Constants } from "../../config/constants";
import { ReplaySubject } from "rxjs";
import { AppConfig } from "../../config/app-config";

import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class IdleScreenService {

	constructor(
		private apiService: ApiService,
      private http: HttpClient,
   ){
      apiService.delegate = this
   }

   public getScreen() {
      const observer = new ReplaySubject<{ html: string }>()
      let request = new ApiRequest(
      	Constants.URL_IDLE_SCREEN,
         null
      );

     	this.apiService.get(request).subscribe(
         data => { observer.next(data) },
         error => { observer.error(error) }
      )
        
     	return observer
   }

   public getScreens() {
      const observer = new ReplaySubject<[{ html: string }]>()
      let request = new ApiRequest(
         Constants.URL_IDLE_SCREENS,
         null
     	);

      this.apiService.get(request).subscribe(
         data => { observer.next(data) },
         error => { observer.error(error) }
      )
      return observer
   }
   
}
