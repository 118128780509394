import { Directive, Input, ElementRef, SecurityContext } from "@angular/core";

@Directive({ selector: '[innerHTMLAndCSS]' })
export class InnerHtmlDirective {

    @Input() set innerHTMLAndCSS(context: string) {
        if (context)
            context = context.replace(/\n/g, '')
                .replace(/<script>.*<\/script>/g, '')
                .replace(/\s*on[a-z]+\s*=\s*"(\\"|[^"])*"/gi, '')
                .replace(/\s*on[a-z]+\s*=\s*'(\\'|[^'])*'/gi, '')
        this.elementRef.nativeElement.innerHTML = context || ''
    }

    constructor(private elementRef: ElementRef) { }
}