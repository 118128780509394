import { trigger, animate, state, transition, style } from '@angular/animations';

const SHORT_DELAY = 170
const MEDIUM_DELAY = 2 * SHORT_DELAY
const LONG_DELAY = 2 * MEDIUM_DELAY
const X_LONG_DELAY = 4 * MEDIUM_DELAY

export const ENTER_STATE = "enterState"
export const LEAVE_STATE = "leaveState"

export const ODD_STATE = "oddState"
export const EVEN_STATE = "evenState"
export const FINAL_STATE = "finalstate"

export const FADE_BLOC_ANIMATION =
    trigger('loadAnimationFade', [
        transition(':enter', [
            style({opacity: 0}),
            animate('300ms', style({opacity:1}))
        ]),
        transition(':leave', [
            style({ opacity: 1}),
            animate('300ms', style({opacity: 0}))
        ])
])

export const FADE_IN_ANIMATION =
    trigger('loadAnimationFade',[
        state(ENTER_STATE,style({
            opacity: '0'
        })
        ),
        state(LEAVE_STATE,style({
            opacity: '1'
        })
    ),transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(2000))
])


export const HOME_LOGO_ANIMATION =
trigger('homeLogoAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const HOME_BUTTON_ANIMATION =
trigger('homeButtonAnimation',
    [
    state(ODD_STATE,
        style({
            opacity: '0',
            transform: 'translateX(-100%)'
        })
    ),
    state(EVEN_STATE,
        style({
            opacity: '0',
            transform: 'translateX(100%)'
        })
    ),
    state(FINAL_STATE,
        style({
            opacity: '1',
            transform: 'translateX(0)'
        })
    ),
    transition(`${ODD_STATE} => ${FINAL_STATE}`,animate(`${MEDIUM_DELAY}ms cubic-bezier(0.725, 1.650, 0.900, 0.845)`)),
    transition(`${EVEN_STATE} => ${FINAL_STATE}`,animate(`${MEDIUM_DELAY}ms cubic-bezier(0.725, 1.650, 0.900, 0.845)`))
])

export const HOME_MENU_ANIMATION =
trigger('homeMenuAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0'
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1'
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(MEDIUM_DELAY))
])

export const CATALOGUE_ANIMATION =
trigger('catalogueAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(MEDIUM_DELAY)),
    transition(`${LEAVE_STATE} => ${ENTER_STATE}`,animate(MEDIUM_DELAY))
])

export const INFO_IMG_ANIMATION =
trigger('infoImgAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(SHORT_DELAY))
])

export const INFO_CONTENT_ANIMATION =
trigger('infoContenAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const QUOTE_ANIMATION =
trigger('quoteAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const NICKNAME_ANIMATION =
trigger('nicknameAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const DELIVERY_ANIMATION =
trigger('deliveryAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const PICKUP_ANIMATION =
trigger('pickupAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const VORDER_ANIMATION =
trigger('vorderAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const CARDLIST_ANIMATION =
trigger('cardlistAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const LANGAGE_ANIMATION =
trigger('langageAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(MEDIUM_DELAY))
])

export const OPTION_ANIMATION =
trigger('optionAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const BUNDLE_ANIMATION =
trigger('bundleAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const RECAP_ANIMATION =
trigger('recapAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const CROSS_ANIMATION =
trigger('crossAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const LOGIN_LOGO_ANIMATION =
trigger('loginLogoAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(SHORT_DELAY))
])

export const LOGIN_FORM_ANIMATION =
trigger('loginFormAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const LOGIN_BUTTON_CONNECT_ANIMATION =
trigger('loginButtonConnectAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
            transform: 'translateX(-100%)'
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
            transform: 'translateX(0)'
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(`${MEDIUM_DELAY}ms cubic-bezier(0.725, 1.650, 0.900, 0.845)`))
])

export const LOGIN_BUTTON_REGISTER_ANIMATION =
trigger('loginButtonRegisterAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
            transform: 'translateX(100%)'
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
            transform: 'translateX(0)'
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(`${SHORT_DELAY}ms cubic-bezier(0.725, 1.650, 0.900, 0.845)`))
])

export const LOGIN_LINK_ANIMATION =
trigger('loginLinkAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0'
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '0.5'
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(X_LONG_DELAY))
])

export const PROFIL_ANIMATION =
trigger('profilAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '1',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const PROFIL_BUTTON_ODD_ANIMATION =
trigger('profilButtonOddAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
            transform: 'translateX(-100%)'
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
            transform: 'translateX(0)'
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(`${MEDIUM_DELAY}ms cubic-bezier(0.725, 1.650, 0.900, 0.845)`))
])

export const PROFIL_BUTTON_EVEN_ANIMATION =
trigger('profilButtonEvenAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
            transform: 'translateX(100%)'
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
            transform: 'translateX(0)'
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(`${MEDIUM_DELAY}ms cubic-bezier(0.725, 1.650, 0.900, 0.845)`))
])

export const PASSWORD_LOST_ANIMATION =
trigger('passwordLostAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const USER_FORM_ANIMATION =
trigger('userFormAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const STORE_LIST_ANIMATION =
trigger('storeListAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const STORE_DETAIL_NAME_ANIMATION =
trigger('storeDetailNameAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(MEDIUM_DELAY))
])

export const STORE_DETAIL_ITEM_ANIMATION =
trigger('storeDetailItemAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const ORDER_LIST_ANIMATION =
trigger('orderListAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const ORDER_DETAIL_ANIMATION =
trigger('orderDetailAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])

export const INFO_BUTTON_ANIMATION =
trigger('infoButtonAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(LONG_DELAY))
])


export const PROFIL_BUTTON_ANIMATION =
trigger('profilButtonAnimation',
    [
    state(ENTER_STATE,
        style({
            opacity: '0',
            transform: 'translateY(500px)'
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
            transform: 'translateY(0)'
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(MEDIUM_DELAY))
])

export const TITLE_ANIMATION =
trigger('titleAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
            transform: 'translateX(-300px)'
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
            transform: 'translateX(0)'
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(SHORT_DELAY))
])

export const FOOTER_ANIMATION =
trigger('footerAnimation',[
    state(ENTER_STATE,
        style({
            opacity: '0',
            transform: 'translateY(300px)'
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
            transform: 'translateY(0)'
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(SHORT_DELAY))
])

export const FOOTER_ANIMATION_INFO =
trigger('footerAnimationInfo',[
    state(ENTER_STATE,
        style({
            opacity: '0',
            transform: 'translateY(100px)'
        })
    ),
    state(LEAVE_STATE,
        style({
            opacity: '1',
            transform: 'translateY(0px)'
        })
    ),
    transition(`${ENTER_STATE} => ${LEAVE_STATE}`,animate(SHORT_DELAY))
])

