import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

    public addSecondsToDate(date: Date, seconds: number): Date {
        return new Date(date.getTime() + 1000 * seconds);
    }

    /**
     * Remplace le contenu d'une chaine avec des symboles par des valeurs
     *
     * @param pattern Chaine de texte a compléter
     * @param params Couple de valeur, symbole avec pour chaque valeur une correspondance en symbole dans la chaine
     */
    public format(pattern: string, ...params: { value: string, symbol: string }[]): string {
        for (let param of params) {
            pattern = pattern.replace(param.symbol, param.value);
        }
        return pattern;
    }

    /**
     * Extrait le message des données d'un retour d'erreur de l'api heyou
     *
     * @param data données d'où extraire le message d'erreur
     */
    public getErrorMessage(data: any): string {
        if (!data) return null;
        if (data.error && data.error.message) return data.error.message;
        if (data.message) return data.message;
        return null;
    }

    public getErrorTitle(data: any): string {
        if (!data) return null;
        if (data.error && data.error.title) return data.error.title;
        if (data.title) return data.title;
        return null;
    }

    public getRequestErrorMessage(data: any): string {
        if (!data) return null;
        if(data.requestError && data.requestError.message) return data.requestError.message;
        return null;
    }

    public getRequestErrorTitle(data: any): string {
        if (!data) return null;
        if(data.requestError && data.requestError.title) return data.requestError.title;
        return null;
    }

    public static removeObjectFromArray<T>(object: T, array: Array<T>|T[]) {
        let index = array.indexOf(object);
        if (index >= 0) array.splice(index, 1);
    }
}
