import { Injectable } from '@angular/core';
import { Constants } from "../../config/constants";
import { AuthenticationService } from "../authentication.service";
import { AppConfig } from "../../config/app-config";
import { HttpHeaders } from '@angular/common/http';

export interface StoreIdHeaderContainer { storeId?: number }
export interface DeviceCodeHeaderContainer { deviceCode?: string }

@Injectable({
  providedIn: 'root'
})
export class ApiHeaderService implements StoreIdHeaderContainer, DeviceCodeHeaderContainer {
    public storeId?: number
    public deviceCode?: string

    constructor(private authenticationService: AuthenticationService) {}

    public injectHeaders(headers: HttpHeaders): HttpHeaders {
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set(Constants.HEADER_API_KEY, Constants.API_KEY);
        headers = headers.set(Constants.HEADER_APP_VERSION, AppConfig.CONF.APP_VERSION);
        headers = headers.set(Constants.HEADER_SOCIETY, AppConfig.CONF.SOCIETY_ID);

        var user_token = this.authenticationService.getUserTokenInStorage();
        if(user_token) {
          headers = headers.set(Constants.HEADER_AUTHORIZATION, 'Bearer ' + user_token);
        }

        let storeId = this.storeId
        if (storeId) {
          headers = headers.set(Constants.HEADER_STORE, String(storeId));
        }
        headers = headers.set(Constants.HEADER_DEVICE, this.deviceCode || 'mobile');

        if (this.authenticationService.anonymousToken) { 
          headers = headers.set(Constants.HEADER_ANONYMOUS_USER, String(this.authenticationService.anonymousToken))
        }

        return headers;
    }
}