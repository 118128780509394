// import { App } from "ionic-angular";
import { TranslateService } from "@ngx-translate/core";
import { Action } from "../std/action";
import { MethodOfPayment } from "../models/method-of-payment";
import { ValidationOrderPage } from "../pages/command-pipe/validation-order/validation-order.page";
import { CashMopComponent } from "../pages/command-pipe/validation-order/components/cash-mop/cash-mop.component";
import { CardMopComponent } from "../pages/command-pipe/validation-order/components/card-mop/card-mop.component";
import { EmbededMopComponent } from "../pages/command-pipe/validation-order/components/embeded-mop/embeded-mop.component";
import { EtpMopComponent } from "../pages/command-pipe/validation-order/components/etp-mop/etp-mop.component";
import { MethodOfPaymentService } from "../services/api/method-of-payment.service";
import { CommandPipeService } from "./command-pipe-service";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

// Mop: Method of Paiement
export class CommandStepMop implements Action {
	static readonly id = 'CommandStepMop'

	constructor(
		// private app: App,
		public router: Router,
		private commandPipeService: CommandPipeService,
		private methodOfPaymentService: MethodOfPaymentService,
		private translateService: TranslateService,
	) {}

	public do() {
		this.methodOfPaymentService.getMethodOfPayments().subscribe(
			(data: MethodOfPayment[]) => {
				// Command pipe conf
				let methodOfPayments = this.commandPipeService.methodsOfPayments = []
				for (let mop of data) {
					switch (mop.type.id) {
						case 1: methodOfPayments.push({ data: mop, component: CashMopComponent }); break
						case 2: methodOfPayments.push({ data: mop, component: CardMopComponent }); break
						case 3: methodOfPayments.push({ data: mop, component: EtpMopComponent }); break
						case 4: methodOfPayments.push({ data: mop, component: EmbededMopComponent }); break
					}
				}
			},
			(error) => {
				let methodOfPayments = this.commandPipeService.methodsOfPayments = []
				let mop = new MethodOfPayment()
				mop.id = 0
				mop.type = { id: 1 }
				mop.label = this.translateService.instant("commant-pipe.mop.cash")
				methodOfPayments.push({ data: mop, component: CashMopComponent })
			},
			() => {
				let methodOfPayments = this.commandPipeService.methodsOfPayments
				let isPaid = this.commandPipeService.isPaid
				// let navCtrl = this.app.getActiveNav()
				if (methodOfPayments && !isPaid){
					console.log('redirect to validation order page');
					this.router.navigate(['tabs/validation-order']);
					// navCtrl.push(ValidationOrderPage)
				} else{
					this.commandPipeService.next(CommandStepMop.id, true)
				} 
			}
		)
	}

	public undo() { this.commandPipeService.isPaid = false }
}