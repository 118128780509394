import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from "rxjs";
import { ApiService } from "./api.service";
import { ApiRequest } from "../../models/apirequest";
import { Constants } from "../../config/constants";
import { MethodOfPayment } from "../../models/method-of-payment";

@Injectable({
  providedIn: 'root'
})
export class MethodOfPaymentService {

  constructor(private apiService: ApiService)  {
        apiService.delegate = this
    }

    public getMethodOfPayments(): Observable<MethodOfPayment[]> {
        let observer = new ReplaySubject<MethodOfPayment[]>()
        const request = new ApiRequest(Constants.URL_METHOD_OF_PAYMENTS, null)

        this.apiService.get(request).subscribe(
            (data) => {
                let mops = []
                for (let i in data) mops.push(new MethodOfPayment(data[i]))
                observer.next(mops)
                observer.complete()
            },
            (err) => {
                observer.error(err)
                observer.complete()
            }
        )

        return observer
    }
}
