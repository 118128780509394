import { Article } from "./article";
import { OptionItem } from "./optionItem";
import { Vat } from "./vat";

export class QuoteItem {
    id: number;
    data_aco: string;
    data_ava: string;
    data_opeco: string;
    vat: Vat;
    amount_et: number;
    amount_vat: number;
    amount_ati: number;
    discount_amount_ati: number;
    total_discount_amount_ati_part: number;
    qty: number;
    price: number;
    article: Article;
    option_item: OptionItem;
    children: QuoteItem[];

    get finalAmountAti(): number {
        let finalAmountAti = this.amount_ati;
        this.children.forEach(item => { finalAmountAti += item.finalAmountAti; });
        return finalAmountAti;
    }

    get finalDiscountAti(): number {
        let finalDiscountAti = this.discount_amount_ati;
        this.children.forEach(item => { finalDiscountAti += item.finalDiscountAti })
        return finalDiscountAti;
    }

    get finalDiscountAtiPart(): number {
        let finalDiscountAtiPart = this.total_discount_amount_ati_part;
        this.children.forEach(item => { finalDiscountAtiPart += item.finalDiscountAtiPart })
        return finalDiscountAtiPart;
    }
    get amountItem () {return this.amount_ati + this.discount_amount_ati + this.total_discount_amount_ati_part}
    get amountItemwithChild() { return this.finalAmountAti + this.finalDiscountAti + this.finalDiscountAtiPart  }
    get amountItemWithChildWithoutPart() { return this.finalAmountAti + this.finalDiscountAtiPart }

    constructor(data) {
        if (typeof data === "undefined" || data === null) {
            this.id = -1;
            this.qty = 0;
            this.price = 0;
            this.option_item = null;
            this.amount_et = 0;
            this.amount_vat = 0;
            this.amount_ati = 0;
            this.discount_amount_ati = 0;
        }
        else {
            this.setData(data);
        }
    }
    public setData(data) {
        if (typeof data === "undefined" || data === null) return;
        this.id = typeof data.id !== "undefined" ? data.id : undefined;
        this.data_aco = typeof data.data_aco !== "undefined" ? data.data_aco : undefined;
        this.data_ava = typeof data.data_ava !== "undefined" ? data.data_ava : undefined;
        this.data_opeco = typeof data.data_opeco !== "undefined" ? data.data_opeco : undefined;
        this.option_item = typeof data.option_item !== "undefined" ? new OptionItem(data.option_item) : null;
        this.qty = typeof data.qty !== "undefined" ? Number(data.qty) : 0;
        this.price = typeof data.price !== "undefined" ? Number(data.price) : 0;
        this.vat = typeof data.vat !== "undefined" ? new Vat(data.vat) : undefined;
        this.amount_et = typeof data.amount_et !== "undefined" ? Number(data.amount_et) : 0;
        this.amount_vat = typeof data.amount_vat !== "undefined" ? Number(data.amount_vat) : 0;
        this.amount_ati = typeof data.amount_ati !== "undefined" ? Number(data.amount_ati) : 0;
        this.discount_amount_ati = typeof data.discount_amount_ati !== "undefined" ? Number(data.discount_amount_ati) : 0;
        this.total_discount_amount_ati_part = typeof data.total_discount_amount_ati_part !== "undefined" ? Number(data.total_discount_amount_ati_part) : 0;
        if(typeof data.article !== "undefined") this.article = new Article(data.article)

        this.addChild(data);
    }

    private addChild(data) {
        this.children = [];
        if(typeof data.children !== "undefined" ) {
            for (let i in data.children) {
                let articleInQuote = new QuoteItem(data.children[i]);
                this.children.push(articleInQuote);

                // note : Lors de la récupération d'un panier avec des options sélectionner, il faut pré-selectionner les optionItems
                // sinon a l'édition de l'article les options items précédemment associés sont supprimés
                //
                // Reselectionne les optionItems des options de l'article courant (article parent).
                //  lorsqu'un optionItems.article == childrenArticle (article enfant) on marque l'optionItem comme selectionné
                // Attention! Il est impossible de distinguer sur quelle option a été sélectionné l'article :
                //   eg. glace 3 boules avec 3 options
                //   saveur 1 : vanille/chocolat/café/citron
                //   saveur 2 : vanille/chocolat/café/citron
                //   saveur 3 : vanille/chocolat/café/citron
                // Les options item sélectionné sur l'article sont uniquement ceux de la premiere option
                let childrenArticle = articleInQuote.article;
                if (this.article && this.article.options) {
                    next: // note: a little bit dirty...
                    for (let option of this.article.options) {
                        let optionItems = option.items;
                        if (optionItems) for (let optionItem of optionItems) {
                            if(articleInQuote.option_item) {
                                if (optionItem.article.id == childrenArticle.id
                                    && optionItem.id == articleInQuote.option_item.id) {
                                        optionItem.isChecked = true;
                                        break next;
                                    }
                            }
                        }
                    }
                }
            }
        }
    }
}
