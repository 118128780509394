import { Injectable } from '@angular/core';

import { Constants } from '../config/constants';
import { StorageData } from '../models/storageData';
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {}

    public static set(key: string, 
        value: string, 
        storageType:string = Constants.TYPE_STORAGE_LOCAL, 
        expireDate: Date = null): void {
        var Storage = this._getStorage(storageType);
      
        var wrappedValue = new StorageData(value, expireDate);
        if(storageType === Constants.TYPE_STORAGE_SESSION) {
            wrappedValue.expireDate = null;
        }

        Storage.setItem(key, wrappedValue.toString());
    }

    /**
     * Retourne la valeur contenue dans `key`, dans le storage `storageType`
     * @param {string} key la clé à récupérer
     * @param {boolean} returnStorageData à true, retourne l'objet wrapper StorageData, qui enveloppe la valeur; à false retourne la valeur directement
     * @param {storageType} storageType le type de storage souhaité (voir StorageService.TYPES)
     * @param {boolean} acceptExpired à false, retourne null si la valeur est expirée; à true retourne la value quoi qu'il en soit
     * @return {null|StorageData|any} selon le paramètre `returnStorageData`, retourne soit un objet StorageData, la valeur stockée ou null si il n'existe pas de donnée à la clé `key` indiquée
     */
    public static get(key: string, returnStorageData: boolean = false, storageType: string = Constants.TYPE_STORAGE_LOCAL, acceptExpired: boolean = false): null|StorageData|any {
        var StoredData = this._getStorageData(key, storageType);
        if(!StoredData) return null;

        if(returnStorageData) return StoredData;

        if(StoredData.isExpired() && !acceptExpired) return null;
        if (StoredData) {
            try { return JSON.parse(StoredData.getValue()) }
            catch(ex) { return StoredData.getValue() }
        }

        return null
    }

    public static has(key: string, storageType: string = Constants.TYPE_STORAGE_LOCAL, acceptExpired: boolean = false): boolean {
        var StoredData = this._getStorageData(key, storageType);
        if(!StoredData || (StoredData.isExpired() && !acceptExpired)) return false;

        return true;
    }

    public static delete(key: string, storageType: string = Constants.TYPE_STORAGE_LOCAL): void {
        var Storage = this._getStorage(storageType);
        Storage.removeItem(key);
    }

    private static _getType(storageType: string): string {
        return Constants.STORAGE_TYPES.indexOf(storageType) === -1 ? Constants.TYPE_STORAGE_LOCAL : storageType;
    }

    private static _getStorage(storageType: string) {
        storageType = this._getType(storageType);

        if(storageType === Constants.TYPE_STORAGE_LOCAL) {
            return localStorage;
        } else if(storageType === Constants.TYPE_STORAGE_SESSION) {
            return sessionStorage;
        }
    }

    private static _getStorageData(key: string, storageType: string = Constants.TYPE_STORAGE_LOCAL): StorageData {
        var Storage = this._getStorage(storageType);

        var item = Storage.getItem(key);
        if(!item) return null;

        var wrappedValue = JSON.parse(item);
        if(!wrappedValue || !wrappedValue.value) return null;

        return new StorageData(wrappedValue.value, wrappedValue.expireDate ? new Date(wrappedValue.expireDate) : null );
    }
}
