import { Injectable, Inject } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { ApiService } from "./api.service";
import { ApiRequest } from "../../models/apirequest";
import { Constants } from "../../config/constants";
import { Language } from "../../models/language";
import { TranslateConfigService } from "../translate-config.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  	public languages: Language[]

    constructor(
    	private apiService: ApiService, 
    	private translateConfig: TranslateConfigService
    )  {
        apiService.delegate = this
    }

    public getLanguages() {
        const observer = new ReplaySubject
        const request = new ApiRequest(Constants.URL_LANGUAGES, null);
        this.apiService.get(request).subscribe(
            data => {
                let isCurrentLocaleValid = false
                const locale = this.translateConfig.locale
                const languages = this.languages = []
                for (let languageData of data) {
                    const language = new Language(languageData)
                    languages.push(language)
                    if (locale && !isCurrentLocaleValid && language.locale == locale) isCurrentLocaleValid = true
                }
                languages.sort((a, b) => a.position - b.position)

                if ((!locale || !isCurrentLocaleValid) && languages.length > 0) this.translateConfig.locale = languages[0].locale
            },
            error => {},
            () => {}
        )
        return observer
    }
}
