import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from "rxjs";
import { TranslateService } from '@ngx-translate/core';

import { AlertService } from '../alert.service';
import { UtilsService } from '../utils.service';
import { StorageService } from '../storage.service';
import { Constants } from '../../config/constants';
import { ApiRequest } from '../../models/apirequest';
import { StorageData } from '../../models/storageData';
import { AuthenticationService } from '../authentication.service';
import { ApiHeaderService } from './api.header.service';
import { LoaderService } from '../loader.service';
import { LogService } from '../log.service';
import { StoreList } from '../../models/store';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    public delegate: any

    constructor(
    	public _Http: HttpClient,
        private apiHeaderService: ApiHeaderService,
        private apiLoaderService: LoaderService,
        private authentificationService: AuthenticationService,
        private alertService: AlertService,
        private translateService: TranslateService,
        private utilsService: UtilsService,
        private logServices: LogService
    ) {


    }

	/* v2: Préparation du code pour mutualiser le code des classes filles */
    //protected abstract get storageType();
    protected get storageType() { return this.delegate && this.delegate.storageType || Constants.TYPE_STORAGE_LOCAL };
    //protected abstract get localStorageKey();
    protected get localStorageKey() { return this.delegate && this.delegate.localStorageKey || Constants.DEFAULT_STORAGE_KEY };
    //protected abstract get expirationDelay();
    protected get expirationDelay() { return this.delegate && this.delegate.expirationDelay || Constants.DEFAULT_STORAGE_EXPIRE };

    protected get storedValue(): any { // Modifier ApiService en ApiService<T> et utiliser T
        return StorageService.get( this.localStorageKey );
    }

    protected get storedData(): StorageData {
        return StorageService.get( this.localStorageKey, true );
    }

    //protected abstract get ...();
    protected get dataPlaceholder() { return this.delegate && this.delegate.dataPlaceholder || null };
    protected get url() { return this.delegate && this.delegate.url || null };

    /**
     * Vérifie si la valeur sauvegardé localement doit être renouvelée
     */
    protected get isStoredValueExpired(): boolean {
        let storedData = this.storedData;
        return !(storedData && !storedData.isExpired());
    }

    //protected abstract buildValueFromData(data);
    protected buildValueFromData(data) { 
        return this.delegate && this.delegate.buildValueFromData ? this.delegate.buildValueFromData(data) : {} 
    };

    /**
    * Enregistre l'objet sous une forme serialisé dans le local storage
    * l'objet doit implémenter la méthode serialize() => string
    * // TODO: créer une interface serializable/storable
    */
    protected storeValue(value: { serialize: () => string }) {
       var expireDate = this.utilsService.addSecondsToDate(new Date(), this.expirationDelay);
       StorageService.set(this.localStorageKey, value.serialize(), this.storageType, expireDate);
    }

    public callApiForObservable(): Observable<any>  {
        var that = this;
        let observer = new ReplaySubject<any>();
        var apiRequest = new ApiRequest<any>(
            this.url,
            null
        );
        function storeData(data): any {
            if (!data) return null;
            let value: any = that.buildValueFromData(data); // use T
            return value;
        }
        this.get(apiRequest).subscribe(
            (data) => {
                console.log('ApiService:ws'); console.log(data)
                observer.next(storeData(data));
            },
            (error) => {
                console.log(that.url);
                console.log(error);
                // Si des données locale sont disponible en remplacement
                // Et si ces données sont utilisable
                let value = storeData(that.dataPlaceholder);
                if (value) {
                    console.log('use fallback ' + that.dataPlaceholder);
                    observer.next(value);
                } else {
                    console.log('no fallback');
                    observer.error(error);
                }
            },
            () => { observer.complete(); });
        return observer;
    }

    public getObservable(): Observable<any> {
        return null;
    }

    protected shouldDisplaySuccessMessage() { return this.delegate && this.delegate.shouldDisplaySuccessMessage ? this.delegate.shouldDisplaySuccessMessage() : false }
    protected shouldDisplayErrorMessage() { return this.delegate && this.delegate.shouldDisplayErrorMessage ? this.delegate.shouldDisplayErrorMessage() : false }
    
    /* Fin: v2 */
    /**
     * Sert à gérer les erreurs de retour d'API
     * @param {string} json le retour de l'API au forma json
     * @return {Object}
     * @throws {Error}
     */
    private readApiResult(json: string): any {
        // @TODO ajouter gestion d'erreur, résultat vide ...
        try {
            var parsed = typeof json === "object" ? json : JSON.parse(json);
        } catch (e) {
            console.error("Erreur avec le retour de l'API !");
            console.debug(json);
            console.debug(parsed);
            console.error(e);
            return e;
        }
        if(parsed) { // TODO: a supprimer, normalement parsed est toujours existant ici (vérifier les retours possible JSON.parse)
            if (parsed.constructor && parsed.constructor.name === "Response") {
                console.log("is Response");
                return parsed;
                //  return new Error("Erreur #"+parsed.status+" (type: "+parsed.type+") : "+parsed.statusText);
            } else {
                if(parsed.code === 200) {
                    console.info('APPEL API 200 OK');
                    return parsed.data;
                } else if(parsed.token) {
                    // cas de connexion et on reçoit seulement un token
                    return parsed;
                } else {
                    return parsed
                }
            }
        }
    }

    /**
     * Construit le string de paramètres (autres que GET) à envoyer pendant l'appel à l'API
     * @param {Object} data les données à envoyer en objet (Object)
     * @return {string}
     */
    private buildParams(request?: ApiRequest<any>): string {
        return request.params = JSON.stringify(request.params);
    }

    /**
     * Traite des informations avant chaque requête vers l'API
     * @param {ApiRequest} request l'objet ApiRequest qui servira à l'appel à l'API
     * @param {Headers} request.headers l'entête de la requête, si fourni, qui recevra des entetes supplémentaires
     * @param {Object} request.params les paramères, sous forme d'object, qu'on enverra à l'API (sauf GET)
     * @return {void}
    */
    private beforeCallApi(request: ApiRequest<any>): void {
        if(request.headers) {
            request.headers = this.apiHeaderService.injectHeaders(request.headers)
        }
    }

    /**
     * Appelle l'API à l'addresse url. Le callback sera executé quand l'appel sera terminé
     * le <TResult> est un typage dynamique qui type le résultat reçu par la réponse
     * @param {ApiRequest<TResult>} request l'objet ApiRequest qui servira à l'appel à l'API
     * @param {string} request.url l'url d'appel à l'API
     * @param {Function} request.successCallback la fonction de callback qui sera appelé à la fin de l'appel à l'API
     * @param {Function} request.errorCallback la fonction de callback qui sera appelé à la fin de l'appel à l'API en cas d'erreur
     * @param {string} request.method méthode d'appel à l'API (GET, POST ...)
     * @param {Object} request.params les paramètres à envoyer pendant l'appel
     * @param {Headers} request.headers les entetes à envoyer pendant l'appel
     * @return {void}
    */
    private callApi<TResult>(request: ApiRequest<TResult>): Observable<any> {
        // this.apiLoaderService.showLoader();
        this.apiLoaderService.present();
        var that = this;
        that.beforeCallApi(request);
        var builtParams = that.buildParams(request);
        var options: any = {
            headers: request.headers,
            observe: 'response'
        };

        var http = that._Http;
        var observerWithMethod = null;

        this.logServices && this.logServices.log(request.url, 'ApiService')
        switch(request.method) {
            case Constants.TYPE_GET: 
                observerWithMethod = http.get(request.url, options); 
                break;
            case Constants.TYPE_DELETE: 
                observerWithMethod = http.delete(request.url, options); 
                break;
            case Constants.TYPE_POST: 
                observerWithMethod = http.post(request.url, builtParams, options); 
                break;
            case Constants.TYPE_PUT: 
                observerWithMethod = http.put(request.url, builtParams, options); 
                break;
            case Constants.TYPE_PATCH: 
                observerWithMethod = http.patch(request.url, builtParams, options); 
                break;
            default: 
                observerWithMethod = http.get(request.url, options); 
                break;
        }
        // Appel l'api avec la bonne method et les HEADER
        // for passing crash, i use a new objserver
        let observer = new ReplaySubject<any>();
        observerWithMethod
            .subscribe(
                response => {
                    this.apiLoaderService.dismiss();
                    this.logServices && this.logServices.log(request.url + ' Success', 'ApiService')
                    let anonymousUserToken = response.headers.get(Constants.HEADER_ANONYMOUS_USER)
                    // console.log(`ApiService header: ${anonymousUserToken} from: ${request.url}`)
                    if (anonymousUserToken){
                        // alert('try to saveinstorage anonymousUserToken');
                        that.authentificationService.saveAnonymousUserTokenInStorage(anonymousUserToken);
                    } 
                    let data = that.readApiResult(response.body)
                    if (this.shouldDisplaySuccessMessage() && data && data['message']){
                    	this.alertService.show({header: data['title'], message: data['message'], buttons: ['ok']});
                    } 
                    observer.next(data);
                },
                (err)=> {
                    this.apiLoaderService.dismiss();
                    
                    var p = that.readApiResult(err);
                    let error = p._body || p // that.readApiResult(typeof p._body !== 'undefined' ? p._body : p);
                    try  {
                        error = JSON.parse(error)
                    } catch(e) {}
                    this.logServices && this.logServices.log({ text: request.url + ' Fail', context: error }, 'ApiService')

                    let message = this.utilsService.getRequestErrorMessage(error);
                    let title = this.utilsService.getRequestErrorMessage(error);
                    const shouldDisplayErrorMessage = this.shouldDisplayErrorMessage() && message
                    if (shouldDisplayErrorMessage) {
                        console.log(error);
                        console.log(message);
                        this.alertService.show({
                            header: title ? title : this.translateService.instant('alert.title.error'),
                            message: message,
                            buttons: [{
                                text: 'ok',
                                cssClass: 'btnvalider',
                                handler: () => { observer.error(error) }
                            }]
                        });
                    } else {
                        observer.error(error);
                    }
                    // console.log('api error: '+JSON.stringify(error));
                },
                () => {
                    // alert(request.url+': api callable');
                    this.apiLoaderService.dismiss();
                    observer.complete()
                }
            );
         return observer;

    }

    /**
     * Raccourci pour callApi par méthode GET
     */
    public get<TResult>(request: ApiRequest<TResult>)  : Observable<any>{
        request.method = Constants.TYPE_GET;
        return this.callApi(request);
    }

    /**
     * Raccourci pour callApi par méthode DELETE
     */
    public delete<TResult>(request: ApiRequest<TResult>) : Observable<any>{
        request.method = Constants.TYPE_DELETE;
        return this.callApi(request);
    }
    /**
     * Raccourci pour callApi par méthode POST
     */
    public post<TResult>(request: ApiRequest<TResult>) : Observable<any>{
        request.method = Constants.TYPE_POST;
        return this.callApi(request);
    }

    /**
     * Raccourci pour callApi par méthode PUT
     */
    public put<TResult>(request: ApiRequest<TResult>) : Observable<any>{
        request.method = Constants.TYPE_PUT;
        return this.callApi(request);
    }

    /**
     * Raccourci pour callApi par méthode PATCH
     */
    public patch<TResult>(request: ApiRequest<TResult>)  : Observable<any>{
        request.method = Constants.TYPE_PATCH;
        return this.callApi(request);
    }

    // This method is created by sajid
    // Bcoz there is another method exist already but there are api url getting by passing
    // whole class in that method
    public callStoreApiForObservable(url): Observable<any>  {
        var that = this;
        let observer = new ReplaySubject<any>();
        var apiRequest = new ApiRequest<any>(
            url,
            null
        );
        function storeData(data): any {
           if (!data) return null;
           let value: any = that.storeBuildValueFromData(data); // use T
           that.storeValue(value);
           console.log(value);
           return value;
           // return data;
        }
        this.get(apiRequest).subscribe(
            (data) => {
                observer.next(storeData(data));
            },
            (error) => {
                // Si des données locale sont disponible en remplacement
                // Et si ces données sont utilisable
                let value = storeData(that.dataPlaceholder);
                if (value) {
                    observer.next(value);
                } else {
                    observer.error(error);
                }
            },
            () => { observer.complete(); });
        return observer;
    }

    protected storeBuildValueFromData(data: any): StoreList {
        return new StoreList( data );
    }
}
/*
*/

