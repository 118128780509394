import { Store } from "./store";
import { PriceLayer } from "./price-layer";
import * as moment from 'moment';

export class PriceLayerGroup {
    public id: number;
    public from_date: string;
    public to_date: string;
    public begin : string;
    public end: string;
    public price_layers: PriceLayer[];
    public store: Store;

    get priceLayerPrice() {
         
        let currentHour = Date.instance()
        let priceLayers = this.price_layers;
        let day =  new Date().getDay() == 0 ? 7: new Date().getDay();
        const priceLayer: PriceLayer = priceLayers.find(
            (priceLayer: PriceLayer) => (priceLayer.day == day) && (moment(Date.instance(moment(currentHour).format('YYYY-MM-DD')+ 'T' + priceLayer.begin.substr(11))).utc().toDate() <= moment(currentHour).utc(true).toDate()) && (moment(Date.instance(moment(currentHour).format('YYYY-MM-DD')+ 'T' +priceLayer.end.substr(11))).utc().toDate() >= moment(currentHour).utc(true).toDate())
        )
        if(priceLayer) return priceLayer.price
        return null
    }
    
    constructor(priceLayerGroup?: PriceLayerGroup) {
        if (priceLayerGroup) {
            this.id = Number(priceLayerGroup.id || 0)
            if(priceLayerGroup.from_date) this.from_date = priceLayerGroup.from_date
            if(priceLayerGroup.to_date) this.to_date = priceLayerGroup.to_date
            if(priceLayerGroup.begin) this.begin = priceLayerGroup.begin
            if(priceLayerGroup.end) this.end = priceLayerGroup.end
            if (priceLayerGroup.store) this.store = new Store(priceLayerGroup.store)
            if(priceLayerGroup.price_layers) {
                this.price_layers = [];
                for (const price_layer of priceLayerGroup.price_layers) {
                    this.price_layers.push( new PriceLayer(price_layer) );
                }
            }
        }
    }
}