import { Conf } from "./conf";

export class PrinterConf implements Conf {

    public device: string = 'printer'
    public logFilename: string
    public pageWidth: number = 576
    public headerImageUrl: string
    public backgroundImageUrl: string
    public footerImageUrl: string

    constructor(printerConf?: PrinterConf) {
        if (printerConf) this.overrideConf(printerConf)
    }

    overrideConf(printerConf: PrinterConf) {
        if (printerConf.device == this.device) {
            if (printerConf.logFilename)        this.logFilename =          printerConf.logFilename
            if (printerConf.pageWidth)          this.pageWidth =            printerConf.pageWidth
            if (printerConf.headerImageUrl)     this.headerImageUrl =       printerConf.headerImageUrl
            if (printerConf.backgroundImageUrl) this.backgroundImageUrl =   printerConf.backgroundImageUrl
            if (printerConf.footerImageUrl)     this.footerImageUrl =       printerConf.footerImageUrl
        }
    }
}