export class AppConfig {


	public static DEBUG = false;

	public static devport_olivier = '8180';
	public static devport_cyrille = '8182';
	public static devport_berengere = '8280';
	public static devport_clement = '8185';
	public static devport = AppConfig.devport_olivier;
	public static arrIndex = 2;
 
	public static usingConf = {
	    TYPES: [
			{
		        PROTOCOL : "http",
		        HOST : 'localhost',
		        MODE : 'localhost',
			},
			{
				PROTOCOL : "http",
		        HOST : '192.168.30.21:' + AppConfig.devport, // dev
		        MODE : 'dev'
			},
			{
		        PROTOCOL : "http",
		        HOST : 'back.app.test.heyou.e-capinfo.fr', // test
		        MODE : 'test'
			},
			{
		        PROTOCOL : "http",
		        HOST : 'back.app.staging.heyou.e-capinfo.fr', // preprod
		        MODE : 'staging'
			},
			{
				PROTOCOL : "http",
		        HOST : 'back.app.heyou.e-capinfo.fr',
		        MODE : 'prod'
			},
			{
				PROTOCOL : "https",
		        HOST : 'back-app.heyou-easylife.fr',
		        MODE : 'prod'
			}
	    ]
	};

	public static param = AppConfig.DEBUG ? "app_dev.php/" : "";
	public static baseUrl = AppConfig.usingConf.TYPES[AppConfig.arrIndex].PROTOCOL + "://" + AppConfig.usingConf.TYPES[AppConfig.arrIndex].HOST + "/";

	public static CONF = {
	    NAME: "conf",
	    APP_VERSION: "2.3.231",
	    MODE: AppConfig.usingConf.TYPES[AppConfig.arrIndex].MODE,

	    BASE_URL: AppConfig.baseUrl,
	    API_URL: AppConfig.baseUrl +  AppConfig.param,

	    API_VERSION: '6',
	    API_KEY: '6RDKEY_368574565',

	    SOCIETY_ID:"4voYjEeF4pnfjjFsSs/IMQ==",
	    GEO_SEARCH_API_KEY: "AIzaSyB7MsdrtR6d806droKJCaDhY19ogjuWvHs"
	};

	public static api = {
		IDLE_SCREENS: 'idle-screens'
	}
	 

}

