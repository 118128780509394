import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { StorageService } from './storage.service';
import { ConfigurationService } from './configuration.service';
import { MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';

import { Observable, ReplaySubject } from "rxjs";

import * as en from '../translations/messages.en'
import * as fr from '../translations/messages.fr'
import * as es from '../translations/messages.es'
import * as de from '../translations/messages.de'
import * as it from '../translations/messages.it'

declare type localized_trads = {[id: string]: {[id:string]:string}}

@Injectable({
	providedIn: 'root'
})

export class TranslateConfigService {

	private static LOCALE_KEY = 'locale';
	public locale: string = '';

    private static LOCALE_KEY_DISPLAY = 'locale_display';
    public locale_display: string = '';

	defaultLanguageByLocales = [
        {
            language_key : '_',
            language: 'en'
        },
        {
            language_key : 'en',
            language: 'en'
        },
        {
            language_key : 'fr',
            language: 'fr'
        },
        {
            language_key : 'es',
            language: 'es'
        },
        {
            language_key : 'de',
            language: 'de'
        },
        {
            language_key : 'it',
            language: 'it'
        }
    ];
     
	constructor(
		private translate: TranslateService,
	) { 
		this.getDefaultLanguage();
	}

	getDefaultLanguage(){
		this.locale = StorageService.get(TranslateConfigService.LOCALE_KEY) || this.translate.getBrowserLang();

        this.locale_display = StorageService.get(TranslateConfigService.LOCALE_KEY_DISPLAY) || this.locale;
        this.setLanguageDisplay(this.locale_display);
		this.setLanguage(this.locale);
		return this.locale;
	}

	setLanguage(locale) {
        const setLocale = this.checkLocaleLanguages(locale);
		this.translate.setDefaultLang(setLocale);
        this.translate.use(setLocale)
		StorageService.set(TranslateConfigService.LOCALE_KEY, setLocale);
	}

    checkLocaleLanguages(locale){
        var languageData = this.defaultLanguageByLocales.find(element => element.language_key === locale);
        if(languageData !== undefined){
            return languageData.language;
        }else{
            return this.translate.getBrowserLang();
        }
    }

    setLanguageDisplay(locale_display) {
        StorageService.set(TranslateConfigService.LOCALE_KEY_DISPLAY, locale_display);
        this.locale_display = locale_display;
    }


}

export function LanguageLoader(http: HttpClient) {
    let languageData = new TranslateHttpLoader(http, '../assets/i18n/', '.json');
    return languageData;
}

// export class MissingTranslation implements MissingTranslationHandler {
//  handle(params: MissingTranslationHandlerParams): string {
//      console.log('MissingTranslationHandler: ', params);
//      return null;
//    }
// }

// @Injectable()
// export class LanguageLoader implements TranslateLoader {

//     private get defaultsLanguageByLocales() {
//         return {
//             '_': {...en.language},
//             'en': {...en.language},
//             'fr': {...fr.language},
//             'es': {...es.language},
//             'de': {...de.language},
//             'it': {...it.language}
//         }
//     }

//     private _languageByLocales: localized_trads = this.defaultsLanguageByLocales

//     public set languageByLocales(languageByLocales: localized_trads) {
//         const currentLanguageByLocales = this._languageByLocales = this.defaultsLanguageByLocales;

//         // Langue par défaut
//         const remoteDefaultLanguage = languageByLocales['_']
//         let defaultLanguage = Object.assign(currentLanguageByLocales['_'], remoteDefaultLanguage)

//         // Merge des traductions de languageByLocales dans la propriété _languageByLocale pour chaque langue
//         // Si la langue n'est pas référencé dans _languageByLocale elle prend la valeur par défaut
//         for (let locale in this.defaultsLanguageByLocales) {
//             const languageByLocale = Object.assign({...remoteDefaultLanguage}, languageByLocales[locale])

//             if (!currentLanguageByLocales[locale]) currentLanguageByLocales[locale] = {...defaultLanguage}
//             Object.assign(currentLanguageByLocales[locale], languageByLocale)
//         }

//         // console.log('I18nLoader::set languageByLocales(...)', this._languageByLocales)
//     }
//     public get languageByLocales (){
//         return this._languageByLocales
//     }
    
//     constructor(
//     	// private configurationService: ConfigurationService
//     ) {
//         this.getTranslation('en');
//     }

//     public getTranslation(lang: string): Observable<any> {
//         // console.log('I18nLoader', `getTranslation(${lang})`)
//         // const debugTranslation = () => {
//         //     if (this.configurationService.platformConf && this.configurationService.platformConf.translationAsKey) {
//         //         const translationsKeys = Object.keys(en.language)
//         //         const translation = {}
//         //         for (let key of translationsKeys) translation[key] = key
//         //         return translation
//         //     }

//         //     return null
//         // }

//         const langTranslation = () => {
//             const languageByLocales = this._languageByLocales
//             const translations = languageByLocales[lang] || languageByLocales['_']
//             // console.log(`I18nLoader::getTranslation(${lang})`, translations)
//             return translations
//         }

//         // const translationProviders = [ debugTranslation, langTranslation ]
//         const translationProviders = [ langTranslation ]

//         const observer = new ReplaySubject
//         let translation
//         for(let translationProvider of translationProviders) 
//             if (translation = translationProvider()) 
//                 break
//         // console.log('I18nLoader', translation)
//         observer.next(translation)
//         return observer
//     }
// }
