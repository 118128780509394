// src/models/user.ts
export class User {
    public id               ?: number
    public username         :  string
    public email            :  string
    public password         ?: string
    public firstName        ?: string
    public lastName         ?: string
    public secondlastname   ?: string
    public cellphone        ?: string
    public gender           ?: string
    public birthdate        ?: Date
    public zipcode          ?: string
    public pseudonyme       ?: string
    public allowEmailOffers ?: boolean
    public phone            ?: string
    public address          ?: string
    public city             ?: string
    public fidelity_number  ?: string
    public allowSMSOffers   ?: boolean
    public createdAt        ?: Date

    constructor(data, update = false, register = false) {
        if (typeof data === "undefined"
        ||  data === null) {
            this.id              = 0
            this.username        = ''
            this.email           = ''
            this.password        = ''
            this.firstName       = ''
            this.lastName        = ''
            this.secondlastname  = ''
            this.cellphone       = ''
            this.gender          = ''
            this.zipcode         = ''
            this.birthdate       = null
            this.pseudonyme      = ''
            this.phone           = ''
            this.address         = ''
            this.city            = ''
            this.fidelity_number = ''
            this.allowSMSOffers  = undefined
            this.createdAt       = null

            if(update || register) {
                this.allowEmailOffers = false
                this.allowSMSOffers   = false
            }
        } else {
            this.setData(data)
        }
    }

    setData(data) {
        this.convert(data)

        this.id             = typeof data.id             !== "undefined" ? data.id             : ''
        this.username       = typeof data.username       !== "undefined" ? data.username       : ''
        this.email          = typeof data.email          !== "undefined" ? data.email          : ''
        this.password       = typeof data.password       !== "undefined" ? data.password       : ''
        this.firstName      = typeof data.firstName      !== "undefined" ? data.firstName      : ''
        this.lastName       = typeof data.lastName       !== "undefined" ? data.lastName       : ''
        this.secondlastname = typeof data.secondlastname !== "undefined" ? data.secondlastname : ''

        // par manque de temps... profile renvoie firstname et register firstName
        if(this.firstName === '' && typeof data.firstname !== "undefined") {
            this.firstName = data.firstname
        }

        // par manque de temps... profile renvoie lastname et register lastname
        if(this.lastName === '' && typeof data.lastname !== "undefined") {
            this.lastName = data.lastname
        }

        if(this.secondlastname === '' && typeof data.secondlastname !== "undefined") {
            this.secondlastname = data.secondlastname
        }

        this.cellphone       = typeof data.cellphone       !== "undefined" ? data.cellphone       : ''
        this.gender          = typeof data.gender          !== "undefined" ? data.gender          : ''
        this.zipcode         = typeof data.zipcode         !== "undefined" ? data.zipcode         : ''
        this.phone           = typeof data.phone           !== "undefined" ? data.phone           : ''
        this.address         = typeof data.address         !== "undefined" ? data.address         : ''
        this.city            = typeof data.city            !== "undefined" ? data.city            : ''
        this.fidelity_number = typeof data.fidelity_number !== "undefined" ? data.fidelity_number : ''
        this.pseudonyme      = typeof data.pseudonyme      !== "undefined" ? data.pseudonyme      : ''

        this.birthdate  = (data.birthdate && typeof data.birthdate !== "undefined")
            ? new Date(data.birthdate)
            : null

        this.allowEmailOffers = typeof data.allowEmailOffers !== "undefined"
            ? data.allowEmailOffers
            : false

        this.allowSMSOffers = typeof data.allowSMSOffers !== "undefined"
            ? data.allowSMSOffers 
            : false
        
        this.createdAt  = (data.createdAt && typeof data.createdAt !== "undefined")
            ? new Date(data.createdAt)
            : null
    }

    private convert(data) {
        if (data.allow_s_m_s_offers) data.allowSMSOffers = data.allow_s_m_s_offers

        if (data.allow_email_offers) data.allowEmailOffers = data.allow_email_offers

        if (data.birthdate) {
            let bd = new String(data.birthdate)

            let result = bd.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)

            if (result && result.length > 0)
                data.birthdate = result[0]
        }
    }

    toStorageString(): string {
        return JSON.stringify(this)
    }

    toLoginData(): AuthUser {
        return new AuthUser({
            username: this.username,
            password: this.password
        })
    }

    toProfileUser(): ProfileUser {
        return new ProfileUser(this)
    }
}

export class AuthUser extends User {
    username: string
    password: string

    constructor(data) {
        super(data)

        this.username = typeof data.username !== "undefined" ? data.username : undefined
        this.password = typeof data.password !== "undefined" ? data.password : undefined
    }
}

abstract class ApiCallUser {
    public gender:          string
    public username:        string
    public firstName?:      string
    public lastName?:       string
    public secondlastname?: string
    public pseudonyme?:     string
    public birthdate?:      { year: number, month: number, day: number }
    public zipcode?:        string
    public cellphone?:      string
    public allowEmailOffers:boolean
    public phone?:          string
    public address?:        string
    public city?:           string
    public fidelity_number?:string
    public allowSMSOffers?: boolean
    public createdAt?:      Date

    constructor(formData) {
        this.gender          = typeof formData.gender          !== null ? formData.gender          : null
        this.username        = typeof formData.username        !== null ? formData.username        : null
        this.firstName       = typeof formData.firstName       !== null ? formData.firstName       : null
        this.lastName        = typeof formData.lastName        !== null ? formData.lastName        : null
        this.secondlastname  = typeof formData.secondlastname  !== null ? formData.secondlastname  : null
        this.pseudonyme      = typeof formData.pseudonyme      !== null ? formData.pseudonyme      : null
        let birthdateString  = typeof formData.birthdate       !== null ? formData.birthdate       : null
        this.zipcode         = typeof formData.zipcode         !== null ? formData.zipcode         : null
        this.cellphone       = typeof formData.cellphone       !== null ? formData.cellphone       : null
        this.phone           = typeof formData.phone           !== null ? formData.phone           : null
        this.address         = typeof formData.address         !== null ? formData.address         : null
        this.city            = typeof formData.city            !== null ? formData.city            : null
        this.fidelity_number = typeof formData.fidelity_number !== null ? formData.fidelity_number : null
        this.createdAt       = typeof formData.createdAt       !== null ? formData.createdAt       : null

        this.allowEmailOffers = typeof formData.allowEmailOffers !== null
            ? formData.allowEmailOffers
            : false

        this.allowSMSOffers = typeof formData.allowSMSOffers !== null
            ? formData.allowSMSOffers
            : false

        if (birthdateString) {
            let date = new Date(birthdateString)

            this.birthdate = {
                year:  date.getFullYear(),
                month: date.getMonth() + 1, // TODO: utilitaire date
                day:   date.getDate()
            }
        }
    }

    public abstract serialize()
}

export class RegistrationUser extends ApiCallUser {
    public email: string;

    public plainPassword?: Object = {
        first:  null,
        second: null
    }

    constructor(formData) {
        super(formData)

        this.email = typeof formData.email !== null
            ? formData.email
            : null

        this.plainPassword = {
            first: typeof formData.password !== null
                ? formData.password
                : null,
            second: typeof formData.passwordconfirm !== null
                ? formData.passwordconfirm
                : null
        }
    }

    serialize() {
        return {
            fos_user_registration_form: this
        }
    }
}

export class ProfileUser extends ApiCallUser {
    serialize() {
        return {
            fos_user_profile_form: this
        }
    }
}
