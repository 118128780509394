
export class Vat {
    public id?: number;
    public label: string;
    public rate: string;
    public alternative_takeaway: Vat;

    //private parent: Vat;
    public delete: boolean = false;
    public update: boolean = false;

    constructor(vat?: Vat, parent?: Vat) {
        this._setData(vat, parent)
    }
    private _setData(vat: Vat, parent?: Vat) {
        if (vat) {
            if (vat.id !== undefined) this.id = Number(vat.id)
            if (vat.label) this.label = vat.label
            if (vat.rate) this.rate = vat.rate
            if (vat.alternative_takeaway) this.alternative_takeaway = new Vat(vat.alternative_takeaway)
        
            // let alreadyInstancedParent
            // if (this.parent = parent)
            //     for (let p = parent; p; p = p.parent)
            //         if (p.id == this.id) { alreadyInstancedParent = p; break }
            // if (vat.alternative_takeaway) this.alternative_takeaway = alreadyInstancedParent ? alreadyInstancedParent : new Vat(vat.alternative_takeaway, this)
        }
    }

    public setData(vat: Vat) { this._setData(vat) }
}


