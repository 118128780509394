import { Conf } from "./conf";
import { Store } from "../models/store";
import { Constants } from "./constants";

export class PlatformConf implements Conf {

    public device: device = 'mobile'
    public hiddenSettingsAccess : boolean = false
    public idleTimeout: number
    public idleExtratime: number
    public store: Store
    public log: {
        enabled: boolean
        keys: string[]
    }

    public translationAsKey: boolean
    public mode: {
        allowAnonymous: boolean,
        articlesPageLayout: articlesPageLayout,
        articleSelectionPageSequence: articleSelectionPageSequence,
        takeBackMode: takeBackMode,
        nicknamePageOrder: nicknamePageOrder,
        quoteFlushMode: quoteFlushMode,
        showTva: boolean,
        showToasterAddQuote: boolean,
        showCart: boolean,
        menuMode: menuMode,
        positionCart: positionCart,
        showStructure: boolean,
        couponFid:boolean,
        priceForShowOnly:boolean,
        saveStoreInStorage:boolean,
        AllowOosProductInfo:boolean,
        positionMenu: positionMenu,
        nbGrid: nbGrid,
        checkCGV: boolean,
        numInternational:boolean
    } = {
        allowAnonymous: true,
        articlesPageLayout: "grid",
        articleSelectionPageSequence: "cart",
        takeBackMode: "scan+type",
        nicknamePageOrder: "before-code",
        quoteFlushMode: "soft",
        showTva: true,
        showToasterAddQuote: false,
        showCart: false,
        menuMode: "menu",
        positionCart: "bottom",
        showStructure: false,
        couponFid:false,
        priceForShowOnly:false,
        saveStoreInStorage:true,
        AllowOosProductInfo:true,
        positionMenu : "top",
        nbGrid : "4",
        checkCGV: false,
        numInternational:true
    }

    constructor(platformConf ?: PlatformConf) {
        if (platformConf) this.overrideConf(platformConf)
    }

    public overrideConf(platformConf: PlatformConf) {
        const device = platformConf.device
        if (!device || device == 'mobile' || device == 'terminal' || device == 'terminal_2') {
            this.device = platformConf.device || 'mobile'
            if(platformConf.hiddenSettingsAccess)   this.hiddenSettingsAccess = platformConf.hiddenSettingsAccess
            if(platformConf.idleTimeout)            this.idleTimeout =          Number(platformConf.idleTimeout)
            if(platformConf.idleExtratime)          this.idleExtratime =        Number(platformConf.idleExtratime)
            if(platformConf.store)                  this.store =                platformConf.store
            if(platformConf.log)                    this.log =                  platformConf.log
            if(platformConf.translationAsKey)       this.translationAsKey =     platformConf.translationAsKey
            const mode = this.mode
            const platformConfMode = platformConf.mode
            if (platformConfMode) {
                if (typeof platformConfMode.allowAnonymous == 'boolean')        Constants.ALLOW_ANONYMOUS =                     mode.allowAnonymous =               platformConfMode.allowAnonymous
                if (platformConfMode.articlesPageLayout)                        Constants.ARTICLES_PAGE_LAYOUT =                mode.articlesPageLayout =           platformConfMode.articlesPageLayout
                if (platformConfMode.articleSelectionPageSequence)              Constants.ARTICLE_SELECTION_PAGE_SEQUENCE =     mode.articleSelectionPageSequence = platformConfMode.articleSelectionPageSequence
                if (platformConfMode.takeBackMode)                              Constants.TAKE_BACK_MODE =                      mode.takeBackMode =                 platformConfMode.takeBackMode
                if (platformConfMode.nicknamePageOrder)                         Constants.NICKNAME_PAGE_ORDER =                 mode.nicknamePageOrder =            platformConfMode.nicknamePageOrder
                if (platformConfMode.quoteFlushMode)                            Constants.QUOTE_FLUSH_MODE =                    mode.quoteFlushMode =               platformConfMode.quoteFlushMode
                if (typeof platformConfMode.showTva == 'boolean')               Constants.SHOW_TVA =                            mode.showTva =                      platformConfMode.showTva
                if (typeof platformConfMode.showToasterAddQuote == 'boolean')   Constants.SHOW_TOASTER_ADD_QUOTE =              mode.showToasterAddQuote =          platformConfMode.showToasterAddQuote
                if (typeof platformConfMode.showCart == 'boolean')              Constants.SHOW_CART =                           mode.showCart =                     platformConfMode.showCart
                if (platformConfMode.menuMode)                                  Constants.MENU_MODE =                           mode.menuMode =                     platformConfMode.menuMode
                if (platformConfMode.positionCart)                              Constants.POSITION_CART =                       mode.positionCart =                 platformConfMode.positionCart
                if (typeof platformConfMode.showStructure == 'boolean')         Constants.SHOW_STRUCTURE =                      mode.showStructure =                platformConfMode.showStructure
                if (typeof platformConfMode.couponFid == 'boolean')             Constants.COUPON_FID =                          mode.couponFid =                    platformConfMode.couponFid
                if (typeof platformConfMode.priceForShowOnly == 'boolean')      Constants.PRICE_FOR_SHOW_ONLY =                 mode.priceForShowOnly =             platformConfMode.priceForShowOnly
                if (typeof platformConfMode.showTva == 'boolean')               Constants.SHOW_TVA =                            mode.showTva =                      platformConfMode.showTva
                if (typeof platformConfMode.saveStoreInStorage == 'boolean')    Constants.SAVE_STORE_IN_STORAGE =               mode.saveStoreInStorage =           platformConfMode.saveStoreInStorage
                if (platformConfMode.positionMenu)                              Constants.POSITION_MENU =                       mode.positionMenu =                 platformConfMode.positionMenu
                if (platformConfMode.nbGrid)                                    Constants.NB_GRID =                             mode.nbGrid =                       platformConfMode.nbGrid
                if (typeof platformConfMode.checkCGV == 'boolean')              Constants.CHECK_CGV =                           mode.checkCGV =                     platformConfMode.checkCGV
              
            }
        }
    }
}