import { Action } from "../std/action";
// import { App } from "ionic-angular";
import { CommandPipeService } from "./command-pipe-service";
// import { QuoteReviewPage } from "../pages/command-pipe/quote-review/quote-review.page";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

export class CommandStepQuoteReview implements Action {
    static readonly id = 'CommandStepQuoteReview'

    constructor(
    	// private app: App, 
        public router: Router,
    	private commandPipeService: CommandPipeService
    ) {}

    public do() {
        const placeType = this.commandPipeService.placeType
        const place = this.commandPipeService.place
        if (place && place.article && placeType.code == 3){
            this.router.navigate(['tabs/quote-review']);
        } 

        else this.commandPipeService.next(CommandStepQuoteReview.id, true)
    }
}
