import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateConfigService } from './translate-config.service';
import { CustomNavResponse } from 'src/app/models/navigation';


@Injectable({
	providedIn: 'root'
})
export class EventsService {

	private currentPathName = new BehaviorSubject('home');
	private currentLocale = new BehaviorSubject(null);
	private currentUser = new BehaviorSubject(null);

	constructor(
		private translateConfig: TranslateConfigService,
	) { 
		// Update Current Locale
		let newLocale = this.currentLocale.getValue();
		newLocale = this.translateConfig.getDefaultLanguage();
		this.currentLocale.next(newLocale);

		// Update Current Path
		let newPathName = this.currentPathName.getValue();
		this.currentPathName.next(newPathName);

		// Update Current Path
		let newUser = this.currentUser.getValue();
		this.currentUser.next(newUser);
	}

	updateLocale(){
  		let newLocale = this.currentLocale.getValue();
		newLocale = this.translateConfig.getDefaultLanguage();
		this.currentLocale.next(newLocale);
  	}

  	getLocale(){
  		return this.currentLocale.getValue();
  	}

  	getLocaleObservable(){
  		return this.currentLocale.asObservable();
  	}

  	updatePathName(pathname){
  		let newPathName = this.currentPathName.getValue();
		newPathName = pathname;
		this.currentPathName.next(newPathName);
  	}

  	getPathName(){
  		return this.currentPathName.getValue();
  	}

  	getPathNameObservable(){
  		return this.currentPathName.asObservable();
  	}

  	updateUser(){
  		let newUser = this.currentUser.getValue();
		this.currentUser.next(newUser);
  	}

  	getUser(){
  		return this.currentUser.getValue();
  	}

  	getUserObservable(){
  		return this.currentUser.asObservable();
  	}
  	
}


export class NavigationEventsService {

	private navigationItem: CustomNavResponse = null;
	private currentNavigationItem: BehaviorSubject<CustomNavResponse> = new BehaviorSubject<CustomNavResponse>(this.navigationItem);

	constructor() {}

	updateNavigationItem(item: CustomNavResponse){
		this.navigationItem = item;
		this.currentNavigationItem.next(item);
  	}

  	getNavigationItem(){
		this.navigationItem = this.currentNavigationItem.getValue();
  		return this.navigationItem;
  	}

  	getNavigationItemObservable(){
  		return this.currentNavigationItem.asObservable();
  	}

}
