export class Price {
    price: number;
    price_per_unit: number;
    store: {id: number};
    
    constructor(data) {
        this.price = typeof data.price !== "undefined" ? data.price : undefined;
        this.price_per_unit = typeof data.price_per_unit !== "undefined" ? data.price_per_unit : undefined;
        this.store = typeof data.store !== "undefined" ? data.store : undefined;
    }

    getStoreId(): number {
        return this.store.id;
    }
}