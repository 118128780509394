import { Injectable } from '@angular/core';
import { Observable, ReplaySubject }  from 'rxjs';

import { Constants } from '../../config/constants';
import { ApiService } from './api.service';
import { ApiHeaderService,StoreIdHeaderContainer } from './api.header.service';
import { NavigationService } from './navigation.service';

import { StorageService } from '../storage.service';
import { StorageData } from '../../models/storageData';
import { Store } from '../../models/store';
import { StoreList } from '../../models/store';
import { TranslationsService } from './translations.service';
import { LoadNavigationService } from '../load-navigation.service';

@Injectable({
	providedIn: 'root'
})
export class StoreService {

	private currentStore: Store;
	// apiHeaderService devrait pouvoir être injecté sous l'interface StoreIdHeaderContainer mais ca ne fonctionne pas
	private storeIdHeaderContainer: StoreIdHeaderContainer;

	constructor(
		private apiService: ApiService,
		private navigationService: NavigationService,
		private translationsService: TranslationsService,
		private apiHeaderService: ApiHeaderService,
		private loadNavigationService: LoadNavigationService
   ) {
		this.storeIdHeaderContainer = apiHeaderService;
		this.apiService.delegate = this;
	}

	protected get url() { return Constants.URL_STORES };

	public get saveStore() {
		return Constants.SAVE_STORE_IN_STORAGE
	}

	protected buildValueFromData(data: any): StoreList {
		return new StoreList( data );
	}

	public getStoreList() : Observable<StoreList> {
		const observer = new ReplaySubject<StoreList>()
		let that=this;
		// I use new method instead of existing
		// this.apiService.callApiForObservable().subscribe(
		this.apiService.callStoreApiForObservable(this.url).subscribe(
			(data: StoreList) => {
				observer.next(data)
				const currentStore = this.getCurrentStore()
				if (currentStore) {
					let deleteCurrentStore = true;
					for (let store of data.children) {
						if (store.id == currentStore.id) { 
							// currentStore.id = 0
							// that.saveStoreInStorage(currentStore);
							that.saveStoreInStorage(store);
							deleteCurrentStore = false; break 
						}
					}
					if (deleteCurrentStore) this.deleteStoreFromStorage()
				}
			},
			(error) => observer.error(error),
			() => observer.complete()
		)

		return observer
	}

	// @TODO: sortir ces méthodes dans un service a part
	private getStoreInStorage(): StorageData {
		return StorageService.get(Constants.STORAGE_KEY_STORE);
	}

	public deleteStoreFromStorage() {
		this.currentStore = null
		this.storeIdHeaderContainer.storeId = null
		StorageService.delete(Constants.STORAGE_KEY_STORE, Constants.TYPE_STORAGE_LOCAL);
	}

	public saveStoreInStorage(_store: Store): void {
		if (_store.isEqual(this.currentStore)) return

		this.currentStore = _store;
		this.storeIdHeaderContainer.storeId = _store.id

		if(this.saveStore){
			// sauvegarde le store choisi par le client dans le local storage
			StorageService.set(Constants.STORAGE_KEY_STORE, _store.toStorageString(), Constants.TYPE_STORAGE_LOCAL);
		}

		this.loadNavigationService.getNavigation(0);
		this.translationsService.getTranslations().subscribe(data => {}, error => {})
	}

	public getCurrentStore() {
		let currentStore = this.currentStore;
		if (currentStore) return currentStore;

		let storedData = this.getStoreInStorage()
		if(storedData) this.storeIdHeaderContainer.storeId = (this.currentStore = new Store(storedData)).id

		return null;
	}

	public setStoreFromExternLink(storeId) {
		const observer = new ReplaySubject<StoreList>()
		// I use new method instead of existing
		// this.apiService.callApiForObservable().subscribe(
		this.apiService.callStoreApiForObservable(this.url).subscribe(
			(data: StoreList) => {
				for (let store of data.children) {
					if (store.id == storeId) { 
						this.saveStoreInStorage(store);
						break }
				}
				observer.next(data)
			}
		)
		return observer
	}

}
