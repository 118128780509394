import { ArticleAttribute } from './article-attribute';
import { Value } from './value';

export class Attribute {
    public id?: number;
    public _label: string;
    public get defaultLabel() { return this._label }
    public set defaultLabel(label: string) { this._label = label }
    public get label() { return localize(this.i18nLabel, this._label) }
    public set label(label: string) { this._label = label }
    public i18nLabel: i18nString;
    public mode: string;
    public displayable: boolean;
    public filterable: boolean;
    public sortable: boolean;
    public value: string;
    public values?: Value[] = null;
    public article_attribute: ArticleAttribute;

    public position: number

    constructor(data) {
        this.id = typeof data.id !== "undefined" ? data.id : undefined;
        this._label = typeof data.label !== "undefined" ? data.label : undefined;
        this.i18nLabel = typeof data.i18n_label !== "undefined" ? data.i18n_label : data.i18nLabel;
        this.position = typeof data.position !== "undefined" ? data.position : undefined;
        this.value = typeof data.value !== "undefined" ? data.value : undefined;
        this.mode = typeof data.mode !== "undefined" ? data.mode : undefined;
        this.displayable = typeof data.displayable !== "undefined" ? data.displayable : undefined;
        this.filterable = typeof data.attribute !== "undefined" ? data.attribute : undefined;
        this.sortable = typeof data.sortable !== "undefined" ? data.sortable : undefined;
        this.values = typeof data.values !== "undefined" ? data.values : undefined;
        this.article_attribute = typeof data.article_attribute !== "undefined" ? data.article_attribute : undefined;
    }
}