// std
import { Action } from "../std/action";

// Services
import { CommandPipeService } from "./command-pipe-service";

export class CommandPipeBuilder {
    constructor(private commandPipeService: CommandPipeService) {}

    public addStep(id: string, action: Action) {
        this.commandPipeService.addStep(id, action)
    }

    public setSteps(steps: {[id:string]:Action}) {
        for (let id in steps) this.addStep(id, steps[id])
    }
}