// import { App } from "ionic-angular";

// std
import { Action } from "../std/action";

// Services
import { CommandPipeService } from "./command-pipe-service";
// import { NicknamePage } from "../../pages/command-pipe/nickname/nickname.page";
import { Quote } from "../models/quote";
import { QuoteService } from "../services/api/quote.service";
import { UserService } from "../services/api/user.service";
import { PlatformConf } from "../config/platform-conf";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

export class CommandStepNickname implements Action {
    static readonly id = 'CommandStepNickname'

    constructor(
        // private app: App,
        public router: Router,
        private commandPipeService: CommandPipeService,
        private quoteService: QuoteService,
        private userService: UserService,
        private platformConf: PlatformConf
    ) {}

    private getQuote() {
        return this.commandPipeService.order || this.commandPipeService.quote
    }

    public do() {
        if (!this.getQuote().pseudo) {
            // L'autofocus d'un input ne fonctionne pas sans l'option { keyboardClose: false }
            // qui ferme le clavier après la transition de page...
            const navigationExtras: NavigationExtras = {
                state: {
                    order: this.commandPipeService.order,
                    keyboardClose: false
                }
            };
            this.router.navigate(['tabs/nickname'], navigationExtras);
            // comment by sajid and replace by router
            // this.app.getActiveNav().push(NicknamePage, { order: this.commandPipeService.order }, { keyboardClose: false }) 
            // order et non quote car order détermine si le pseudo doit être défini sur order ou quote
        } else this.commandPipeService.next(CommandStepNickname.id, true)
    }

    public undo() {
        const quote = this.getQuote();
        if (quote) {
            quote.pseudo = null
            // note: n'est disponible que sur le panier
            if (quote instanceof Quote) this.quoteService.removeNickname()

            if(this.platformConf.device == 'terminal') this.userService.nickname = null
        }
    }
}