import { Article } from "./article";
export class Category {
    public id: number;
    private i18nLabel: i18nString
    private _label: string
    public get label() { return localize(this.i18nLabel, this._label) }
    private i18nDescription: i18nString
    private _description: string
    public get description() { return localize(this.i18nDescription, this._description) }
    public selectable_qty: number;
    public img: string;
    public articles: Article[] = undefined;
    public position:number;

    constructor(data) { this.setData(data) }

    public setData(data) {
      this.id = typeof data.id !== "undefined" ? data.id : undefined;
      this._label = typeof data.label !== "undefined" ? data.label : data.i18nLabel;
      this.selectable_qty = typeof data.selectable_qty !== "undefined" ? Number(data.selectable_qty) : 1;
      this.img = typeof data.img !== "undefined" ? data.img : undefined;
      this.position = typeof data.position !== "undefined" ? Number(data.position) : 0;
      if(typeof data.articles !== "undefined" && data.articles.length > 0 ) {
          this.articles = [];
          for (let i in data.articles) {
            let wrapperArticleData = data.articles[i];
            let articleData = null;
            if (wrapperArticleData) articleData = wrapperArticleData.article
            if (articleData || wrapperArticleData) {
              let article = new Article(articleData ? articleData : wrapperArticleData)
              article.categoryId = this.id;
              // note: par cohérence avec les NavigationItem et CategoryNav
              //  l'article devrait être englobé dans un positionnable.
              //  par soucis de simplicité on défini directement sa position
              article.supplement = wrapperArticleData.supplement;
              article.position = Number(wrapperArticleData.position);

              this.articles.push(article);
            }
        }
      }
    }
}

/**
 * La classe CategoryNav est un artifice pour gérer les sous catégories avec les contraintes imposée par les WS
 */
export class CategoryNav extends Category {
    public category: { id: number };
    // note: il n'est pas possible de définir de valeur par défaut pour children
    //  car children = undefined est réalisé (une fois transpilé) dans le constructeur
    //  APRES l'appel a super
    // public children: CategoryNav[] = undefined;
    public children: CategoryNav[];
    public position: number;

    private _parent: CategoryNav;
    public get parent(): CategoryNav { return this._parent }

    constructor(data) { super(data) }

    public setData(data) {
      this.position = typeof data.position !== "undefined" ? Number(data.position) : undefined;
      this.category = typeof data.category !== "undefined" ? data.category : undefined;

      if(typeof data.children !== "undefined" && data.children.length > 0 ) {
        let childrenData = data.children;
        let children = [];
        // note: for..in au cas ou le ws retourne un objet au lieu d'un tableau
        // eg. format du json retourné par le ws avec A, B et C des objets
        // [ 0 => A, 1 => B, 2 => C ] => [ A, B, C ]
        // [ 0 => A, 2 => B, 3 => C ] => { 0 => A, 1 => B, 2 => C }
        for (let i in childrenData) {
          let child = childrenData[i];
          let subcategory = new CategoryNav(child);
          subcategory._parent = this;
          children.push(subcategory);
        }
        this.children = children;
      }

      super.setData(data)
    }
}
