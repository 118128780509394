import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ModalController, AlertController, NavParams, NavController, } from '@ionic/angular';
import { TITLE_ANIMATION } from '../../animations/animations';

@Component({
  selector: 'app-idle',
  templateUrl: './idle.page.html',
  styleUrls: ['./idle.page.scss'],
})
export class IdlePage implements OnInit {

  @Input() html: string;

  constructor(
    public modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  @HostListener('click')
  @HostListener('touch')

  public didTouchBack() { 
    this.modalController.dismiss({
      'dismissed': true,
    });
  }

}
