import { Store } from "./store";

export class PriceLayer {
    public id: number;
    public from_date: string;
    public to_date: string;
    public begin : string;
    public end: string;
    public day: number;
    public price: number;
    public store: Store;

    constructor(priceLayer?: PriceLayer) {
        if (priceLayer) {
            this.id = Number(priceLayer.id || 0)
            if(priceLayer.from_date) this.from_date = priceLayer.from_date
            if(priceLayer.to_date) this.to_date = priceLayer.to_date
            if(priceLayer.begin) this.begin = priceLayer.begin
            if(priceLayer.end) this.end = priceLayer.end
            if(priceLayer.day) this.day = Number(priceLayer.day)
            this.price = Number(priceLayer.price)
            if (priceLayer.store) this.store = new Store(priceLayer.store)
            }
    }
}