/**
 * Maillon de chaîne utile pour former une liste chainée
 */
export class Link<T> {
    public element: T;
    public next?: Link<T>;
    public prev?: Link<T>;

    static buildList<T>(elements: T[]) {
        if (!elements) return;

        // On inverse la liste car les maillons de la liste chainée
        //  sont inséré depuis la fin vers le début
        let elementsReversed = elements.slice().reverse();
        let elementLink = null
        for (let element of elementsReversed) {
            let newElementLink = new Link<T>();
            newElementLink.next = elementLink;
            if (elementLink) elementLink.prev = newElementLink;
            newElementLink.element = element;
            elementLink = newElementLink;
        }

        return elementLink;
    }
}