import { Store } from "./store";
import { ArticleOffer } from "./article-offer";
import * as moment from 'moment';

export class ArticleOfferGroup {
    public id: number;
    public from_date: string;
    public to_date: string;
    public begin : string;
    public end: string;
    public article_offers: ArticleOffer[];
    public store: Store;
    public i18nLabel1 : i18nString
    public _label1 : string
    public i18nLabel2 : i18nString
    public _label2 : string

    public get label1() { return localize(this.i18nLabel1, this._label1) }
    public get label2() { return localize(this.i18nLabel2, this._label2) }
    public set label1(label1: string) { this._label1 = label1 }
    public set label2(label2: string) { this._label2 = label2 }

    get articleOfferPrice() {
         
        let currentHour = Date.instance()
        let articleOffers = this.article_offers;
        let day =  new Date().getDay() == 0 ? 7: new Date().getDay();
        const articleOffer: ArticleOffer = articleOffers.find(
            (articleOffer: ArticleOffer) => 
                (articleOffer.day == day) && (moment(Date.instance(moment(currentHour).format('YYYY-MM-DD')+ 'T' + articleOffer.begin.substr(11))).utc().toDate() <= moment(currentHour).utc(true).toDate()) && (moment(Date.instance(moment(currentHour).format('YYYY-MM-DD')+ 'T' +articleOffer.end.substr(11))).utc().toDate() >= moment(currentHour).utc(true).toDate()) 
        
        )
        if(articleOffer) return articleOffer.price
        else if(articleOffers[0].price) return articleOffers[0].price
        return null
    }

    get articleOfferLabel1() {
         
        let currentHour = Date.instance()
        let articleOffers = this.article_offers;
        let day =  new Date().getDay() == 0 ? 7: new Date().getDay();
        const articleOffer: ArticleOffer = articleOffers.find(
            (articleOffer: ArticleOffer) => (articleOffer.day == day) && (moment(Date.instance(moment(currentHour).format('YYYY-MM-DD')+ 'T' + articleOffer.begin.substr(11))).utc().toDate() <= moment(currentHour).utc(true).toDate()) && (moment(Date.instance(moment(currentHour).format('YYYY-MM-DD')+ 'T' +articleOffer.end.substr(11))).utc().toDate() >= moment(currentHour).utc(true).toDate())
        )
        if(articleOffer) return articleOffer.label1
        else if(articleOffers[0].label1) return articleOffers[0].label1
        return null
    }

    get articleOfferLabel2() {
         
        let currentHour = Date.instance()
        let articleOffers = this.article_offers;
        let day =  new Date().getDay() == 0 ? 7: new Date().getDay();
        const articleOffer: ArticleOffer = articleOffers.find(
            (articleOffer: ArticleOffer) => (articleOffer.day == day) && (moment(Date.instance(moment(currentHour).format('YYYY-MM-DD')+ 'T' + articleOffer.begin.substr(11))).utc().toDate() <= moment(currentHour).utc(true).toDate()) && (moment(Date.instance(moment(currentHour).format('YYYY-MM-DD')+ 'T' +articleOffer.end.substr(11))).utc().toDate() >= moment(currentHour).utc(true).toDate())
        )
        if(articleOffer) return articleOffer.label2
        else if(articleOffers[0].label2) return articleOffers[0].label2
        return null
    }
    
    constructor(articleOfferGroup?: ArticleOfferGroup) {
        if (articleOfferGroup) {
            this.id = Number(articleOfferGroup.id || 0)
            if(articleOfferGroup.from_date) this.from_date = articleOfferGroup.from_date
            if(articleOfferGroup.to_date) this.to_date = articleOfferGroup.to_date
            if(articleOfferGroup.begin) this.begin = articleOfferGroup.begin
            if(articleOfferGroup.end) this.end = articleOfferGroup.end
            if(articleOfferGroup.store) this.store = new Store(articleOfferGroup.store)
            if(articleOfferGroup.article_offers) {
                this.article_offers = [];
                for (const article_offer of articleOfferGroup.article_offers) {
                    this.article_offers.push( new ArticleOffer(article_offer) );
                }
            }
            if(articleOfferGroup.label1) this._label1 = this._setData(articleOfferGroup.label1, undefined)
            if(articleOfferGroup.label2) this._label2 = this._setData(articleOfferGroup.label2, undefined)        
            if(articleOfferGroup.i18nLabel1) this.i18nLabel1 = articleOfferGroup.i18nLabel1            
            if(articleOfferGroup.i18nLabel2) this.i18nLabel2 = articleOfferGroup.i18nLabel2
        }
        
    }

    private _setData(field, value = undefined) {
        const result = (typeof field !== "undefined")
            ? field
            : value

        return result
    }
}