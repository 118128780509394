import { Injectable } from "@angular/core";
import { FileService } from "./file.service";
import { ConfigurationService } from "./configuration.service";

export class LogMessage {
    public text?: string
    public context: any
}

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private configurationService: ConfigurationService, private fileService: FileService) {}

  public log(message: string|LogMessage, key?: string) {
      const logConf = this.configurationService.platformConf.log
      let enabled = false
      let keys = null
      if (logConf) {
          enabled = logConf.enabled
          keys = logConf.keys
      }
      if (!enabled) return
      if (keys && key && keys.indexOf(key) < 0) return

      let context: string
      let text: string
      if (message instanceof LogMessage) {
          if (message.text) text = message.text
          try { context = JSON.stringify(message.context) }
          catch (e) { context = 'cannot convert message to string' }
      } else text = message

      const log = `${new Date().toISOString()}${key ? ` [${key}]` : '' } : ${text ? text : ''}${context ? `\n${context}` : ''}\n`

      this.fileService.writeIntoFile('log', log, true)
  }
}
