import { Injectable } from '@angular/core';

import { Constants } from '../config/constants';
import { UtilsService } from './utils.service';
import { StorageService } from './storage.service';
import { FileService } from './file.service';
import { StorageData } from '../models/storageData';
import { User } from '../models/user';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    
    public anonymousToken: string;

    constructor(
    	private fileService: FileService,
        private utilsService: UtilsService
    ) {}

    public getUserTokenInStorage(): StorageData {
        return StorageService.get(Constants.STORAGE_KEY_USER_TOKEN, false, null, false);
    }

    public saveUserTokenInStorage(token: string): void {
        var expireDate = this.utilsService.addSecondsToDate(new Date(), Constants.FIVE_YEARS_EXPIRE);
        StorageService.set(Constants.STORAGE_KEY_USER_TOKEN, token, Constants.TYPE_STORAGE_LOCAL, expireDate);
    }

    public deleteUserInStorage(): void {
        StorageService.delete(Constants.STORAGE_KEY_USER);
        StorageService.delete(Constants.STORAGE_KEY_USER_TOKEN);
    }

    public getUserInStorage(): StorageData {
        return StorageService.get(Constants.STORAGE_KEY_USER, false, null, false);
    }

    public saveUserInStorage(user: User): void {
        var expireDate = this.utilsService.addSecondsToDate(new Date(), Constants.FIVE_YEARS_EXPIRE);
        StorageService.set(Constants.STORAGE_KEY_USER, user.toStorageString(), Constants.TYPE_STORAGE_LOCAL, expireDate);
    }

    public getAnonymousUserTokenInStorage(): string {
        // alert(`AuthenticationService getAnonymousUserTokenInStorage: ` + StorageService.get(Constants.STORAGE_KEY_ANONYMOUS_USER_TOKEN, false, null, false))
        return StorageService.get(Constants.STORAGE_KEY_ANONYMOUS_USER_TOKEN, false, null, false);
    }

    public saveAnonymousUserTokenInStorage(token: string) {
        // alert(`AuthenticationService saveAnonymousUserTokenInStorage(${token})`);
        if (this.anonymousToken != token){
            this.writeTokenIntoConf(this.anonymousToken = token);
            console.log('write token into conf');
        } 
        var expireDate = this.utilsService.addSecondsToDate(new Date(), Constants.FIVE_YEARS_EXPIRE);
        StorageService.set(Constants.STORAGE_KEY_ANONYMOUS_USER_TOKEN, token, Constants.TYPE_STORAGE_LOCAL, expireDate);
    }

    public readTokenFromConf(): Observable<string> {
        const that = this
        let observer = new ReplaySubject<string>()
        this.anonymousToken = this.getAnonymousUserTokenInStorage()
        this.fileService && this.fileService.readFromFile(Constants.FILE_TOKEN).subscribe(
            (data) => {
                if (data) {
                    console.log(`AuthenticationService readTokenFromConf() with data: ${data}`)
                    that.saveAnonymousUserTokenInStorage(this.anonymousToken = data);
                    observer.next(data)
                } else {
                    console.log(`AuthenticationService readTokenFromConf() but file empty`)
                    observer.error(null)
                }
            },
            (error) => {
                console.log(`AuthenticationService readTokenFromConf() cannot read file`)
                observer.error(null)
            }
        );
        return observer
    }

    public writeTokenIntoConf(token: string) : any {
        // Comment by sajid
        // this.fileService && this.fileService.writeIntoFile(Constants.FILE_TOKEN, token, false)
        this.anonymousToken = token
    }
}
