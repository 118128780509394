import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, ToastController } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpModule, Http } from '@angular/http';
import { Printer } from '@ionic-native/printer';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { SplashScreen } from '@ionic-native/splash-screen';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { File } from '@ionic-native/file/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { QRCodeModule } from 'angularx-qrcode';


// Language translation imports
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageLoader } from './services/translate-config.service';
import { TranslateConfigService } from './services/translate-config.service';
import { CommandPipeService } from './command-pipe/command-pipe-service';

import { FilePrint } from './print-strategies/file-print';
import { PostPrint } from './print-strategies/post-print';

// import { CardMopComponent } from './pages/command-pipe/validation-order/components/card-mop/card-mop.component';
// import { CashMopComponent } from './pages/command-pipe/validation-order/components/cash-mop/cash-mop.component';
// import { EtpMopComponent } from './pages/command-pipe/validation-order/components/etp-mop/etp-mop.component';
// import { EmbededMopComponent } from "./pages/command-pipe/validation-order/components/embeded-mop/embeded-mop.component";

 
// Directives
import { DirectiveModule } from './directives/directive.module';

// import { CapinfoModule } from './capinfo/capinfo.module';
// import { ComponentModule } from './components/component.module';


import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr, 'fr');

@NgModule({
	declarations: [
		AppComponent,
		// CardMopComponent,
		// CashMopComponent,
		// EtpMopComponent,
		// EmbededMopComponent
	],
	entryComponents: [
		// CardMopComponent,
		// CashMopComponent,
		// EtpMopComponent,
		// EmbededMopComponent
	],
	imports: [
		BrowserModule, 
		HttpClientModule,
		IonicModule.forRoot(), 
		AppRoutingModule,
		// Language translation module
		TranslateModule.forRoot({
	      loader: {
	        provide: TranslateLoader,
	        useFactory: (LanguageLoader),
	        deps: [HttpClient],
	      }
	    }),
		HttpModule,
		HttpClientModule,
		BrowserAnimationsModule,
		DirectiveModule,
		QRCodeModule,
		// CapinfoModule,
		// ComponentModule
	],
	exports: [
  	],
  	providers: [
	  	{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	  	// Language translation service
  	   	TranslateConfigService,
  	   	File,
  	   	Geolocation,
  	   	AndroidPermissions,
  	   	CommandPipeService,
  	   	QRScanner,
  	   	FilePrint,
  	   	PostPrint,
   	],
  bootstrap: [AppComponent],
})
export class AppModule {}
