import { Timeslot } from "./timeslot";
import { Peculiarity } from "./peculiarity";

export class StoreList {
    public children: Store[];

    /**
     * @param {any} data objet pour hydratation du StoreList
     */
    constructor(data) {
        if (data && data.children) data = data.children;
        this.children = (typeof data !== "undefined" && data !== null) ? this.hydrate(data) : [];
    }

    /**
     * @param {any[]} children tableau de Store sous forme d'objet/tableau.
     * @returns {Store[]}
     */
    hydrate(children: any[]): Store[] {
        let arrStore = [];
        for(let i in children) {

            let _child = children[i];
            arrStore.push( new Store(_child) );
        }

        return arrStore;
    }

    serialize(): string { return JSON.stringify(this) }
}


export type leafletLayer = {
    type: string,
    features: any[]
}
export class Store {
    public id: number;
    public name: string;
    public address: string;
    public zipcode: string;
    public city: string;
    public latitude: number;
    public longitude: number;
    public phone: string;
    public drive: boolean;
    public time_slots: Timeslot[];
    public peculiarities: Peculiarity[];
    public shipping_area: leafletLayer;
    public shipping_zipcode: string;

    constructor(data) {
        this.id = typeof data.id !== "undefined" ? data.id : undefined;
        this.name = typeof data.name !== "undefined" ? data.name : undefined;
        this.address = typeof data.address !== "undefined" ? data.address :undefined;
        this.zipcode = typeof data.zipcode !== "undefined" ? data.zipcode :undefined;
        this.city = typeof data.city !== "undefined" ? data.city :undefined;
        this.latitude = typeof data.latitude !== "undefined" ? Number(data.latitude): undefined;
        this.longitude = typeof data.longitude !== "undefined" ? Number(data.longitude) : undefined;

        this.phone = typeof data.phone !== "undefined" ? data.phone : undefined;

        const time_slots = this.time_slots = []
        const timeslotsData = data.time_slots
        if (timeslotsData) {
            for (const timeslotData of timeslotsData) {
                time_slots.push(new Timeslot(timeslotData))
            }
        }

        const peculiarities = this.peculiarities = []
        const peculiaritiesData = data.peculiarities
        if (peculiaritiesData) {
            for (const peculiarityData of peculiaritiesData) {
                peculiarities.push(new Peculiarity(peculiarityData))
            }
        }

        if (data.shipping_area) this.shipping_area = !(data.shipping_area instanceof Object) ? JSON.parse(data.shipping_area) : data.shipping_area
        if (data.shipping_zipcode) this.shipping_zipcode = data.shipping_zipcode
    }

    isEqual(store: Store) { return store && store.id == this.id }

    toStorageString(): string {
        return JSON.stringify(this);
    }
}
