import { Injectable } from "@angular/core";
import { QuoteService } from "../services/api/quote.service";
import { PrintStrategy } from "./print-strategy";
import { Observable } from "rxjs";

@Injectable()
export class PostPrint implements PrintStrategy<string> {
    public get id() { return 'PostPrint' }
    constructor(private quoteService: QuoteService) {}

    public print(data: string): Observable<string> { return this.quoteService.updateTicketInfo(data) }
}