import { QuoteService } from "../../../../services/api/quote.service";
import { AlertService } from "../../../../services/alert.service";
import { CommandPipeService } from "../../../../command-pipe/command-pipe-service";
import { Order } from "../../../../models/order";
import { CommandStepMop } from "../../../../command-pipe/command-step-mop";
import { UtilsService } from "../../../../services/utils.service";
import { Action } from "../../../../std/action";
import { TranslateService } from '@ngx-translate/core';

export class ValidateOrderAction implements Action {
    constructor(
        private quoteService: QuoteService,
        private commandPipeService: CommandPipeService,
        private utilsService: UtilsService,
        private translateService: TranslateService,
        private alertService: AlertService
    ) {}

    // Alerte simple avec bouton OK
    private presentErrorAlert(fallbackMessage: string, error: { message: string } = null) {
        let message = this.utilsService.getErrorMessage(error);
        this.presentAlert(this.translateService.instant('alert.title.error'), message ? message : fallbackMessage); 
    }
    private presentAlert(title: string, message: string) {
        this.alertService.show({
            cssClass:'alert-margintop11',
            header: title,
            message: message,
            buttons: [{
                text: 'ok',
                cssClass: 'btnvalider'
            }]
        })
    }

    public do() {
        var that = this;
        this.quoteService.validateQuote().subscribe((data) => {
            let commandPipeService = this.commandPipeService
            commandPipeService.isPaid = true
            commandPipeService.order = new Order(data.Order)
            commandPipeService.next(CommandStepMop.id)
        }, (error) => {
            that.presentErrorAlert(this.translateService.instant('validation-order.error-alert'), error);
        });
    }
}