import { Action } from "../std/action";
// import { App } from "ionic-angular";
import { CommandPipeService } from "./command-pipe-service";
import { PlaceService } from "../services/api/place.service";
import { QuoteService } from "../services/api/quote.service";
// import { PickUpPlacePage } from "../pages/command-pipe/pick-up-place/pick-up-place.page";
import { UserService } from "../services/api/user.service";
import { AlertService } from "../services/alert.service";
import { UtilsService } from "../services/utils.service";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../config/constants";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

export class CommandStepPickUpPlace implements Action {
    static readonly id = 'CommandStepPickUpPlace'

    constructor(
        // private app: App,
        public router: Router,
        private commandPipeService: CommandPipeService,
        private placeService: PlaceService,
        private userService: UserService,
        private quoteService: QuoteService,
        private utilsService: UtilsService,
        private translateService: TranslateService,
        private alertService: AlertService, 
        private errorAction?: () => void
        ) {}

    public do() {
        const placeType = this.commandPipeService.placeType
        const place = this.commandPipeService.place
        if (!place) {
            if (placeType && (placeType.code == 1 || placeType.code == 2) || ((!placeType || placeType.code == 0 ) && Constants.TAKE_BACK_MODE == "list")) {
                this.placeService.getPlaces(placeType.code).subscribe(
                    places => {
                        if (places.length > 1) {
                            const navigationExtras: NavigationExtras = {
                                state: {
                                    places: places
                                }
                            };
                            this.router.navigate(['tabs/pick-up-place'], navigationExtras);
                        }else if (places.length == 1) {
                            this.commandPipeService.place = places[0]
                            this.commandPipeService.next(CommandStepPickUpPlace.id)
                        }
                    }
                )
            } else this.commandPipeService.next(CommandStepPickUpPlace.id, true)
        } else {
            const order = this.commandPipeService.order
            let observer = order ? this.userService.placeOrder(order.id, place.label) : this.quoteService.placeQuote(place.label)
            observer.subscribe(
                data => {
                    this.commandPipeService.place = place
                    this.commandPipeService.next(CommandStepPickUpPlace.id, true)
                },
                error =>{
                    let message = this.utilsService.getErrorMessage(error);
                    let title = this.utilsService.getErrorTitle(error);
                    this.alertService.show({
                        cssClass : 'alert-margintop11',
                        header: title ? title : this.translateService.instant("code-validation.invalid-alert.title"),
                        message: message ? message : this.translateService.instant("code-validation.invalid-alert.message"),
                        buttons: [{
                            cssClass: 'btnvalider',
                            text: this.translateService.instant("ok"),
                            handler: this.errorAction
                        }]
                    });
                }
            )
        }
    }

    public undo() {
        if (this.commandPipeService.place) {
            this.commandPipeService.place = null

            if (this.commandPipeService.isPurchasing) {
                //optimisation pour éviter de faire des appels API après validation commande
                const order = this.commandPipeService.order
                order ? this.userService.unplaceOrder(order.id) : this.quoteService.unplaceQuote()
            }
        }
    }
}
