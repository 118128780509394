// import { App } from "ionic-angular";

// std
import { Action } from "../std/action";

// Services
import { AlertService } from "../services/alert.service";
import { CommandPipeService } from "./command-pipe-service";
import { TakeBackComponentService } from "../services/take-back-component.service";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../config/constants";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

export class CommandStepTakeBack implements Action {
    static readonly id = 'CommandStepTakeBack'

    constructor(
        // private app: App, 
        public router: Router,
        private commandPipeService: CommandPipeService, 
        private translateService: TranslateService, 
        private takeBackComponentService: TakeBackComponentService, 
        private alertService: AlertService
    ) {}

    public do() {
        let order = this.commandPipeService.order
        let isPlaced = this.commandPipeService.isPlaced
        let placeType = this.commandPipeService.placeType
        if ((!placeType || placeType.code == 0) && !isPlaced && Constants.TAKE_BACK_MODE != "list") {
            this.takeBackComponentService.getTakeBackPage().subscribe(
                (component) => {
                    // L'autofocus d'un input ne fonctionne pas sans l'option { keyboardClose: false }
                    // qui ferme le clavier après la transition de page...
                    // comment by sajid
                    // navCtrl.push(component, order ? { order: order } : null, { keyboardClose: false })

                    let objState = {
                        keyboardClose: false,
                        order:order ? order : null
                    }

                    const navigationExtras: NavigationExtras = {
                        state: objState
                    };
                    this.router.navigate([component], navigationExtras);
                },
                (error) => {
                    this.alertService.show({
                        cssClass : 'alert-margintop11',
                        header: this.translateService.instant("scan-unavailable-alert.title"),
                        message: this.translateService.instant("scan-unavailable-alert.message"),
                        buttons: [{
                            cssClass: 'btnvalider',
                            text: this.translateService.instant("ok")
                        }]
                    })
                }
            )
        } else this.commandPipeService.next(CommandStepTakeBack.id, true)
    }

    public undo() { this.commandPipeService.isPlaced = false; this.commandPipeService.order = null }
}