import { Action } from "../std/action";
// import { App } from "ionic-angular";
import { CommandPipeService } from "./command-pipe-service";
// import { DeliveryAddressPage } from "../pages/command-pipe/delivery-address/delivery-address.page";
import { PlaceService } from "../services/api/place.service";
import { QuoteService } from "../services/api/quote.service";
import { Quote } from "../models/quote";

import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

export class CommandStepDeliveryAddress implements Action {
    static readonly id = 'CommandStepDeliveryAdress'

    constructor(
        // private app: App,
        public router: Router,
        private placeService: PlaceService,
        private commandPipeService: CommandPipeService,
        private quoteService: QuoteService
    ) {}

    private getQuote() {
        return this.commandPipeService.order || this.commandPipeService.quote;
    }

    public do() {
        const placeType = this.commandPipeService.placeType
        const place = this.commandPipeService.place
        const delivery_address = this.getQuote().delivery_address;

        if (placeType && placeType.code == 3 && !delivery_address && !place){
            this.placeService.getPlaces(placeType.code).subscribe(
                places => {
                    const navigationExtras: NavigationExtras = {
                        state: {
                            places: places
                        }
                    };
                    this.router.navigate(['tabs/delivery-address'], navigationExtras);
                    // { replaceUrl: true }
                    // Comment by sajid and replace by router
                    // this.app.getActiveNav().push(DeliveryAddressPage,{places: places })
                }
            )
            
        } 
        else this.commandPipeService.next(CommandStepDeliveryAddress.id, true)
    }

    public undo() {
        const quote = this.getQuote()
        //optimisation pour éviter de faire des appels API après validation commande
        if(this.commandPipeService.isPurchasing) {
            // note: ne fonctionne que sur le panier, il est peu probable de devoir renseigner l'adresse après paiement
            if (quote instanceof Quote) this.quoteService.removeAddress()
        }
        quote.delivery_address = null
        
    }
}
