import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '', 
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'language-list',
    loadChildren: () => import('./pages/language-list/language-list.module').then( m => m.LanguageListPageModule)
  },
  {
    path: 'idle',
    loadChildren: () => import('./pages/idle/idle.module').then( m => m.IdlePageModule)
  },
  {
    path: 'modal-command',
    loadChildren: () => import('./pages/command-pipe/modal-command/modal-command.module').then( m => m.ModalCommandPageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./pages/info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'options',
    loadChildren: () => import('./pages/options/options.module').then( m => m.OptionsPageModule)
  },
  {
    path: 'upsellings',
    loadChildren: () => import('./pages/upsellings/upsellings.module').then( m => m.UpsellingsPageModule)
  },
  {
    path: 'store-detail',
    loadChildren: () => import('./pages/store-detail/store-detail.module').then( m => m.StoreDetailPageModule)
  },
  {
    path: 'cross-sellings',
    loadChildren: () => import('./pages/cross-sellings/cross-sellings.module').then( m => m.CrossSellingsPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
