import { Injectable, Inject } from '@angular/core';
import { Observable, ReplaySubject }  from 'rxjs';

import { GlobalService } from '../global.service';
import { Constants } from '../../config/constants';
import { ApiService } from './api.service';
import { AuthenticationService } from '../authentication.service';
import { StorageService } from '../storage.service';
import { StorageData } from '../../models/storageData';
import { User, RegistrationUser, ProfileUser } from '../../models/user';
import { Card } from '../../models/card';
import { PasswordChange } from '../../models/ws/password-change';
import { ApiRequest } from '../../models/apirequest';
import { LoginInterface, RegisterInterface } from '../../models/apiresult';
import { UtilsService } from '../utils.service';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	private _user: User;
	get user() { return this._user }
	set user(user: User) { this._user = this.global.currentUser = user }

	private _nickname: string
	get nickname() { return this._nickname || (this._user && this._user.pseudonyme) }
	set nickname(nickname: string) { this._nickname = nickname }

	constructor(
		private apiService: ApiService, 
		public global: GlobalService, 
		private authentificationService: AuthenticationService,
		private utilsService: UtilsService
	) {
		apiService.delegate = this

		let token = authentificationService.getUserTokenInStorage();
		if (token) {
			let data = authentificationService.getUserInStorage();
			if (data) this.setCurrentUser(new User(data));
		} else {
			authentificationService.deleteUserInStorage();
		}
	}

	/**
	 * Vérifie si il existe un token utilisateur stocké, et si il n'est pas expiré.
	 */
	public isLoggedIn(): boolean {
		var userToken = this.authentificationService.getUserTokenInStorage();
		return userToken !== null && this.user !== null;
	}

	/**
	 * Vérifie par authentification que l'utilisateur existe
	 * Si l'utilisateur existe, un token utilisateur est retourné, et est stocké
	 * @param {User} user l'utilisateur à authentifier
	 * @param {function} callback fonction à appeler après authentification
	 */
	public login(user: User): Observable<any> {
		var that = this;
		let observer = new ReplaySubject<any>();
		var apiRequest = new ApiRequest<LoginInterface>(
			Constants.URL_LOGIN,
			user.toLoginData()
		);
		// Si un idPanier est existant lors de la connexion il est envoyé en en-tete pour ratacher le panier a l'utilisateur
		if (this.global.idPanier) {
			apiRequest.headers = apiRequest.headers.set(Constants.HEADER_QUOTE, this.global.idPanier.toString());
		}

		that.apiService.post(apiRequest).subscribe(
			(data) => {
				this.authentificationService.saveUserTokenInStorage(data.token);
				observer.next(data.token);
			},
			(error) => {
				observer.error(error);
			},
			() => {
				observer.complete();
			});
		return observer;
	}

	/**
	 * Déconnecte l'utilisateur
	 * Etant donné que l'identification de l'utilisateur se fait côté client, il suffit de supprimer le token local
	 * on fait un appel API si une action spéciale est à faire côté serveur
	 */
	public logout(): Observable<any> {
		let observer = new ReplaySubject<any>();

		this.user = null;
		var apiRequest = new ApiRequest(
			Constants.URL_LOGOUT,
			{}
		);
		this.apiService.post(apiRequest).subscribe(
			(data) => {
				this.logoutUser();
				this.global.viderPanierLocal();
				observer.next(data);
			},
			(error) => {
				observer.error(error);
			},
			() => {
				observer.complete();
			});

		return observer;
	}

	public register(user: RegistrationUser): Observable<any> {
		let observer = new ReplaySubject<any>();
		var apiRequest = new ApiRequest<RegisterInterface>(
			Constants.URL_REGISTER,
			user.serialize()
		);
		this.apiService.post(apiRequest).subscribe(
			(data) => {
				this.setCurrentUser(new User(data));
				this.authentificationService.saveUserTokenInStorage(data.token); // Uniquement car le serveur auto-loggue l'utilisateur aprés l'inscription
				observer.next(data);
			},
			(error) => {
				observer.error(error);
			},
			() => {
				observer.complete();
			});

		return observer;
	}

	public update(user: ProfileUser): Observable<any> {
		let observer = new ReplaySubject<any>();
		var apiRequest = new ApiRequest<RegisterInterface>(
			Constants.URL_PROFILE_EDIT,
			user.serialize()
		);
		this.apiService.post(apiRequest).subscribe(
			(data) => {
				this.setCurrentUser(new User(data));
				observer.next(data);
			},
			(error) => {
				observer.error(error);
			},
			() => {
				observer.complete();
			});
		return observer;
	}

	public resetPassword(username: string): Observable<any> {
		let observer = new ReplaySubject<any>();
		let apiRequest = new ApiRequest<RegisterInterface>(
			Constants.POST_RESET_PASSWORD,
			null
		)
		apiRequest.params = { 'username': username }

		this.apiService.post(apiRequest).subscribe(
			(data) => { observer.next(data) },
			(error) => { observer.error(error) },
			() => { observer.complete() }
		)

		return observer;
	}

	public changePassword(passwordChange: PasswordChange): Observable<any> {
		let observer = new ReplaySubject<any>();
		let apiRequest = new ApiRequest<RegisterInterface>(
			Constants.URL_PASSWORD_CHANGE,
			passwordChange.serialize()
		)
		this.apiService.post(apiRequest).subscribe(
			(data) => {
				observer.next(data);
			},
			(error) => {
				observer.error(error);
			}, () => {
				observer.complete()
			}
		);
		return observer;
	}

	public getMyOrders(): Observable<any> {
		var apiRequest = new ApiRequest(
			Constants.URL_ORDERS,
			null
		);
		return this.apiService.get(apiRequest);
	}

	public getUser(): Observable<any> {
		var apiRequest = new ApiRequest(
			Constants.URL_PROFILE,
			null
		);
		let observer = new ReplaySubject<any>();
		this.apiService.get(apiRequest).subscribe(
			(data) => {
				this.user = new User(data);
				this.setCurrentUser(this.user);
				observer.next(data);
			},
			(error) => {
				observer.error(error);
			},
			() => {
				observer.complete();
			}
		);
		return observer;
	}


	private setCurrentUser(_currentUser: User) {
		if (_currentUser) this.authentificationService.saveUserInStorage(_currentUser);
		else this.authentificationService.deleteUserInStorage();
		this.user = _currentUser ? new User(_currentUser) : null;
	}
	private logoutUser() { this.setCurrentUser(null) }


	private callAPIforGetMyCards(): Observable<any> {
		var that = this;
		let observer = new ReplaySubject<any>();
		var apiRequest = new ApiRequest<any>(
			Constants.GET_CARDS,
			null
		);

		this.apiService.get(apiRequest).subscribe(
			(data) => {
				that.saveMyCardsForStorage(data);
				observer.next( data);
			},
			(error) => {
				observer.error(error);
			},
			() => {
				observer.complete();
			});
		return observer;
	}
	private saveMyCardsForStorage(data: any): void {
		var expireDate = this.utilsService.addSecondsToDate(new Date(), Constants.NAVIGATION_CACHE_EXPIRES);
		StorageService.set(Constants.STORAGE_MY_CARDS, JSON.stringify(data), Constants.TYPE_STORAGE_LOCAL, expireDate);
	}

	private getMyCardsFromStorage(): StorageData {
		return StorageService.get( Constants.STORAGE_MY_CARDS, false );
	}
	public getMyCards(reload: boolean) : Observable<any>  {
		var that = this;
		let data = that.getMyCardsFromStorage();
		if(!data || reload) {
			that.getMyCardsFromStorage();
			return that.callAPIforGetMyCards();
		} else {
			try {
				let observer = new ReplaySubject<any>();
				observer.next(data);
				observer.complete();
				return observer;
			}
			catch(ex) {
			//   console.log(ex);
				that.getMyCardsFromStorage();
				return that.callAPIforGetMyCards();
			}
		}
	}
	public updateCardLabel(card : Card, newLabel: string) : Observable<any> {
		var apiRequest = new ApiRequest<any>(
			Constants.POST_MODIFY_CARD + card.id + '/label',
			{label : newLabel }
		);

		return this.apiService.post(apiRequest);
	}
	

	public removeCard(card : Card) : Observable<any> {
		var apiRequest = new ApiRequest<any>(
			Constants.DELETE_CARD + card.id,
			{}
		);

		return this.apiService.delete(apiRequest);
	}

	public checkPaiement(quoteId) : Observable<any> {
		var apiRequest = new ApiRequest<any>(
			Constants.URL_STATUT_PAIEMENT,
			{}
		);

		apiRequest.headers = apiRequest.headers.set(Constants.HEADER_QUOTE, String(quoteId));

		return this.apiService.get(apiRequest);
	}

	public getFullDate() {
		return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -1);
	}

	public payOrder(cardId): Observable<any> {
		const infoTime = this.getFullDate();

		var apiRequest = new ApiRequest<any>(
			Constants.POST_PAYER_ORDER,
			{ token_id : cardId, infoTime: infoTime }
		);
		if (this.global.idPanier) {
			apiRequest.headers = apiRequest.headers.set(Constants.HEADER_QUOTE, this.global.idPanier.toString());
		}
		return this.apiService.post(apiRequest);
	}

	public unplaceOrder(orderId: number) {
		var apiRequest = new ApiRequest<any>(
			Constants.URL_ORDER_PLACE,
			null
		);
		apiRequest.params = { order_id: orderId }

		return this.apiService.delete(apiRequest);
	}

	public placeOrder(orderId: number, placeCode: string) {
		var apiRequest = new ApiRequest<any>(
			Constants.URL_ORDER_PLACE,
			null
		);
		apiRequest.params = { order_id: orderId, place: placeCode }

		return this.apiService.post(apiRequest);
	}

	public getDetailPaiement() : Observable<any> {
		var apiRequest = new ApiRequest<any>(
			Constants.URL_DETAIL_PAIEMENT,
			{}
		);
		if (this.global.idPanier) {
			apiRequest.headers = apiRequest.headers.set(Constants.HEADER_QUOTE, this.global.idPanier.toString());
		}
		return this.apiService.get(apiRequest);
	}

	public getDetailEmbeded() : Observable<any> {
		var apiRequest = new ApiRequest<any>(
			Constants.URL_DETAIL_EMBEDED,
			{}
		);
		if (this.global.idPanier) {
			apiRequest.headers = apiRequest.headers.set(Constants.HEADER_QUOTE, this.global.idPanier.toString());
		}
		return this.apiService.get(apiRequest);
	}
}
