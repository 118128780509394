import { Injectable, Inject } from '@angular/core';
import { Observable, ReplaySubject }  from 'rxjs';

// models
import { CustomNavResponse, NavigationArticle } from '../../models/navigation';
import { ApiRequest } from '../../models/apirequest';
import { NavigationInterface } from '../../models/apiresult';

import { Constants } from '../../config/constants';
 
// services
// import { StoreService } from "./store.service";
import { ApiService } from './api.service';
import { NavigationEventsService } from '../events.service';
import { tap } from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})
export class NavigationService {

	private _navigation: CustomNavResponse;
	private _selectedItem: CustomNavResponse;
	private eventsService = new NavigationEventsService();

	constructor(
		private apiService: ApiService
	)  {
		apiService.delegate = this
	}


	callApiForNavigation(): Observable<CustomNavResponse> {
		var apiRequest = new ApiRequest<NavigationInterface>(
			Constants.URL_NAVIGATION,
			null
		);

		return this.apiService.get(apiRequest).pipe(
			tap((resp) => {
				this.processNavigationData(resp);
			})
		);
	}

	processNavigationData(response) {
		this._navigation = {
			children: this._populatingNavData(response)
		};
	}

	_populatingNavData(navData: CustomNavResponse, parent?: CustomNavResponse) {
		let result: CustomNavResponse[] = [];

		if(navData) {
			for(let i in navData.children) {
				let _child = navData.children[i];

				_child = {
					..._child,
					level: parent?(parent.level !== undefined?parent.level + 1:0):0 // To update the level of children
				}

				if(_child.children && _child.children.length > 0) {
					let _childs = this._populatingNavData(_child, _child) // Recursive call with Parent

					let articles = [];
					if(_child.articles && _child.articles.length > 0) {
						for (let i=0;i <  _child.articles.length;i++) {
							articles.push(new NavigationArticle(_child.articles[i], _child));
						}
					}

					result.push({
						..._child,
						articles: articles,
						children: _childs,
						parent: parent?parent:undefined, // Set Parent data if exists, else undefined
						img: _child.id ? `${Constants.BASE_NAVIGATION_IMAGE_URL + _child.id}.png` : undefined, // Update Image URL from ID
						image: _child.id ? `${Constants.BASE_NAVIGATION_IMAGE_URL + _child.id}.png` : undefined, // Update Image URL from ID
					});
				} else {
					let articles = [];
					if(_child.articles && _child.articles.length > 0) {
						for (let i=0;i <  _child.articles.length;i++) {
							articles.push(new NavigationArticle(_child.articles[i], _child));
						}
					}

					result.push({
						..._child,
						articles: articles,
						parent: parent?parent:undefined, // Set Parent data if exists, else undefined
						img: _child.id ? `${Constants.BASE_NAVIGATION_IMAGE_URL + _child.id}.png` : undefined, // Update Image URL from ID
						image: _child.id ? `${Constants.BASE_NAVIGATION_IMAGE_URL + _child.id}.png` : undefined, // Update Image URL from ID
					});
				}
			}
		}

		return result;
	}

	set selectedItem(selectedItem) {
        if (this._selectedItem != selectedItem) {
            this._selectedItem = selectedItem;
            this.eventsService.updateNavigationItem(selectedItem);
        }
    }

	get selectedItem() { return this._selectedItem; }

	public isSelectedItem(navigationItem: CustomNavResponse) { return this._selectedItem == navigationItem; }

    public listenItemSelectionChange(fn: any) {
        return this.eventsService.getNavigationItemObservable().subscribe(item => {
            fn(item);
        });
    }

	get navigation() { return this._navigation; }

	/**
	* Retrouve l'ancetre de l'élément au niveau voulu
	*
	* @param navigationItem L'item enfant a parcourir pour retrouver l'ancêtre de niveau donné
	*
	* @return le parent trouvé ou null
	*/
	public getAncestorNavigationItem(navigationItem: CustomNavResponse, level: number = 0): CustomNavResponse|null {
		while(navigationItem && navigationItem.parent && navigationItem.level > level){
			console.log('navigationItem at getAncestorNavigationItem: ', navigationItem);
			navigationItem = navigationItem.parent;
		} 
		return navigationItem;
	}

	/**
	  * Retrouve la racine de l'élément au niveau voulu
	  *
	  * @return le parent trouvé ou null
	*/
	public getRootNavigationItem(navigationItem: CustomNavResponse): CustomNavResponse | null {
		return this.getAncestorNavigationItem(navigationItem)
	}

}
