// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  DEBUG: false,
  APP_VERSION: "2.3.231",
  MODE: 'test',

  BASE_URL: 'http://back.app.test.heyou.e-capinfo.fr/',
  API_URL: 'http://back.app.test.heyou.e-capinfo.fr/',

  API_VERSION: '6',
  API_KEY: '6RDKEY_368574565',

  SOCIETY_ID:"4voYjEeF4pnfjjFsSs/IMQ==",
  GEO_SEARCH_API_KEY: "AIzaSyB7MsdrtR6d806droKJCaDhY19ogjuWvHs"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
