// import { App } from "ionic-angular";

// std
import { Action } from "../std/action";

// Pages
// import { TakeAwayPage } from "../pages/command-pipe/take-away/take-away.page";

// Services
import { QuoteService } from "../services/api/quote.service";
import { CommandPipeService } from "./command-pipe-service";

import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

export class CommandStepTakeAway implements Action {
    static readonly id = 'CommandStepTakeAway'

    constructor(
        // private app: App, 
        public router: Router,
        private commandPipeService: CommandPipeService,
        private quoteService: QuoteService
    ) {}

    public do() {
        let quote = this.quoteService.quote;
        let placeType = this.commandPipeService.placeType;
        if (quote.allowTakeAway && this.commandPipeService.isTakeAway == undefined && (!placeType || (placeType.code == 0 || placeType.code == 1)) ){
            // Comment by sajid and replace by router
            // this.app.getActiveNav().push(TakeAwayPage) 
            this.router.navigate(['tabs/take-away']);
        }else {
            this.commandPipeService.next(CommandStepTakeAway.id, true)
        }
    }

    public undo() { this.commandPipeService.isTakeAway = undefined }
}