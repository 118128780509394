import { Injectable, Type } from "@angular/core";

// std
import { Action } from "../std/action";
import { Link } from "../models/link";

// Models
import { Order } from "../models/order";
import { Quote } from "../models/quote";
import { MethodOfPayment } from "../models/method-of-payment";
import { PlaceType } from "../models/place-type";
import { Place } from "../models/place";

import { MopComponent } from "src/app/pages/command-pipe/validation-order/components/mop-component";

@Injectable()
export class CommandPipeService { 
    private stepsById: { [id: string]: Link<Action> } = {}
    private step: Link<Action>
    public exitAction?: Action

    public quote?: Quote;
    public order?: Order;
    public isTakeAway?: boolean;
    public printerData?: string;

    public methodsOfPayments?: { data: MethodOfPayment, component: Type<MopComponent> }[];
    public isPaid?: boolean;

    public allowTakeBackLater: boolean;
    public isPlaced?: boolean; // isQuotePlaced (l'info est validé sur le panier coté api)
    public placeType?: PlaceType;
    public place?: Place;
    public pickUpTime?: any;

    public reservation?: any;

    public isPurchasing: boolean

    private doStep() { let step = this.step; if (step) step.element.do() }
    private undoStep() { let step = this.step; if(step) { let element = step.element; if (element && element.undo) element.undo() } }
    private nextStep() { let step = this.step; if (step.next) this.step = step.next }

    public addStep(id: string, action: Action) {
        let step = new Link<Action>(); step.element = action

        let initialStep = this.step
        if (initialStep) {
            while(initialStep.next) initialStep = initialStep.next
            initialStep.next = step; step.prev = initialStep
        }
        else this.step = step

        this.stepsById[id] = step
    }

    public reset(id?: string) {
        let targetAction = null
        if (id){
            targetAction = this.stepsById[id]
        } 
        // console.log('Reset steps: ',this.step);

        for (let step = this.step; step.prev && (!targetAction || step != targetAction); this.step = step = step.prev) {
            this.undoStep()
        }
        if (!targetAction) this.undoStep();
    }

    public begin(quote: Quote) {
        this.reset()
        this.quote = quote
        this.doStep()
    }

    // note: si force n'est pas a true, next execute l'action mais ne passe pas a la suivante
    public next(id: string, force: boolean = false) {
        let step = this.step = this.stepsById[id]
        
        if (force) {
            if (!step.next) { this.exit(); return }
            this.nextStep()
        }
        this.doStep()
    }

    public exit() {
        if (!this.exitAction) return
        this.reset()
        this.exitAction.do()
    }
}
