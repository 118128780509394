import { Article } from "./article";

export class OptionItem {
    public id: number;
    private i18nLabel: i18nString
    private _label: string
    public get label() { return this.article ? this.article.label : localize(this.i18nLabel, this._label) }
    private i18nDescription: i18nString
    private _description: string
    public get description() { return this.article ? this.article.description : localize(this.i18nDescription, this._description) }
    public article?: Article;
    public filter?: string[];
    // note: toujours false si construit a partir des données serveur
    public isChecked : boolean;
    public isMultiple: boolean;
    public qty: number;

    get image() { return this.article ? this.article.image : null; }

    constructor(data) { this.setData(data) }

    private setData(data) {
      if(data === null) return;
      this.id = typeof data.id !== "undefined" ? data.id : undefined;
      this._label = typeof data.label !== "undefined" ? data.label : undefined;
      this.i18nLabel = typeof data.i18n_label !== "undefined" ? data.i18n_label : data.i18nLabel;
      this._description = typeof data.description !== "undefined" ? data.description : undefined;
      this.i18nDescription = typeof data.i18n_description !== "undefined" ? data.i18n_description : data.i18nDescription;
      this.article = typeof data.article !== "undefined" ? new Article(data.article) : undefined;
      this.filter = typeof data.filter !== "undefined" ? data.filter : undefined;
      this.isChecked = typeof data.isChecked !== "undefined" ? data.isChecked : false;
      this.isMultiple = typeof data.isMultiple !== "undefined" ? data.isMultiple : false;
      this.qty = typeof data.qty !== "undefined" ? data.qty : 0;
    }

    public allowItem(optionItem: OptionItem): boolean { return this.filter ? this.filter.indexOf(String(optionItem.id)) < 0 : true }
}
