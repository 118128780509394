export class MethodOfPayment {
    public id: number
    private i18nLabel: i18nString
    private _label: string
    public get label() { return localize(this.i18nLabel, this._label) }
    public set label(label: string) { this._label = label }
    public type: { id: number } // la classe MethodOfPaymentType n'est pas créé car seul l'id nous intéresse ici

    constructor(data?: any) {
        if (data) {
            this.id = data.id || 0
            this.i18nLabel = data.i18n_label || null
            this._label = data.label
            this.type = data.type
        }
    }
}
