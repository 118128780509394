import { Injectable } from '@angular/core';

import { File } from '@ionic-native/file/ngx';
import { Observable, ReplaySubject } from 'rxjs';
import { Link } from '../models/link';
import { Action } from '../std/action';

class ReadAction implements Action {

    constructor(
    	private file: File, 
    	private observer: Observable<string>, 
    	private filename: string, 
    	private dataByFilename: {[id:string]:string}
    ) {}

    public do() {
        const filename = this.filename
        const maxReads = 1 // note: 1 ou 10 essais ne changent rien donc 1 essai est suffisant
        let fileConfExists = false
        const read = (observer, count) => {
            if (count > maxReads) {
                console.log(`FileService -- fail read ${this.file.externalDataDirectory}${filename} after ${maxReads} retries`);
                if (fileConfExists) console.log('FileService -- file exist but can\'t be read')
                observer.error(fileConfExists)
                return
            }
            console.log(`FileService -- try ${count} read ${this.file.externalDataDirectory}${filename}`)

            let timeElapsed = false
            // note: timeout au cas ou aucun retour de cordova n'est fait (ni succes, ni erreur) pour ne pas bloquer l'appli
            let timeout = setTimeout(
                () => {
                    timeElapsed = true
                    setTimeout(() => { read(observer, count+1) })
                },
                2000
            )

            const readDone = (logMessage, ...args) => {
                if (timeElapsed) {
                    console.log(logMessage, args)
                    return false
                } else clearTimeout(timeout)
                return true
            }

            try{

                this.file.checkFile(this.file.externalDataDirectory, filename).then(fileStatus => {
                    // alert('checkFile success');
                    fileConfExists = true
                    this.file.readAsText(this.file.externalDataDirectory, filename).then(results => {
                            if (!readDone(`successfully read ${filename} after timeout`)) return
                            console.log(`FileService -- successfully read ${filename}`)
                            this.dataByFilename[filename] = results
                            observer.next(results);
                        }).catch(err => {
                            if (!readDone(`FileService -- fail read ${this.file.externalDataDirectory}${filename} after timeout`)) return
                            console.log(`FileService -- fail read ${this.file.externalDataDirectory}${filename}`)
                            observer.error(null);
                        })
                }).catch(_ => {
                    if (!readDone(`FileService -- no file to read ${this.file.externalDataDirectory}${filename} checked after timeout`)) return
                    console.log(`FileService -- no file to read ${this.file.externalDataDirectory}${filename}`)
                    observer.error(null);
                });
            } catch (error) {
                // alert('FileService -- no file to read ');
                if (!readDone(`FileService -- no file to read ${this.file.externalDataDirectory}${filename} checked after timeout`)) return
                console.log(`FileService -- no file to read ${this.file.externalDataDirectory}${filename}`)
                observer.error(null);
            }
        }

        read(this.observer, 1);
    }
}

@Injectable({
  providedIn: 'root'
})
 
export class FileService {
	
    private readActionLink: Link<Action>
    private dataByFilename: {[id:string]:string} = {}

    constructor( private file: File ) {}

    /**
     * Attention, si la methode est appelée plusieurs a la suite
     * checkfile sera négatif et donc le fichier sera écrit autant de fois
     */
    public writeIntoFile(filename: string, data: string, append: boolean) {
        const options = { append: append, replace: !append }
        let observer = new ReplaySubject<string>();
        console.log(`FileService -- try write ${this.file.externalDataDirectory}${filename}`)
        const processWrite = () => {
            this.file
                .writeFile(this.file.externalDataDirectory, filename, data, options)
                .then(_ => {
                    console.log(`FileService -- successfully write ${filename} `)
                    observer.next(filename)
                })
                .catch(error => {
                    console.log(`FileService -- fail write ${filename} cannot be writen`, error)
                    observer.error(error)
                })
        }
        if(this.dataByFilename[filename] != data) {
            this.file.checkFile(this.file.externalDataDirectory, filename)
            .then(_ => processWrite())
            .catch(err => {
                this.file
                    .createFile(this.file.externalDataDirectory, filename, true)
                    .then(_ => processWrite())
                    .catch(error => {
                        console.log(`FileService -- fail write ${filename} do not exist`, error)
                        observer.error(error)
                    })
            })
        }

        return observer
    }

    public readFromFile(filename: string) : Observable<string> {
        let actionObserver = new ReplaySubject<string>()
        const readActionStack = new Link<Action>()
        readActionStack.element = new ReadAction(this.file, actionObserver, filename, this.dataByFilename)

        if (!this.readActionLink) {
            this.readActionLink = readActionStack
            this.readActionLink.element.do()
        } else {
            let currentActionLink = this.readActionLink
            while(currentActionLink.next) currentActionLink = currentActionLink.next
            currentActionLink.next = readActionStack
        }

        let observer = new ReplaySubject<string>()
        actionObserver.subscribe(
            data => {
                observer.next(data);
                (this.readActionLink = this.readActionLink.next) && this.readActionLink.element.do()
            },
            error => {
                observer.error(error);
                (this.readActionLink = this.readActionLink.next) && this.readActionLink.element.do()
            }
        )
        return observer
    }
}
