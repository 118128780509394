import { Action } from "../std/action";
// import { App } from "ionic-angular";
import { CommandPipeService } from "./command-pipe-service";
import { PickUpPage } from "../pages/command-pipe/pick-up/pick-up.page";
import { PlaceService } from "../services/api/place.service";
import { PlaceType } from "../models/place-type";
import { Place } from "../models/place";
import { Router, ActivatedRoute, ActivationEnd, NavigationExtras } from '@angular/router';

export class CommandStepPickUp implements Action {
    static readonly id = 'CommandStepPickUp'

    constructor(
        // private app: App, 
        public router: Router,
        private commandPipeService: CommandPipeService, 
        private placeService: PlaceService
    ) {}

    public do() {
        // console.log('commandPipeService.placeType: ',this.commandPipeService.placeType);
        if (!this.commandPipeService.placeType) {
            this.placeService.getTypes().subscribe(
                placeTypes => {
                    if (placeTypes.length > 1){
                        const navigationExtras: NavigationExtras = {
                            state: {
                                placeTypes: placeTypes
                            }
                        };
                        this.router.navigate(['tabs/pick-up'], navigationExtras);
                    }else if (placeTypes.length == 1) {
                        this.commandPipeService.placeType = placeTypes[0]
                        this.commandPipeService.next(CommandStepPickUp.id, true)
                    }
                }
            )
        } else this.commandPipeService.next(CommandStepPickUp.id, true)
    }

    public undo() { this.commandPipeService.placeType = null }
}