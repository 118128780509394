import { Injectable, Inject } from '@angular/core'

import { ApiService } from './api.service';
import { ApiRequest } from '../../models/apirequest';

import { Constants } from '../../config/constants';

@Injectable({
	providedIn: 'root'
})
export class ImageService {

	// Liste des timestamps (de dernière modification) des images
   // timestamp est de type arborescence de fichier avec :
   // - soit un string[] pour les dossier
   // - soit un number pour les fichiers
   private timestamps: any;

   constructor( 
   	private apiService: ApiService
   )  {
      apiService.delegate = this
   }

   public get breakingImage() { return this.timestampUrl(Constants.IMG_BREAKING) }
   public get comingSoonImage() { return this.timestampUrl(Constants.IMG_COMING_SOON) }

   public load() {
      let request = new ApiRequest(
         Constants.URL_IMAGE_TIMESTAMPS,
         null
      );
      
      this.apiService.get(request).subscribe(
         data => {
            // console.log('ImageService::ws', data)
            this.timestamps = data
         },
         error => { /* silent errors */ }
      )
   }

   private getUrl(url: string) {
      if (!this.timestamps) return url

		let timestampsNode: any = this.timestamps;
		let imageFilepath = url.substring(Constants.BASE_IMAGE_URL.length);
		let components = imageFilepath.split('/');
		for (let component of components) {
         timestampsNode = timestampsNode[component]
         if (!timestampsNode) break
      }

      return timestampsNode ? url + '?' + timestampsNode : url;
  	}

  	public timestampUrl(url: string) {
      let appenedUrl = this.getUrl(url);
      return appenedUrl;
   }
   
}
